import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//#region ///// COMPONENTS /////
import AvatarList from '../AvatarList';
//#endregion

import './styles.scss';

function AvatarOption({
  avatarCollection,
  handleAvatarEdit,
  handleAvatarBackButton
}) {
  const { t } = useTranslation();

  return (
    <div className='avatarOption'>
      <div className='avatarOption_header'>
        <h3 className='avatarOption_header_title'>{t('Select an avatar')}</h3>
      </div>
      <AvatarList
        avatarCollection={avatarCollection}
        handleAvatarEdit={handleAvatarEdit}
      />
    </div>
  )
}

AvatarOption.propTypes = {
  avatarCollection: PropTypes.array,
  handleAvatarEdit: PropTypes.func,
  handleAvatarBackButton: PropTypes.func
};

export default React.memo(AvatarOption); 