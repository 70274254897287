import { fork, all } from 'redux-saga/effects';

import appSagas from '../containers/App/sagas';
import deviceMonitorSaga from '../containers/App/sagaMonitorDevice';
import appSectionRouterSagas from '../containers/AppSectionRouter/sagas';
import homeSagas from '../containers/sections/HomeSection/sagas';
import profileSagas from '../containers/ProfileManager/sagas';
import searchSagas from '../containers/sections/SearchSection/sagas';
import sectionSagas from '../containers/sections/Section/sagas';
import playerEpgSagas from '../containers/sections/PlayerEpgSection/sagas';
import playerSagas from '../containers/sections/PlayerSection/sagas';
import contentOverViewSagas from '../containers/sections/ContentOverViewSection/sagas';
import contentOverViewLiveSagas from '../containers/sections/ContentOverViewLiveSection/sagas';
import ActivateCodeSagas from '../containers/sections/ActivateCodeSection/sagas';

const sagas = [
  appSagas,
  appSectionRouterSagas,
  deviceMonitorSaga,
  homeSagas,
  profileSagas,
  searchSagas,
  sectionSagas,
  playerSagas,
  contentOverViewSagas,
  contentOverViewLiveSagas,
  playerEpgSagas,
  ActivateCodeSagas
];

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga));

  yield all([...globalSagasForks]);
}

export default globalSagas;
