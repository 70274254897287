import { types } from './constants';

const INITIAL_STATE = {
  avatarCollection: [],
  error: {
    code: null,
    details: null,
    message: null
  },
  isFetching: false,
  profileSelected: null,
  profileCollection: [],
  currentContentPosition: null,
  profileDataFetch: null,
  profileFormStatus: false,
  enableProfileOperations: false,
  isAddFormMode: true,
  favorites: [],
  enablePinCodeValidationModal: false,
  profileIDSelectedForValidation: null,
  validatePinCodeStatus: true,
  formDisabled: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PROFILES_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: false
      };
    case types.PROFILES_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          code: action.error.errorCode,
          message: `"${action.error.name}": ${action.error.message}`
        },
        isFetching: false
      };
    case types.PROFILES_FETCH_SUCCESS:
      return {
        ...state,
        avatarCollection: [...action.avatars],
        isFetching: false,
        profileCollection: [...action.profiles],
        profileSelected: action.currentProfile
      };
    case types.SET_CURRENT_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        profileSelected: action.profileID
      };
    case types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...action.error
        },
        isFetching: false
      };
    case types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_SUCCESS:
      return {
          ...state,
           currentContentPosition: action.content
        };
    case types.CREATE_PROFILE_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.CREATE_PROFILE_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          code: action.error.errorCode,
          message: `"${action.error.name}": ${action.error.message}`
        },
        isFetching: false
      };
    case types.CREATE_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case types.EDIT_PROFILE_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.EDIT_PROFILE_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          code: action.error.errorCode,
          message: `"${action.error.name}": ${action.error.message}`
        },
        isFetching: false
      };
    case types.EDIT_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case types.DELETE_PROFILE_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.DELETE_PROFILE_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          code: action.error.errorCode,
          message: `"${action.error.name}": ${action.error.message}`
        },
        isFetching: false
      };
    case types.DELETE_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false
      };
    case types.PROFILE_BY_ID_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.PROFILE_BY_ID_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          code: action.error.errorCode,
          message: `"${action.error.name}": ${action.error.message}`
        },
        isFetching: false
      };
    case types.PROFILE_BY_ID_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profileDataFetch: action.profileData
      };
    case types.PROFILE_FORM_STATUS:
        return {
          ...state,
          profileFormStatus: action.status
        };
    case types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_CLEAR:
          return {
            ...state,
            currentContentPosition: null
        };     
    case types.ENABLE_PROFILE_OEPRATIONS:
          return {
            ...state,
            enableProfileOperations: action.status
        };
    case types.CHANGE_FORM_MODE:
          return {
            ...state,
            isAddFormMode: action.status
        };  
        
    case types.FAVORITES_CONTENTS_FETCH:
          return {
            ...state,
            error: {
              ...INITIAL_STATE.error
            },
            isFetching: false
          };
    case types.FAVORITES_CONTENTS_FETCH_FAILURE:
          return {
            ...state,
            error: {
              ...state.error,
              code: action.error.errorCode,
              message: `"${action.error.name}": ${action.error.message}`
            },
            isFetching: false
          };
    case types.FAVORITES_CONTENTS_FETCH_SUCCESS:
          const { result } = action.contents;
          return {
            ...state,
            favorites: result
          };
    case types.ADD_FAVORITE_CONTENT_FETCH:
          return {
            ...state,
            error: {
              ...INITIAL_STATE.error
            },
            isFetching: true
          };
    case types.ADD_FAVORITE_CONTENT_FETCH_FAILURE:
          return {
            ...state,
            error: {
              ...state.error,
              code: action.error.errorCode,
              message: `"${action.error.name}": ${action.error.message}`
            },
            isFetching: false
          };
    case types.ADD_FAVORITE_CONTENT_FETCH_SUCCESS:
          return {
            ...state, 
            isFetching: false
          };
    case types.DELETE_FAVORITE_CONTENT_FETCH:
          return {
            ...state,
            error: {
              ...INITIAL_STATE.error
            },
            isFetching: true
          };
    case types.DELETE_FAVORITE_CONTENT_FETCH_FAILURE:
          return {
            ...state,
            error: {
              ...state.error,
              code: action.error.errorCode,
              message: `"${action.error.name}": ${action.error.message}`
            },
            isFetching: false
          };
    case types.DELETE_FAVORITE_CONTENT_FETCH_SUCCESS:
          return {
            ...state, 
            isFetching: false
          };
    case types.ADD_PIN_CODE_PROFILE_FETCH:
          return {
            ...state,
            error: {
              ...INITIAL_STATE.error
            },
            isFetching: true
          };
    case types.ADD_PIN_CODE_PROFILE_FETCH_FAILURE:
          return {
            ...state,
            error: {
              ...state.error,
              code: action.error.errorCode,
              message: `"${action.error.name}": ${action.error.message}`
            },
            isFetching: false
          };
    case types.ADD_PIN_CODE_PROFILE_FETCH_SUCCESS:
          return {
            ...state, 
            isFetching: false
          };
          
    case types.DELETE_PIN_CODE_PROFILE_FETCH:
          return {
            ...state,
            error: {
              ...INITIAL_STATE.error
            },
            isFetching: true
          };
    case types.DELETE_PIN_CODE_PROFILE_FETCH_FAILURE:
          return {
            ...state,
            error: {
              ...state.error,
              code: action.error.errorCode,
              message: `"${action.error.name}": ${action.error.message}`
            },
            isFetching: false
          };
    case types.DELETE_PIN_CODE_PROFILE_FETCH_SUCCESS:
          return {
            ...state, 
            isFetching: false
          }; 
    case types.EDIT_PIN_CODE_PROFILE_FETCH:
          return {
            ...state,
            error: {
              ...INITIAL_STATE.error
            },
            isFetching: true
          };
    case types.EDIT_PIN_CODE_PROFILE_FETCH_FAILURE:
          return {
            ...state,
            error: {
              ...state.error,
              code: action.error.errorCode,
              message: `"${action.error.name}": ${action.error.message}`
            },
            isFetching: false
          };
    case types.EDIT_PIN_CODE_PROFILE_FETCH_SUCCESS:
          return {
            ...state, 
            isFetching: false
          };     
    case types.VALIDATE_PIN_CODE_PROFILE_FETCH:
          return {
            ...state,
            error: {
              ...INITIAL_STATE.error
            },
            isFetching: true
          };
    case types.VALIDATE_PIN_CODE_PROFILE_FETCH_FAILURE:
          return {
            ...state,
            error: {
              ...state.error,
              code: action.error.errorCode,
              message: `"${action.error.name}": ${action.error.message}`
            },
            isFetching: false
          };
    case types.VALIDATE_PIN_CODE_PROFILE_FETCH_SUCCESS:
          return {
            ...state, 
            isFetching: false,
            validatePinCodeStatus: action.status
          };

    case types.ENABLE_VALIDATE_MODAL_PIN_CODE:
            return {
              ...state, 
              enablePinCodeValidationModal: action.status,
              profileIDSelectedForValidation: action.profileIDSelected
            };
    case types.FORM_DISABLED_PROFILE:
              return {
                ...state, 
                formDisabled: action.status
              };
    case types.SUBSCRIBE_DEAFULT_TOPIC_FETCH:
                return {
                  ...state,
                  error: {
                    ...INITIAL_STATE.error
                  },
                  isFetching: true
                };
    case types.SUBSCRIBE_DEAFULT_TOPIC_FETCH_FETCH_FAILURE:
                return {
                  ...state,
                  error: {
                    ...state.error,
                    code: action.error.errorCode,
                    message: `"${action.error.name}": ${action.error.message}`
                  },
                  isFetching: false
                };
    case types.SUBSCRIBE_DEAFULT_TOPIC_FETCH_SUCCESS:
                return {
                  ...state, 
                  isFetching: false
                };
    case types.CLEAR_STATES:
      return {
        ...state,
        avatarCollection: [],
        profileCollection: []
      };              
    default:
      return state;
  }
};

export default reducer;
