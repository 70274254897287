import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

//#region ///// COMPONENTS /////
import ProfileItem from '../ProfileItem';
import ProfilesList from '../ProfilesList';
//#endregion
import addIcon from '../../../static/images/add.svg';
import './styles.scss';
import { existAdminProfile, isAdminProfile } from '../../../utils/ProfileUtils';
import { PROFILE_NAMES } from '../../../constants/contentTypes';


function ProfileOption({
  avatarCollection,
  profileCollection,
  maxProfiles,
  handleOnAddProfile,
  handleOnSelectProfile,
  handleOnEditProfile,
  handleConfirmDeleteProfile,
  enableProfileOperations,
  currentDataProfileSelected,
  profileCollectionSize,
  profilesWithoutDefault
}) {
  const { t } = useTranslation();
  const { max_rating: maxRatingProfileSelected } = currentDataProfileSelected || {};

  return (
    <React.Fragment>
      <div className="profile-option_title">
        <h2>{t('Who are you?')}</h2>
      </div>
      <div className="profile-option_list">
        <ProfilesList
          avatarCollection={avatarCollection}
          onClickItemHandler={handleOnSelectProfile}
          profileCollection={profilesWithoutDefault}
          handleOnEditProfile={handleOnEditProfile}
          handleConfirmDeleteProfile={handleConfirmDeleteProfile}
          enableProfileOperations={enableProfileOperations}
          currentDataProfileSelected={currentDataProfileSelected}
        />
        {( (profileCollection[0]?.name === PROFILE_NAMES.DEFAULT && profileCollectionSize === 1) || ( (isAdminProfile(maxRatingProfileSelected) || existAdminProfile(profileCollection)) &&  enableProfileOperations && profileCollectionSize < maxProfiles)) &&
          <ProfileItem
            className={"profile-item_add"}
            avatarURI={addIcon}
            name={t('Add profile')}
            onClickHandler={handleOnAddProfile}
          />
        }
      </div>
    </React.Fragment>
  )
}

ProfileOption.propTypes = {
  avatarCollection: PropTypes.array,
  profileCollection: PropTypes.array,
  maxProfiles: PropTypes.number,
  handleOnAddProfile: PropTypes.func,
  handleOnSelectProfile: PropTypes.func,
  handleOnEditProfile: PropTypes.func,
  handleOnDeleteProfile: PropTypes.func,
  enableProfileOperations: PropTypes.bool,
  currentDataProfileSelected: PropTypes.object,
  profileCollectionSize: PropTypes.number
};

export default React.memo(ProfileOption); 