import { types } from './constants';

export const changeStickyBool = (bool) => ({
  type: types.NAVBAR_STICKY_CHANGE,
  bool
});

export const changeNavBarBool = (bool) => ({
  type: types.NAVBAR_NAVBARBOOL_CHANGE,
  bool
});


export const changeAccountMenuStatus = (status) => ({
  type: types.ACCOUNT_MENU_STATUS_CHANGE,
  status
});