import React from 'react';
import { changeStickyBool } from '../../containers/Navbar/actions'
import {
    useDispatch
} from 'react-redux';
import useRouter from '../../customHooks/useRouter';
import { PATH_ERROR } from '../../containers/AppSectionRouter/constants';


/*export function useChangeSticky(widgetCollection, sortedWidgets) {
    const dispatch = useDispatch();
    return(
    React.useEffect(() => {
        const firstElement = sortedWidgets.find((e) => {
            return ('carousel' === e.componentType || 'banner' === e.componentType);
        })
        if (firstElement && firstElement?.componentType) {
            if ('carousel' === firstElement.componentType) {
                dispatch(changeStickyBool(true))
            } else {
                dispatch(changeStickyBool(false))
            }
        }
    }, [widgetCollection])
    )
}*/

export function useCatchError() {
    const router = useRouter()
    let countCarousel = 0;

    const catchErrorHanlder = (widget)=>{
        if ('carousel' === widget?.componentType) countCarousel+=1;
        return (error) => {
        if (widget.componentType === 'carousel') {
          countCarousel = countCarousel - 1;
          if (countCarousel <= 4) {
            console.log('countCarousel:', countCarousel)
            return router.push(PATH_ERROR, { error: error, navBar: true });
          }
        }
        if (widget.componentType === 'banner' || widget.componentType === 'gallery_wall' || widget.componentType === 'epg_grid') {
          return router.push(PATH_ERROR, { error: error, navBar: true });
        }
    }}
    return catchErrorHanlder
}