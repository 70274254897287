const ARRAY_ERROR_CODE = [
    "CL-001",
    "CL-002",
    "CP-006",
    "CS-011",
    "SE-003",
    "CP-015",
    "CP-037",
    "CP-137",
    "GE-001"
]

export const ERROR_CODE = (()=>{
    const obj = {};
    ARRAY_ERROR_CODE.forEach((value) => {
        obj[value] = value;
    });
    return obj;
})();


export const ERROR_CODE_FORMAT = (()=>{
    const obj = {};
    ARRAY_ERROR_CODE.forEach((value) => {
        obj[value.replace('-','')] = value;
    });
    return obj;
})();


                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                