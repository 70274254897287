import React from 'react';
import {
  useDispatch
} from 'react-redux';
import { changeNavBarBool } from '../../Navbar/actions';
import { clearIsNextEpisodePlayer } from '../PlayerSection/actions';

import * as actions from './actions';

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(accessToken, section) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    //automatic scroll up
    window.scrollTo(0, 0);
    dispatch(changeNavBarBool(true));
    dispatch(actions.loadWidgets(accessToken, section.id, { active: true }));
    dispatch(clearIsNextEpisodePlayer());
  }, []);
}
