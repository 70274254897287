import { types } from './constants';

export const getComponent = () => ({
  type: types.UNITY_COMPONENT_FETCH
});

export const loadComponentFetchFailure = (errors) => ({
  type: types.UNITY_COMPONENT_FETCH_FAILURE,
  errors
});

export const loadComponentFetchSuccess = (componentItems) => ({
  type: types.UNITY_COMPONENT_FETCH_SUCCESS,
  componentItems
});

export const loadMoreDataInComponentSuccess = (componentItems) => ({
  type: types.UNITY_LOAD_MORE_DATA_COMPONENT_SUCCESS,
  componentItems
});



export const selectOrderType = (orderTypeSelected) => ({
  type: types.UNITY_COMPONENT_SELECT_ORDER_TYPE,
  orderTypeSelected
});