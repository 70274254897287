import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as hooks from './hooks';
import * as selectors from './selectors';
import * as actions from './actions';
import { useNotificationModalCreate } from '../PlayerSection/hooks';
import { SHOW_RATING_IMAGE, TITLES } from './constants';
import useRouter from '../../../customHooks/useRouter';
import useWidgetTranslation from '../../../customHooks/useWidgetTranslation';
import { findImageSettingByType } from '../../../utils/appSettingUtils';
import { NO_IMAGE_DEFAULT } from '../../../constants/noImage';
import { CONTENT_TYPES, IMAGE_MODE, TRUCATE_STRING } from '../../../constants/contentTypes'

//#region /////// COMPONENTS ///////
import Footer from '../../../components/Footer';
import {
  ContentOverview,
  ContentRelated,
  ContentEpisodes,
  isTokenFromBR
} from '@tbx/experience-widgets-lib';
import { checkifHaveContentAccess } from '../../../utils/validationUserUtils';
import { isDesktop } from 'react-device-detect';
import { SectionErrorBoundary } from '../../../HOCs/WithErrorBoundary';
import { selectCurrentContentPosition, selectCurrentProfile } from '../../ProfileManager/selectors';
import { validateUrlRedirect } from '../../../utils/ContentUtils';
import { addFavoriteContentByProfile, deleteFavoriteContentByProfile, fetchCurrentContentPositionByProfile } from '../../ProfileManager/actions';
import { ERROR_CODE } from '../../../constants/errorCode';
import { validateFavoriteContent } from '../../../utils/ProfileUtils';
import Seo from '../../../components/Seo';
import { truncateString } from '../../../utils/objectUtils';
import { getContentImageByMode } from '../../../utils/imageUtils';
import { BRAND_INFO } from '../../../constants/utilTypes';
import { GA_SECTION_NAMES } from '../../../constants/googleAnalyticEventTypes';
import { RAITINGS_DEFAULT } from '../../../constants/ratings';
import { useTranslation } from 'react-i18next';
import { convertPath } from '../../../utils/pathRoutesUtils';
import { PATH_ERROR } from '../../AppSectionRouter/constants';
//#endregion

//#region /////// VARIABLES goBackFunction ///////
const redirect = {
  url: '/',
  countGoBack: 0,
  boolGoBack: false
}
//#endregion

function ContentOverViewSection({ accessToken, storeTime, isLoading }) {
  const router = useRouter();
  const { t } = useTranslation();
  const contentID = router.query.contentID;
  const mainDiv = React.useRef();
  const translationTexts = useWidgetTranslation(TITLES);
  const [showFooter, setShowFooter] = React.useState(false);
  const dispatch = useDispatch();

  // const [contentRelatedId, setContentRelatedId] = React.useState(0);
  // const [contentEpisodeId, setContentEpisodeId] = React.useState(0);

  //#region /////// SELECTORS ///////
  const selectedContent = useSelector(state => selectors.selectContentWidget(state));
  const selectedContentRelated = useSelector(state => selectors.selectContentRelatedWidget(state));
  const selectContentEpisodes = useSelector(state => selectors.selectContentEpisodesWidget(state));
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const appSettings = useSelector(state => selectors.selectAppSettings(state));
  const networks = useSelector(state => selectors.selectNetworks(state));
  const profileSelectedID = useSelector(state => selectCurrentProfile(state));
  const redirectUrl = useSelector(state => selectors.selectContentsUrlByID(state));
  const currentContentPosition = useSelector(state => selectCurrentContentPosition(state));
  const selectError = useSelector(state => selectors.selectError(state));
  const favorites = useSelector(state => selectors.selectFavoritesContents(state))
  const favoritesIsFetching = useSelector(state => selectors.selectFavoritesIsFetching(state));
  const PATHS = useSelector(state => selectors.selectPathList(state));
  const selectRatingImages = useSelector(state => selectors.selectRatingImages(state));
  const notificationModalCreate = useNotificationModalCreate(selectedContent, appSettings.packageUpgradeMessages, PATHS);
  //#endregion

  //#region ///// HOOK EFFECTS /////
  hooks.useDidMount(accessToken, contentID, mainDiv, redirectUrl, authenticated, PATHS, selectRatingImages);
  const toastAdd = hooks.useToastAdd();
  hooks.useStoreTimeOnClickBack(storeTime, redirect, router, PATHS)
  const  { contentEpisodesState, currentSeason } = hooks.useBookmarkEpisodes(selectContentEpisodes,currentContentPosition)
  //#endregion

  const onClickPlayerButton = (contentId) => {
    const currentContent = selectedContent?.contentType === CONTENT_TYPES.SERIE
      ? selectContentEpisodes?.result[0]
      : selectedContent;

    const hasRedirect = validateUrlRedirect({ currentContent });

    hasRedirect && authenticated && dispatch(actions.getContentsUrlById(accessToken, contentId));

    if (!isDesktop) {
      alert('Visualiza este contenido descargando la app');
    } else if (checkifHaveContentAccess(authenticated, selectedContent)) {

       if(!hasRedirect) {
          redirect.countGoBack = 0;
          const subPath = (currentContentPosition?.bookmarkEpisodes && 
                           currentContentPosition?.bookmarkEpisodes.length > 0 && 
                           selectedContent?.contentType === CONTENT_TYPES.SERIE) ? 
                                currentContentPosition?.bookmarkEpisodes[0].contentId 
                                : 
                                currentContentPosition ? currentContentPosition.currentContent.id 
                                :
                                contentId;
          
                                router.push(convertPath(PATHS.player,subPath))
        }

    } else {
      redirect.countGoBack = 0;
      const { origin } = window.location;
      const returnURL = origin + convertPath(PATHS.content,contentID);
      router.push(PATHS.login, { returnURL });
    }
  }

  const onClickEpisode = (contentId) => {
    const hasRedirect = validateUrlRedirect({
      currentContent: selectContentEpisodes?.result[0]
    });
    
    hasRedirect && authenticated && dispatch(actions.getContentsUrlById(accessToken, contentId));

    if (!isDesktop) {
      alert('Visualiza este contenido desde una PC.');
    } else if (checkifHaveContentAccess(authenticated, selectedContent)) {

       if(!hasRedirect) {
          redirect.countGoBack = 0;
          router.push(convertPath(PATHS.player,contentId));
        }

    } else {
      redirect.countGoBack = 0;
      const { origin } = window.location;
      const returnURL = origin + convertPath(PATHS.content,contentID);

      router.push(PATHS.login, { returnURL });
    }
  }

  const onClickContentSelected = (ev, contentid) => {
      ev.preventDefault();

      if(!contentid){
        return false;
      }
  
      redirect.countGoBack = 0;
      setShowFooter(false);
      
      router.push(convertPath(PATHS.content,contentid));
  };


  const goBackFunction = React.useCallback(async () => {
    if ((redirect.countGoBack >= 2 && redirect.boolGoBack) ||
      router.location.state?.oneBack.path === PATHS.player ||
      !router.location.state?.oneBack.path) {
        redirect.countGoBack = 0;
      return router.push(redirect.url);
    }
    redirect.countGoBack = redirect.countGoBack + 1;
    return router.goBack();
  }, [router]);

  const catchError = (error) => {
    return router.push(PATH_ERROR, { error });
  };

  React.useEffect(()=>{
    // REDIRECT TO FICHA FUTURA
    if ( selectedContent?.live ) {
      router.replace(convertPath(PATHS.live, selectedContent?.id));
    }

    if (ERROR_CODE[selectError.code] || !!selectError.code && !!selectError.details && !!selectError.message){
      if ( selectError?.code === 'CS-011' ) {
        notificationModalCreate(selectError);
        dispatch(actions.clearErrorStates());
        return;
      }
      toastAdd(selectError);
      return goBackFunction();
    }
  },[selectError]);


  React.useEffect(()=>{
    if((currentContentPosition?.currentContent?.serie?.id === contentID || currentContentPosition?.currentContent?.id === contentID) && !currentContentPosition?.currentContent?.entitlementTypes[0]){
      toastAdd("NOT_ENTITLEMENTS");
    }
  },[currentContentPosition?.currentContent])

  const onClickMyListButton = (contentId, favoritesAdded) => {
    favoritesAdded ? dispatch(deleteFavoriteContentByProfile(contentId)): dispatch(addFavoriteContentByProfile(contentId));
  }

  const contentNoImageProps = (imageMode)=>{
    const { w, h } = findImageSettingByType(appSettings.imageSizes, imageMode)
    return {
    width: w || NO_IMAGE_DEFAULT(imageMode).width,
    height: h || NO_IMAGE_DEFAULT(imageMode).height,
    backgroundColorOne: NO_IMAGE_DEFAULT().backgroundColorOne,
    backgroundColorTwo: NO_IMAGE_DEFAULT().backgroundColorTwo
    }
  }

  const handleSearchContentByFilter = React.useCallback(async (searchText, filterName) => {
    router.push(`${PATHS.search}?${filterName}=${searchText}`);
  }, [router]);

  const inFavoritesAdded = validateFavoriteContent(favorites, contentID);
  const truncatedDescription = truncateString(selectedContent?.description, TRUCATE_STRING.METATAGS_DESCRIPTION);
  const imageUrl = getContentImageByMode(selectedContent?.images, 'THUMB')?.url;


  const ratingsList = {};
  Object.entries(RAITINGS_DEFAULT).forEach(([key, value]) => {
    ratingsList[key] =  t(value)
  });

  const mustShowRatingImage = isTokenFromBR(accessToken?.access_token) || SHOW_RATING_IMAGE;

  return (
    <div ref={mainDiv} className="main-content">
     {selectedContent && <Seo title={`${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.contentType === CONTENT_TYPES.SERIE ? GA_SECTION_NAMES.SERIES : GA_SECTION_NAMES.MOVIES} - ${selectedContent?.title}`} contentTitle={`${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title}`} description={truncatedDescription} image={imageUrl} /> }
      <div onLoad={() => setShowFooter(false)} className="ContentOverviewSection">
        {<ContentOverview 
            translationTexts={translationTexts} 
            content={selectedContent} 
            onClickPlayerButton={onClickPlayerButton} 
            onClickBackButton={goBackFunction} 
            catchError={catchError} 
            isLoading={!selectedContent} 
            contentNoImageProps={contentNoImageProps(IMAGE_MODE.POSTER)}
            currentContentPosition={currentContentPosition} 
            disabledPlayButton={authenticated && !currentContentPosition?.currentContent?.entitlementTypes[0]}
            inFavoritesAdded={inFavoritesAdded}
            favoritesIsFetching={favoritesIsFetching}
            handleSearchContentByFilter={handleSearchContentByFilter}
            onClickMyListButton= {authenticated && onClickMyListButton}
            ratingImages={selectRatingImages}
            mustShowRatingImage={mustShowRatingImage}
            redirectContent={true}
            showPlayIcon={false}
          />
        }
      </div>
      <div>
        {selectedContent?.contentType === CONTENT_TYPES.MOVIE &&
          <ContentRelated 
          content={selectedContentRelated} 
          translationTexts={translationTexts}
          contentNoImageProps={contentNoImageProps(IMAGE_MODE.POSTER)} 
          onClickContentSelected={onClickContentSelected} 
          isLoading={!selectedContentRelated} 
          catchError={() => { }} />
        }
        {selectedContent?.contentType === CONTENT_TYPES.SERIE &&
          <ContentEpisodes 
          translationTexts={translationTexts} 
          content={authenticated ? contentEpisodesState : selectContentEpisodes} 
          contentNoImageProps={contentNoImageProps(IMAGE_MODE.THUMB)} 
          onClickContentSelected={onClickEpisode} 
          isLoading={authenticated ? !contentEpisodesState : !selectContentEpisodes}
          catchError={catchError} 
          countItem={5} 
          currentSeason ={currentSeason}
          disabledEpisodes={authenticated && !currentContentPosition?.currentContent?.entitlementTypes[0]}/>
        }
      </div>
      {showFooter && <Footer />}
    </div>
  );
}

export default SectionErrorBoundary(ContentOverViewSection)