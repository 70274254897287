import React from 'react';
import './styles.scss';

function LoadingSpinner({ className }) {

    return (
        <div className={"tbx-LoadingSpinner_container " + className}>
            <div className={"tbx-LoadingSpinner"} />
        </div>
    );
};

export default LoadingSpinner;
