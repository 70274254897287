import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import {
  unityEPGService
} from '@tbx/experience-widgets-lib';

import * as actions from './actions';
import { types } from './constants';

function* fetchEPGDataByIds(action) {
  const { unityToken, epgID, from, to } = action;
 
  try {
    const { result } = yield call(unityEPGService.fetchEPGDataByIds, unityToken, {
      epgID: epgID,
      from: from.toISOString(),
      to: to.toISOString()
    });

    if (!result || result.error) {
      throw new Error(result);
    }else{
      yield put(actions.setEpgDataByIdsSuccess(result));
    }


  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('FETCH_EPG_DATA_BY_IDS_ERROR: ', errObj);
    yield put(actions.setEpgDataByIdsError(errObj));
  }
}

function* saga() {
  /*yield takeLatest(types.WIDGET_FETCH, fetchContentWidget);*/
  yield takeLatest(types.FETCH_EPG_DATA_BY_IDS, fetchEPGDataByIds);
}

export default saga;