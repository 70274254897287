import { types } from './constants';

const INITIAL_STATE = {
  error: {
    code: null,
    details: null,
    message: null
  },
  isFetching: true,
  selectedContent: null,
  redirectUrl: null,
  selectedContentRelated: null
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.WIDGET_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.WIDGET_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...action.error
        },
        isFetching: false
      };
    case types.WIDGET_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selectedContent: action.content,
      };
    case types.RELATED_WIDGET_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selectedContentRelated: action.content
      };
    case types.CONTENTS_URL_ID_FETCH:
        return {
          ...state,
          error: {
            ...INITIAL_STATE.error
          },
          isFetching: true
        };
    case types.CONTENTS_URL_ID_FETCH_FAILURE:
        return {
          ...state,
          error: {
            ...action.error
          },
          isFetching: false
        };
    case types.CONTENTS_URL_ID_FETCH_SUCCESS:
        const { entitlements } = action.content;
        const entitlementsType = entitlements.find(e => e.type === 'redirect');
        return {
          ...state,
          isFetching: false,
          redirectUrl: entitlementsType?.url
        };
    case types.CLEAR_STATES:
      return {
        ...INITIAL_STATE
      };
    case types.CLEAR_ERROR_STATES:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        }
      };
    default:
      return state;
  }
};

export default reducer;
