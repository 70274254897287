import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Button, Col, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getProfileTypeName, isAdminProfile } from '../../../utils/ProfileUtils';
import { MAX_RATING_DEFAULT, PROFILE_TYPES } from '../../../containers/ProfileManager/constants';
import { PinCodeInput } from '@tbx/experience-widgets-lib';
import { NUMBER_FIELDS_PIN } from '../../../containers/ProfileManager/constants';
import './styles.scss';
import { PROFILE_NAMES } from '../../../constants/contentTypes';
import { PROFILE_NAME_MAX_LENGTH } from '../../../constants/profileTypes';

function ProfileForm({
  isAddMode,
  profileDataForm,
  handleProfileTypeSelected,
  handleOnChangeProfileNameForm,
  handleCancel,
  handleSubmit,
  currentDataProfileSelected,
  profileCollectionSize,
  handlePinCodeChange,
  handleDeletePinCode,
  pinCodeValue,
  profileCounts
}) {

  const { t } = useTranslation();
  const { name: profileNameForm, hasPIN } = profileDataForm;
  const { max_rating: maxRatingProfileSelected } = currentDataProfileSelected || {};

  let profileTypes = [...PROFILE_TYPES];
  const filteredProfileTypes = !isAddMode && (profileCollectionSize === 1 || profileCounts.adultCount < 2) ? profileTypes.filter(p => p.max_rating !== MAX_RATING_DEFAULT.KID) : profileTypes;

  return (
    <Form className="profileForm__container" onSubmit={(e) => handleSubmit(e)}>
      <Row className="mb-4">
        <Form.Group as={Col} controlId="formProfileName">
          <input
            className={"formProfileNameRow__input"}
            type="text"
            placeholder={t('Name of Profile')}
            value={profileNameForm}
            maxLength={PROFILE_NAME_MAX_LENGTH.CUSTOM}
            onChange={(event) => handleOnChangeProfileNameForm(event)}
          />
        </Form.Group>
      </Row>
      {isAdminProfile(maxRatingProfileSelected) && profileCollectionSize > 0 && PROFILE_TYPES.length > 1 &&
        <>
          <Row className={`formGridValidationProfileTypeRow ${ !isAddMode ? 'disabledRow': '' }`}>
            <Dropdown className='dropDownProfile' align="start">
              <Dropdown.Toggle className='dropDownToggleProfile bi bi-chevron-down'>
                <label>{t(getProfileTypeName(profileDataForm?.maxRating))}</label>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {filteredProfileTypes.map((p) =>
                  <Dropdown.Item
                    key={p.name}
                    className={p.max_rating === profileDataForm?.maxRating ? 'active' : ''}
                    onClick={() => handleProfileTypeSelected(p.max_rating)}
                  >
                    {t(p.name)}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Row>
        </>
      }
      {
        (profileDataForm?.maxRating <= MAX_RATING_DEFAULT.KID) && isAdminProfile(maxRatingProfileSelected) &&
        <>
          <hr className="form__divisor" />
          <Row className="formPinRow">
            <Form.Group as={Col} controlId="formProfileName">
              <Form.Label>{isAddMode ? t('Create your pin') : t('Edit your pin')}</Form.Label>
              {hasPIN && <div className='pinCodeDelete' onClick={() => handleDeletePinCode()}><label>{t('Delete pin')}</label><i className="bi bi-trash trashIcon"></i> </div>}
              <PinCodeInput
                handlePinCodeChange={handlePinCodeChange}
                numberOfFields={NUMBER_FIELDS_PIN.CUSTOM}
                profileWithCodePin={hasPIN}
                validateFormat={'0123456789'}
                classNameCustom={'pinCodeForm'} />
            </Form.Group>
          </Row>
        </>

      }
      <Row className="mb-3 mt-5" controlId="formGridSubmit">
        <Col>
          <Button
            className="profileForm__container_cancel_button"
            size="lg"
            variant="secondary"
            onClick={handleCancel}
          >
            {t('Cancel')}
          </Button>
        </Col>
        <Col>
          <Button
            className={"profileForm__container_create_button"}
            size="lg"
            variant="primary"
            type="submit"
            disabled={(!profileNameForm || profileNameForm === PROFILE_NAMES.DEFAULT || !(profileNameForm && (pinCodeValue.length === NUMBER_FIELDS_PIN.CUSTOM || !pinCodeValue)))}>
            {t('Save changes')}
          </Button>
        </Col>
      </Row>
      {/* <Row>
        { isAddMode && <span className={"profileForm__container_admin_profile"}>{t('Admin profile information')}</span> }
      </Row> */}
    </Form>

  );
}

ProfileForm.propTypes = {
  isAddMode: PropTypes.bool,
  disableFormButton: PropTypes.bool,
  profileDataForm: PropTypes.object,
  handleProfileTypeSelected: PropTypes.func,
  handleOnChangeProfileNameForm: PropTypes.func,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  currentDataProfileSelected: PropTypes.object,
  profileCollectionSize: PropTypes.number,
  profileCounts: PropTypes.object
};

export default React.memo(ProfileForm);
