import { types } from './constants';

const INITIAL_STATE = {
  recommended: false,
  emptyResult: false,
  isSearching: false,
  page: {},
  searchErrors: null,
  searchResult: [],
  userInput: ''
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CONTENT_SELECTED:
      return {
        ...state,
        selectedContent: action.content,
        toggleModal: true
      };
    case types.SEARCH_CONTENTS:
      return {
        ...state,
        emptyResult: false,
        isSearching: true
      };
    case types.SEARCH_CONTENTS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchErrors: action.errors
      };
    case types.SEARCH_CONTENTS_SUCCESS:
      const { page, result } = action.results;
      return {
        ...state,
        emptyResult: action.emptyResult,
        recommended: action.recommended,
        isSearching: false,
        page: {
          ...page
        },
        searchErrors: null,
        searchResult: [
          ...result
        ],
      };
    case types.SEARCH_INPUT_CHANGE:
      return {
        ...state,
        userInput: action.text
      };
    case types.SEARCH_INPUT_CLEAR:
      return {
        ...INITIAL_STATE
      };
    case types.SEARCH_LOAD_MORE_CONTENTS:
      const { results: resultData } = action;
      let newResult = [];

      const dataFounded = resultData.result.length > 1;

      if (dataFounded) {
        newResult = [
          ...state.searchResult,
          ...resultData.result
        ];
      }

      return {
        ...state,
        emptyResult: !dataFounded,
        isSearching: false,
        page: {
          ...resultData.page
        },
        searchErrors: null,
        searchResult: [
          ...newResult
        ],
      };
    default:
      return state;
  }
};

export default reducer;
