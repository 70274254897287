import { LIMIT_ITEMS } from '../../../constants/contentTypes';
import { types } from './constants';

//TODO: State con datos mockeados para POC, se debe pensar en un endpoint que devuelva
// una coleccion de los contentlist que se desea mostrar en carouseles

const INITIAL_STATE = {
  error: {
    code: null,
    details: null,
    message: null
  },
  isFetching: true,
  selectedContent: null,
  toggleModal: false,
  page: 0,
  widgetCollection: [],
  widgetCollectionParent: [],
  containerWidget: [],
  widgetCollectionPaginate: []

};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CONTENT_SELECTED:
      return {
        ...state,
        selectedContent: action.content,
        toggleModal: true
      };
    case types.TOGGLE_MODAL:
      return {
        ...state,
        toggleModal: action.show
      };
    case types.WIDGETS_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.WIDGETS_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...action.error
        },
        isFetching: false
      };
    case types.WIDGETS_FETCH_SUCCESS:
      const { widgetsCollection } = action;


      const resultReduce = widgetsCollection.result.reduce((previusResult, r) => {
        const { parentComponent, componentType } = r;
        const addComponent = (index, r) => {
          previusResult[index] = [...(previusResult[index] || []), r]
        }
        if (!!parentComponent && componentType !== 'grid') addComponent(0, r); //widgetCollection
        if (!!parentComponent && componentType === 'grid') addComponent(1, r); //widgetCollectionParent
        if (!parentComponent && componentType === 'grid') addComponent(2, r); //containerWidget
        return previusResult;
      }, []);

      const orderSort = (aOrder,bOrder) => +(aOrder > bOrder) || +(aOrder === bOrder) - 1;


      resultReduce[1].sort((a, b) => orderSort(a.order,b.order));

      const parentOrder = (component) => {
          const parentComponentOrder = resultReduce[1].find(({ id }) => id === component.parentComponent)?.order
          if (typeof parentComponentOrder === 'number') return parentComponentOrder;
          return component.order;
      }

      resultReduce[0].sort(function (a, b) {
        if (parentOrder(a) < parentOrder(b)) return -1
        return orderSort(a.order,b.order);
      });


      return {
        ...state,
        isFetching: false,
        widgetCollection: resultReduce[0],
        widgetCollectionParent: resultReduce[1],
        containerWidget: resultReduce[2],
        widgetCollectionPaginate: resultReduce[0].slice(0, LIMIT_ITEMS.HSTACK),
        page: 1
      };
    case types.WIDGETS_LOAD_MORE:
      const maxPages = state.widgetCollection?.length / LIMIT_ITEMS.HSTACK;
      const page = state.page;

      if (page < maxPages) {
        const newData = state.widgetCollection.slice(0, ((LIMIT_ITEMS.HSTACK) * (page + 1))) || []

        return {
          ...state,
          widgetCollectionPaginate: newData,
          page: page + 1
        };

      } else return { ...state }
    case types.LOAD_SECTION_ERROR:
      return {
        ...state,
        error: {
          ...action.error
        }
      };
    case types.CLEAR_SECTION_ERROR:
        return {
          ...state,
          error: {
            ...INITIAL_STATE.error
          }
        };
    default:
      return state;
  }
};

export default reducer;
