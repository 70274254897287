import CONFIG from "../config";

export const getIdpClient = (clientId) => {
    let idpClient = CONFIG.cloudpass.IDP_CLIENT;
    if(CONFIG.cloudpass.CLIENTS_LIST){
      let defaultClient = null;
      const idpClientR = CONFIG.cloudpass.CLIENTS_LIST.find((porps)=>{
        defaultClient = porps?.default ? porps.idpClient : defaultClient;
        return (porps.client === clientId)
      })?.idpClient;
      idpClient = idpClientR || defaultClient || idpClient;
    }

    return idpClient;
}

export const getContentListSuggestions = (clientId) => {
  let contentListSuggestions = window.__TBX_ENV__.TBX_CONTENT_LIST_SUGGESTIONS;
  if(CONFIG.cloudpass.CLIENTS_LIST){
    let defaultContentListSuggestions = null;
    const contentListSuggestionsR = CONFIG.cloudpass.CLIENTS_LIST.find((porps)=>{
      defaultContentListSuggestions = porps?.default ? porps.contentListSuggestions : defaultContentListSuggestions;
      return (porps.client === clientId)
    })?.contentListSuggestions;
    contentListSuggestions = contentListSuggestionsR || defaultContentListSuggestions || contentListSuggestions;
  }

  return contentListSuggestions;
}