import React from 'react';
import useWidgetTranslation from '../../customHooks/useWidgetTranslation';
import {
  EPGGrid
} from '@tbx/experience-widgets-lib';
import _ from 'lodash';

import { LIVE_PLAYER, TITLES } from './constants';
import { useTranslation } from 'react-i18next';
import "./styles.scss"
import * as hooks from './hooks';
import reducer , { INITIAL_STATE } from './reducer';
import { IMAGE_MODE } from '../../constants/contentTypes';

function EpgComponent({ content, isLoading, unityToken, contentNoImageProps }) {
  const [state, dispatch] = React.useReducer(reducer, {
    ...INITIAL_STATE
  });
  const { t } = useTranslation();
  
  const translationTexts = {
    ...useWidgetTranslation(TITLES),
    live_player: t(LIVE_PLAYER)
  };
  const daysBefore = 2;
  const daysAfter = 2;
  
  hooks.useDidMount(content, unityToken, daysBefore, daysAfter, state.epgDataByIds ,state.content, dispatch)
  

  return (
    <div className="epgComponent" >
        {content?.length > 0 && <EPGGrid
          content={state?.epgList.length > 0 ? state.epgList : content}
          translationTexts={translationTexts}
          daysAfter={daysAfter}
          daysBefore={daysBefore}
          programItemHeaderComponent="COMPONENT_TWO"
          daysMaxRange={15}
          displayedItemsNum={3}
          imageMode={IMAGE_MODE.THUMB}
          isLoading={isLoading}
          locale={'es'}
          onChangeDayCallback={(dayItem) => console.log('>> Selected dayItem:', dayItem)}
          onSelectedProgramItemCallback={()=>{}}
          title={'Title'}
          unityToken={unityToken}
          onClickContentSelected={()=>{}}
          isContentSelected={false}
          isImgProgramItemBody={true}
          isDateInBody={false}
          contentNoImageProps={contentNoImageProps}
          showPlayIcon={false}
        />}
    </div>
  );
}


export default EpgComponent;