import { types } from './constants';


export const fetchEPGDataByIds = (unityToken, epgIds, dateFrom, dateTo) => ({
  type: types.FETCH_EPG_DATA_BY_IDS,
  unityToken: unityToken,
  epgID: epgIds,
  from: dateFrom,
  to: dateTo
})

export const setEpgDataByIdsSuccess = (result) => ({
  type: types.EPG_DATA_BY_IDS_SUCCESS,
  result
});

export const setEpgDataByIdsError = (error) => ({
  type: types.EPG_DATA_BY_IDS_ERROR,
  error
});


export const selectContent = (content, epgList) => ({
  type: types.CONTENT_SELECTED,
  content,
  epgList
});