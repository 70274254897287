import { types } from './constants';

//TODO: State con datos mockeados para POC, se debe pensar en un endpoint que devuelva

const INITIAL_STATE = {
  error: {
    code: null,
    details: null,
    message: null
  },
  isFetching: true,
  selectedContent: null,
  isNextEpisode: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CONTENT_SELECTED:
      return {
        ...state,
        selectedContent: action.content
      };
    case types.WIDGET_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.WIDGET_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...action.error
        },
        isFetching: false
      };
    case types.WIDGET_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        selectedContent: action.content
      };
    case types.START_NEXT_EPISODE_SUCCESS:
      return {
        ...state,
        isNextEpisode: action.status
      };
    case types.CLEAR_STATES:
      return {
        ...INITIAL_STATE,
        isNextEpisode: state.isNextEpisode
      };
    case types.CLEAR_IS_NEXT_EPISODE_PLAYER:
        return {
          ...state,
          isNextEpisode: false
        };
    default:
      return state;
  }
};

export default reducer;
