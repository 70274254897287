import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory }  from 'history';
import createSagaMiddleware from 'redux-saga';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createJWTRefreshMiddleware from './middlewares/jwtRefresh';
import createGlobalReducer from './global-reducer';
import globalSagas from './global-sagas';
import reducerRegistry from './reducerRegistry';

import { INITIAL_STATE as SESSION_INITIAL_STATE } from '../containers/App/sessionReducer';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const jwtRefreshMiddleware = createJWTRefreshMiddleware();

const middlewares = [
  // Middleware for intercepting and dispatching navigation actions
  routerMiddleware(history),
  sagaMiddleware,
  jwtRefreshMiddleware
];

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const migrations = {
  0: state => SESSION_INITIAL_STATE
};

const persistConfig = {
  key: 'tbx',
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  version: 0,
  whitelist: ['session']
};

const rootReducer = createGlobalReducer(history, reducerRegistry.getReducers());
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

// Replace the store's reducer whenever a new reducer is registered.
reducerRegistry.setChangeListener(reducers => {
  store.replaceReducer(createGlobalReducer(history, reducers));
});

sagaMiddleware.run(globalSagas);

export const persistor = persistStore(store);
export default store;
