import { FILTER_ORDER_TYPES } from '../../components/HStack/constants';
import { types } from './constants';

export const INITIAL_STATE = {
  componentItems: {
    itemCollection: [],
    page: null,
    totalCount: 0
  },
  errors: null,
  isFetchingData: true,
  orderTypeSelected: 'popularity'
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UNITY_COMPONENT_FETCH:
      return {
        ...state,
        errors: null,
        isFetchingData: true
      };
    case types.UNITY_COMPONENT_FETCH_FAILURE:
      return {
        ...state,
        errors: action.errors,
        isFetchingData: false
      };
    case types.UNITY_COMPONENT_FETCH_SUCCESS:
      const { componentItems } = action;
      return {
        ...state,
         componentItems: {
          itemCollection: componentItems.result,
          page: componentItems.page,
          totalCount: componentItems.count
        },
        errors: null,
        isFetchingData: false
      };
      case types.UNITY_LOAD_MORE_DATA_COMPONENT_SUCCESS:
    
        let newResult = [];
        const dataFounded = action?.componentItems?.result.length > 0;

        if (dataFounded) {
          newResult = [...state.componentItems.itemCollection, ...action.componentItems.result]
        }
      
        return {
          ...state,
          componentItems: {
            itemCollection: [ ...newResult],
            page: action.componentItems.page,
            totalCount: action.componentItems.count
          }
        };
    case types.UNITY_COMPONENT_SELECT_ORDER_TYPE:
      return {
        ...state,
        orderTypeSelected: action.orderTypeSelected
      };
    default:
      return state;
  }
};

export default reducer;
