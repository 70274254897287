export function parseErrorToNewRelic(inError){

    let parsedError = {
        message: "",
        stack: "",
        lineNumber: "",
        fileName: "",
        columnNumber: "",
        name: ""     
    };

    if( typeof inError === "string" ){
        parsedError.message = inError;
        window.newrelic.noticeError(parsedError);
        return;
    }

    if( typeof inError === "object" ){
        
        parsedError = {...parsedError,...inError};

        if(inError.code){
            parsedError.message = `UNITY ERROR: [${inError.code}]`;
        }

        if(inError.componentStack){
            parsedError.stack = inError.componentStack;
        }

        window.newrelic.noticeError(parsedError);
        return;
    }
}