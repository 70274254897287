import { CONTENT_ID_CONST, SUBPATHS } from "../containers/AppSectionRouter/constants"

export const convertPath = (path, idContent) => {
    return path.replace(CONTENT_ID_CONST, idContent)
}

export const isPath = (path,pathnameUrl) => {
    const converterToArray = (pathname) => {
        return pathname.slice(1, pathname.length).split('/');
    }

    const pathArray = converterToArray(path)
    const pathnameUrlArray = converterToArray(pathnameUrl)

    return pathArray.every((p,inex)=>p===CONTENT_ID_CONST || pathnameUrlArray[inex] === p)
}


/**
 * Validate subpath to redirect. Default value /content
 * @param {String} contentType
 * 
 * @return {String}
 */
 export const getPathNameByContentType = (contentType,PATHS) => {
    const subPathsObj = Object.entries(SUBPATHS);

    for(const val in subPathsObj) {
        if(subPathsObj[val][1].includes(contentType)){
            return PATHS[subPathsObj[val][0]];
        }
    }

    return PATHS[subPathsObj[0][0]];
 
};