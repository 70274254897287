import React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import useWidgetTranslation from '../../../customHooks/useWidgetTranslation';
import {
  Player,
  WithTBXPlayerScripts
} from '@tbx/experience-widgets-lib';
import { isDesktop } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

import * as hooks from './hooks';
import * as selectors from './selectors';
import useRouter from '../../../customHooks/useRouter';
import { checkifHaveContentAccess } from '../../../utils/validationUserUtils';
import './styles.scss';
import { CONTENT_TYPES, TRUCATE_STRING } from '../../../constants/contentTypes';
import { SectionErrorBoundary } from '../../../HOCs/WithErrorBoundary';
import { TITLES } from './constants';
import { ERROR_CODE_FORMAT } from '../../../constants/errorCode';
import { usePlayerCallbackState } from '../../App/playerCallbackContextProvider';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getSeoTitleInPlayerSection, validateGoBackOnCompletion } from '../../../utils/ContentUtils';
import { startNextEpisode } from './actions';
import { ENVIROMENTS } from '../../../utils/envUtils';
import Seo from '../../../components/Seo';
import { BRAND_INFO } from '../../../constants/utilTypes';
import { truncateString } from '../../../utils/objectUtils';
import { PATH_ERROR } from '../../AppSectionRouter/constants';
import { convertPath } from '../../../utils/pathRoutesUtils';

function PlayerSection({ accessToken, playerScriptsLoaded, playerScriptsError }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const playerCallbackState = usePlayerCallbackState();
  const translationTexts = useWidgetTranslation(TITLES)
  const contentID = router.query.contentID;
  const playerID = _.uniqueId('player-');
  const PLAYERSECTION_CONTAINER_REF = React.useRef();

  //#region /////// SELECTORS ///////
  const { appId, packageUpgradeMessages } = useSelector(state => selectors.selectAppSettings(state));
  const selectedContent = useSelector(state => selectors.selectSelectedContent(state));
  const isFetching = useSelector(state => selectors.selectIsFetching(state));
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const selectError = useSelector(state => selectors.selectError(state));
  const selectLastEpisodeID = useSelector(state => selectors.selectLastEpisodeIdInSerie(state));
  const isNextEpisode = useSelector(state => selectors.selectIsNextEpisode(state));
  const PATHS = useSelector(state => selectors.selectPathList(state));
  //#endregion

  //#region ///// HOOK EFFECTS /////
  hooks.useDidMount(accessToken, contentID);
  hooks.usePlayerCallbackConfig(playerCallbackState, contentID, selectedContent, PATHS);
  const toastAdd = hooks.useToastAdd();
  const notificationModalCreate = hooks.useNotificationModalCreate(selectedContent, packageUpgradeMessages, PATHS);
  const useLoadWidgetError = hooks.useLoadWidgetError(playerScriptsError, contentID);
  //#endregion

  React.useEffect(() => {

    const { CL001, CL002, CP006, CS011, CP015, CP037, CP137, SE003, GE001 } = ERROR_CODE_FORMAT;

    const modalCode = [CL001, CL002, CP006, CS011, CP015, CP037, CP137, GE001];

    if (SE003 === selectError?.code) {
      toastAdd({
        code: SE003,
      })
      return router.push(PATHS.home);
    }

    if (modalCode.some((ec) => ec === selectError?.code)) {
      notificationModalCreate(selectError);
      onBack(selectedContent);
    } else if (selectError?.code) {
      toastAdd(selectError)
      onBack(selectedContent)
    }

    if (!!playerScriptsError) {
      toastAdd(playerScriptsError)
      return router.push(PATHS.home);
    }

  }, [selectError])

  const onBack = React.useCallback(async (content) => {
    if (!PLAYERSECTION_CONTAINER_REF?.current) return;
    PLAYERSECTION_CONTAINER_REF.current.style.visibility = 'hidden';
    PLAYERSECTION_CONTAINER_REF.current.style.opacity = '0';
    if (content.contentType === CONTENT_TYPES.BROADCAST) {
      if (!!router?.location?.state?.oneBack?.url) {
        return router.push(router?.location?.state?.oneBack?.url);
      } else {
        return router.push(PATHS.home);
      }
    }
    let contentId = (content.contentType === CONTENT_TYPES.EPISODE) ? content.serie.id : content.id;
  
    return content.clip && router?.location?.state
        ? router.goBack() 
        : content.clip 
                ?  router.push(PATHS.home) 
                :  router.push(convertPath(PATHS.content,contentId));
  }, [router]);

  if (isFetching) {
    return (<div className='playerSectionContainer playerSectionContainer_loading'>
      <LoadingSpinner
        className="playerSectionContainer_spineer"
      />
    </div>)
  }

  const catchError = (error) => {
    return router.push(PATH_ERROR, { error, navBar: true });
  };

  const refreshPlayerInChangeSource = (playerId, contentTypeNextEpisode) => {

    if(playerId && contentTypeNextEpisode === CONTENT_TYPES.EPISODE) {
      dispatch(startNextEpisode(true, router, playerId, PATHS.player));
    }
     
  }

  const truncatedDescription = truncateString(selectedContent?.description, TRUCATE_STRING.METATAGS_DESCRIPTION);

  return (playerScriptsLoaded && <div className="playerSectionContainer" ref={PLAYERSECTION_CONTAINER_REF}>
    {selectedContent && <Seo title={getSeoTitleInPlayerSection(selectedContent, t)} contentTitle={`${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title}`} description={truncatedDescription} /> }
      {
        !selectError?.code && isDesktop && checkifHaveContentAccess(authenticated, selectedContent)
          ?
          <Player
            content={selectedContent}
            onClickBackButton={() => onBack(selectedContent)}
            playerHandleError={useLoadWidgetError}
            catchError={catchError}
            translationTexts={translationTexts}
            playerCallback={playerCallbackState}
            LoadingComponent={LoadingSpinner}
            refreshPlayerInChangeSource={refreshPlayerInChangeSource}
            isNextEpisode={isNextEpisode}
            enableGoBackOnCompletion = {validateGoBackOnCompletion(selectedContent, selectLastEpisodeID)}
            options={{
              castAppId: appId,
              playerID: playerID,
              jwtToken: `${accessToken['auth_type']} ${accessToken['access_token']}`,
              fill: true,
              responsive: true,
              autoplay: true,
              pictureInPictureToggle: true,
              environment: ENVIROMENTS
            }}
          />
          : (selectedContent?.contentType === CONTENT_TYPES.BROADCAST ?  
          <Redirect to={PATHS.home} /> : 
          <Redirect to={convertPath(PATHS.content,contentID)} /> )

      }
    </div>
  );
}

export default SectionErrorBoundary(WithTBXPlayerScripts(PlayerSection));
