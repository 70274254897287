import { CONTENT_TYPES } from "../../constants/contentTypes";

export const types = {
  ROUTER_SECTIONS_FETCH: 'Router/SECTIONS_FETCH',
  ROUTER_SECTIONS_FETCH_FAILURE: 'Router/SECTIONS_FETCH_FAILURE',
  ROUTER_SECTIONS_FETCH_SUCCESS: 'Router/SECTIONS_FETCH_SUCCESS',
  ROUTER_SECTIONS_ADD_PATH_ROUTES: 'Router/PATH_ROUTES_ADD',
  ROUTER_SECTIONS_ADD_PATH_ROUTES_SECTION_COLLECTION: 'Router/PATH_ROUTES_ADD_SECTION_COLLECTION',
  ROUTER_SECTIONS_ADD_PATH_COUNTRY: 'Router/ROUTER_SECTIONS_ADD_PATH_COUNTRY'
};

export const CONTENT_ID_CONST = ':contentID';

export const PATHS_CONST = {
  home: '',
  logout: '/logout',
  login: '/login',
  search: '/search',
  content: `/content/${CONTENT_ID_CONST}`,
  live: `/live/${CONTENT_ID_CONST}`,
  player: `/player/${CONTENT_ID_CONST}`
};

export const SUBPATHS = {
  live: [CONTENT_TYPES.BROADCAST, CONTENT_TYPES.SPORT],

  content: [CONTENT_TYPES.MOVIE, CONTENT_TYPES.SERIE, CONTENT_TYPES.EPISODE]
};


export const PATHS_CONST_WITHOUT_COUNTRY = {
  active: '/activar'
}

export const PATH_ERROR = '/error'
