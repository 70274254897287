
export const types = {
  UNITY_COMPONENT_FETCH: 'ExperienceWidget/UNITY_COMPONENT_FETCH',
  UNITY_COMPONENT_FETCH_FAILURE: 'ExperienceWidget/UNITY_COMPONENT_FETCH_FAILURE',
  UNITY_COMPONENT_FETCH_SUCCESS: 'ExperienceWidget/UNITY_COMPONENT_FETCH_SUCCESS',
  UNITY_COMPONENT_SELECT_ORDER_TYPE: 'ExperienceWidget/UNITY_COMPONENT_SELECT_ORDER_TYPE',
  UNITY_LOAD_MORE_DATA_COMPONENT_SUCCESS: 'ExperienceWidget/UNITY_LOAD_MORE_DATA_COMPONENT_SUCCESS'
};

export const NO_IMAGE_DEFAULT = {
  width: 200,
  height: 300,
  backgroundColorOne: '#1C1C1C',
  backgroundColorTwo: '#343434',
}


export const LIST_SOURCES_NAMES = {
  BOOKMARKS: 'bookmarks',
  FAVORITES: 'favorites',
  SUGGESTED: 'suggested'
}

export const DISABLED_SKELETON_LIST_SOURCES = [LIST_SOURCES_NAMES.BOOKMARKS, LIST_SOURCES_NAMES.FAVORITES, LIST_SOURCES_NAMES.SUGGESTED];
export const LIST_SOURCES_FOR_AUTOREFRESH = [LIST_SOURCES_NAMES.BOOKMARKS, LIST_SOURCES_NAMES.FAVORITES];


export const MAX_ITEMS_TO_REQUEST_BY_LIST_SOURCE = {
  BOOKMARKS: 40
}