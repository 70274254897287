import React from "react";
import PropTypes from "prop-types";
import FadeIn from "react-fade-in";
import { NavLink } from "react-router-dom";
import { usePlayerCallbackState } from "../App/playerCallbackContextProvider";
import useRouter from "../../customHooks/useRouter";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  BurgerMenu,
  Imgcomponent,
  DropdownCountries,
} from "@tbx/experience-widgets-lib";
//#region /////// COMPONENTS ///////
import DropdownUserAccount from "../../components/DropdownUserAccount";
//#endregion

import ClientLogo from "../../static/images/logo.png";
import { ReactComponent as SearchLogo } from "../../static/images/search-icon.svg";
import { ReactComponent as AccountLogo } from "../../static/images/person-circle.svg";
import { ReactComponent as DropdownArrow } from "../../static/images/drop-down-arrow.svg";

import {
  selectAuthenticated,
  selectAccessToken,
  selectCountries,
} from "../App/selectors";
import {
  selectAccountMenuStatus,
  selectNavBarBool,
  selectSticky,
  selectPathList,
  selectPathCountry,
} from "./selectors";
import {
  selectAvatarCollection,
  selectProfileCollection,
} from "../ProfileManager/selectors";
import * as hooks from "./hooks";
import {
  getCurrentAvatar,
  getCurrentProfileData,
  isAdminProfile,
} from "../../utils/ProfileUtils";
import { decodeToken } from "../../utils/jwtUtils";
import classNames from "../../utils/classnameUtils";
import {
  changeEnableProfileOpeartions,
  changeFormProfileMode,
  changeProfileFormStatus,
  enablePinCodeValidateModal,
  fetchProfileById,
  setCurrentProfile,
  validatePinCodeProfileSuccess,
} from "../ProfileManager/actions";
import { Button } from "react-bootstrap";
import { toggleProfilesModal } from "../App/actions";
import "./styles.scss";
import { changeAccountMenuStatus } from "./actions";
import { updateUserInput } from "../sections/SearchSection/actions";
import { MAX_RATING_DEFAULT } from "../ProfileManager/constants";
import NavBarLink from "./NavBarLink";
import { BRAND_INFO } from "../../constants/utilTypes";
import { DEFAULT_CONTENT_PROVIDER_AUTH } from "../../constants/contentProviderAuth";
import { pathCountryAdd } from "../AppSectionRouter/actions";

function Navbar({ sections = [], subSections = [] }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const playerCallbackState = usePlayerCallbackState();
  const pathname = router.pathname;
  const history = router.history;
  const [goSection, setGoSection] = React.useState(pathname);
  const [openMenu, setOpenMenu] = React.useState(false);

  //#region /////// SELECTORS ///////
  const authenticated = useSelector((state) => selectAuthenticated(state));
  const avatarCollection = useSelector((state) =>
    selectAvatarCollection(state)
  );
  const profileCollection = useSelector((state) =>
    selectProfileCollection(state)
  );
  const stickyBool = useSelector((state) => selectSticky(state));
  const navBarBool = useSelector((state) => selectNavBarBool(state));
  const accountMenuOpen = useSelector((state) =>
    selectAccountMenuStatus(state)
  );
  const pathCountry = useSelector((state) => selectPathCountry(state));
  const countries = useSelector((state) => selectCountries(state));
  const accessToken = useSelector((state) => selectAccessToken(state));
  const PATHS = useSelector((state) => selectPathList(state));
  //#endregion

  const tokenData = accessToken.access_token
    ? decodeToken(accessToken.access_token)
    : {};
  const { profile } = tokenData;

  const countryCode = pathCountry?.substring(1).toUpperCase();
  const [selectedCountry, setSelectedCountry] = React.useState(countryCode);

  const changeSelectedCountry = (country) => {
    setSelectedCountry(country);

    const pathCountry = `/${country.toLowerCase()}`;
    localStorage.setItem("pathCountry", pathCountry);

    window.location.assign(window.location.origin);
  };

  React.useEffect(() => {
    if (countries?.result?.length) {
      const currentCountry = countries.result.find(
        (country) => country.shortCode === countryCode
      )?.shortCode;

      const country = currentCountry || DEFAULT_CONTENT_PROVIDER_AUTH.countryCode;
      setSelectedCountry(country);

      const pathCountry = `/${country.toLowerCase()}`;
      dispatch(pathCountryAdd(pathCountry));

      if ( !localStorage.getItem("pathCountry") ) {
        localStorage.setItem("pathCountry", pathCountry);
        window.location.assign(window.location.origin);
      }
    }
  }, [countries]);

  const currentAvatar = getCurrentAvatar(
    avatarCollection,
    profileCollection,
    profile
  );
  const currentProfileSelected = getCurrentProfileData(
    profileCollection,
    profile
  );
  const {
    hasPIN: hasPINProfileSelected,
    max_rating: maxRatingProfileSelected,
  } = currentProfileSelected || {};
  const adminProfile = isAdminProfile(currentProfileSelected?.max_rating);

  const handleOnClickLogin = (e) => {
    e.preventDefault();
    const { origin, pathname } = window.location;
    const returnURL = origin + pathname;

    history.push(PATHS.login, { returnURL });
  };

  const handleOnClickLogout = (e) => {
    playerCallbackState.clearPlayerCallback();
    e.preventDefault();
    history.push(PATHS.logout, { returnURL: origin });
  };

  const handleOnClickLogo = () => {
    // reset input state
  };

  const handleOnClickSearch = (e) => {
    e.preventDefault();
    if (!router.pathname.includes(PATHS.search)) {
      history.push(PATHS.search, { oneBack: { pathname: pathname } });
    } else {
      dispatch(updateUserInput(""));
    }
  };

  const findSectionImg = (images) => {
    return images?.length > 0 && images[0]?.url;
  };

  //#region /////// HOOKS ///////
  const { scrolling } = hooks.useScrolling();
  const [{ sortedSections }, { sortedSubSections }] = hooks.useDidMount(
    sections,
    subSections
  );
  //#endregion

  const onSectionSelected = (section) => {
    history.push(`${section}`);
    setGoSection(section === "/" ? PATHS.home : section);
    setOpenMenu(false);
  };

  const handleOpenMenuProfile = () => dispatch(changeAccountMenuStatus(true));

  const handleOnSelectProfile = (profileID) => {
    hasPINProfileSelected && maxRatingProfileSelected < MAX_RATING_DEFAULT.ADULT
      ? handleEnablePinCodeValidateModal(profileID)
      : handleOnSelectProfileWithoutPinCode(profileID);
  };

  const handleOnSelectProfileWithoutPinCode = (profileID) => {
    playerCallbackState.clearPlayerCallback();
    dispatch(changeAccountMenuStatus(false));
    dispatch(setCurrentProfile(profileID));
  };

  const handleMyProfileEdit = () => {
    playerCallbackState.clearPlayerCallback();
    dispatch(fetchProfileById(profile));
    dispatch(toggleProfilesModal(true));
    dispatch(changeProfileFormStatus(true));
    dispatch(changeFormProfileMode(false));
  };

  const handleManageProfiles = () => {
    playerCallbackState.clearPlayerCallback();
    dispatch(toggleProfilesModal(true));
    dispatch(changeEnableProfileOpeartions(true));
  };

  const handleProfileCreateOption = () => {
    dispatch(toggleProfilesModal(true));
    dispatch(changeProfileFormStatus(true));
    dispatch(changeFormProfileMode(true));
  };

  const setOpenProfileMenu = (status) => {
    dispatch(changeAccountMenuStatus(status));
  };

  const handleEnablePinCodeValidateModal = (profileIDSelected) => {
    playerCallbackState.clearPlayerCallback();
    dispatch(toggleProfilesModal(true));
    dispatch(enablePinCodeValidateModal(true, profileIDSelected));
    dispatch(validatePinCodeProfileSuccess(true, profileIDSelected));
  };

  const profileOptionsProps = [
    {
      name: t("Sign Out"),
      handleOnClick: handleOnClickLogout,
    },
  ];

  const profileOperationProps = [
    // {
    //   name:  t('Create profile'),
    //   handleOnClick: handleProfileCreateOption
    // },
    {
      name: adminProfile ? t("Manage Profiles") : t("My profile"),
      handleOnClick: adminProfile ? handleManageProfiles : handleMyProfileEdit,
    },
  ];

  return (
    <React.Fragment>
      {navBarBool && (
        <nav
          className={classNames(
            "navigation sticky",
            scrolling ? "black" : "",
            "stickyBool-" + stickyBool,
            "subSection-" + (sortedSubSections?.length > 0)
          )}
          style={{ position: stickyBool ? "sticky" : "fixed" }}
        >
          <FadeIn>
            <ul className="navigation__container">
              <li>
                <div className="navigation__container--menuTrigger">
                  <BurgerMenu
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                    selectedKey={goSection == "/" ? "/home" : goSection}
                    onSectionSelected={onSectionSelected}
                    sections={sortedSections}
                  />
                </div>
              </li>

              <li>
                <NavLink to={PATHS.home} onClick={() => handleOnClickLogo()}>
                  <Imgcomponent
                    className={"logoContainer"}
                    src={ClientLogo}
                    alt={BRAND_INFO.CLIENT_NAME}
                    loading={"eager"}
                  />
                </NavLink>
              </li>

              {sections.length > 0 && (
                <React.Fragment>
                  <DropdownArrow className="navigation__container--downArrow-2"></DropdownArrow>
                  {sortedSections.map((section, index) => {
                    const urlImage = findSectionImg(section?.images);
                    return (
                      <NavBarLink
                        key={`NavLink-${index}`}
                        section={section}
                        index={index}
                        urlImage={urlImage}
                      />
                    );
                  })}
                </React.Fragment>
              )}

              <div className="navigation__container--left"></div>

              <li>
                <NavLink onClick={handleOnClickSearch} to={PATHS.search}>
                  <SearchLogo
                    alt="search"
                    className="navigation__container--searchLogo"
                  />
                </NavLink>
              </li>

              <li>
                {!authenticated ? (
                  <Button
                    className="loginButton"
                    onClick={(e) => handleOnClickLogin(e)}
                  >
                    {<AccountLogo className="accountLogo" alt="Login" />}
                    <span className="textLoginButton">
                      {t("Get In").toLocaleUpperCase()}
                    </span>
                  </Button>
                ) : (
                  <DropdownUserAccount
                    handleOpenMenuProfile={handleOpenMenuProfile}
                    onClickSignOutHandler={handleOnClickLogout}
                    currentAvatar={currentAvatar}
                    profiles={profileCollection}
                    avatars={avatarCollection}
                    openProfileMenu={accountMenuOpen}
                    setOpenProfileMenu={setOpenProfileMenu}
                    handleOnSelectProfile={handleOnSelectProfile}
                    profileOperationProps={profileOperationProps}
                    profileOptionsProps={profileOptionsProps}
                    currentProfileSelected={currentProfileSelected}
                    isAdminProfileSelected={adminProfile}
                    handleEnablePinCodeValidateModal={
                      handleEnablePinCodeValidateModal
                    }
                  />
                )}
              </li>

              {!authenticated && (
                <li>
                  <DropdownCountries
                    selectedCountry={selectedCountry}
                    changeSelectedCountry={changeSelectedCountry}
                    countries={countries?.result}
                  />
                </li>
              )}
            </ul>
          </FadeIn>
        </nav>
      )}
    </React.Fragment>
  );
}

Navbar.propTypes = {
  sections: PropTypes.array,
};

export default Navbar;
