import React from 'react';
import {
  useDispatch
} from 'react-redux';
import useRouter from '../../../customHooks/useRouter';
import * as actions from './actions';
import { changeNavBarBool } from '../../Navbar/actions';
import { fetchCurrentContentPositionByProfile, fetchCurrentContentPositionByProfileClear } from '../../ProfileManager/actions';
import { getRatingImages, toastAdd } from '../../App/actions';
import { useTranslation } from 'react-i18next';
import { TOAST_TEXT } from './constants';
import { TOAST_TYPES } from '../../App/constants';
import { clearIsNextEpisodePlayer } from '../PlayerSection/actions';
import { isPath } from '../../../utils/pathRoutesUtils';

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(accessToken, contentID, mainDiv, redirectUrl, authenticated, PATHS, selectRatingImages) {
  const dispatch = useDispatch();
  const router = useRouter();

  React.useEffect(() => {
    //automatic scroll up
    window.scrollTo(0, 0);
    dispatch(actions.clearStates());
    dispatch(actions.loadWidget(accessToken, contentID));
    dispatch(changeNavBarBool(true))
    dispatch(clearIsNextEpisodePlayer());

    if(selectRatingImages.length === 0) {
      dispatch(getRatingImages(accessToken));
    }

    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    }

    if (authenticated) {
      dispatch(fetchCurrentContentPositionByProfile(accessToken, contentID));
    }

    return () => {
      dispatch(fetchCurrentContentPositionByProfileClear());
    }

  }, [redirectUrl, authenticated]);

  React.useEffect(()=>{
    return ()=>{
      if(!isPath(PATHS.content,router?.history?.location?.pathname)){
        dispatch(actions.clearStates());
      }
    }
  },[router?.match?.path])
}

/**
 * useToastAdd like hook effect
 *
 * @export
 */
export function useToastAdd() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (toast) => {
    const title = TOAST_TEXT['TITLE']
    const message = TOAST_TEXT[toast.code] || TOAST_TEXT[toast] || TOAST_TEXT["DEFAULT"]
    dispatch(toastAdd({
      toastType: TOAST_TYPES.ERROR,
      title: t(title),
      message: t(message),
      error: toast.message ? null : toast,
    }))
  }
}

/**
 * useStoreTimeOnClickBack like hook effect
 *
 * @export
 */
export function useStoreTimeOnClickBack(storeTime, redirect, router, PATHS) {
  React.useEffect(() => {
    if (router.location.key === storeTime?.present?.key[0]) {
      const oneBackPath = router.location.state?.oneBack?.path;
      const storeTime3 = storeTime.past.slice(0, 2)
      redirect.boolGoBack = storeTime3.length > 0 && storeTime3.every(p => isPath(PATHS.content, p.pathname))
      
      //debugger
      if (oneBackPath === "/:slug" || oneBackPath === "" || oneBackPath === PATHS.search) {
        redirect.countGoBack = 0;
        redirect.url = router.location.state.oneBack.url
      }
    }
  }, [storeTime])
}


/**
 * useBookmarkEpisodes like hook effect
 *
 * @export
 */
export function useBookmarkEpisodes(selectContentEpisodes, currentContentPosition) {
  const [contentEpisodesState, setContentEpisodesState] = React.useState(null);
  const [currentSeason, setCurrentSeason] = React.useState(0);

  React.useEffect(() => {

    if (selectContentEpisodes && currentContentPosition) {
      const result = selectContentEpisodes.result.map((episode)=>{
        const episodeBookmark = currentContentPosition.bookmarkEpisodes.find(({contentId})=>episode.id === contentId)
        return {
          ...episode,
          bookmark: episodeBookmark
        }
      })
      
      setContentEpisodesState({
        ...selectContentEpisodes,
        result: result
      })

      const activedSeason = currentContentPosition?.bookmarkEpisodes.length > 0 && currentContentPosition.bookmarkEpisodes[0];
      setCurrentSeason(activedSeason ? selectContentEpisodes.result?.find(e => e.id === activedSeason.contentId)?.season : 0)
      
    }
  }, [selectContentEpisodes, currentContentPosition])


  return { contentEpisodesState, currentSeason }
}



