
export const types = {
  CONTENT_SELECTED: '@@section/CONTENT_SELECTED',
  TOGGLE_MODAL: '@@section/TOGGLE_MODAL',
  TOGGLE_PLAYER: '@@section/TOGGLE_PLAYER',
  WIDGETS_FETCH: '@@section/WIDGETS_FETCH',
  WIDGETS_FETCH_FAILURE: '@@section/WIDGETS_FETCH_FAILURE',
  WIDGETS_FETCH_SUCCESS: '@@section/WIDGETS_FETCH_SUCCESS',
  WIDGETS_LOAD_MORE: '@@section/WIDGETS_LOAD_MORE'
};
