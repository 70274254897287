import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ClientLogo from '../../static/images/logo.svg'
import * as hooks from './hooks';
import { selectPathList } from './selectors';
import './styles.scss';

const NotFound = () => {
  const { t } = useTranslation();
  const PATHS = useSelector(state => selectPathList(state));
  hooks.useDidMount(PATHS);

  return (
    <div className='notFound'>
      <h1 className='notFound_title'>{t('Section not found, try another path.')}</h1>
    </div>)
}

export default NotFound
