import React from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { addFavoriteContentByProfile, deleteFavoriteContentByProfile } from '../../ProfileManager/actions';
import { isDesktop } from 'react-device-detect';

//#region /////// COMPONENTS ///////
import Footer from '../../../components/Footer';
import HStack from '../../../components/HStack';
//#endregion

import * as actions from './actions';
import * as hooks from './hooks';
import * as selectors from './selectors';
import useRouter from '../../../customHooks/useRouter';
import { checkifHaveContentAccess } from '../../../utils/validationUserUtils';
import { selectCurrentContentPosition } from '../../ProfileManager/selectors';
import LoadingSpinner from '../../../components/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.scss';
import { BRAND_INFO } from '../../../constants/utilTypes';
import Seo from '../../../components/Seo';
import LOGO_IMAGE from '../../../static/images/logo.png';
import { HandleError } from '../../../components/HandleError';
import { useTranslation } from 'react-i18next';
import { CONTENT_TYPES } from '../../../constants/contentTypes';
import { convertPath, getPathNameByContentType } from '../../../utils/pathRoutesUtils';
import { selectPathCountry } from '../../AppSectionRouter/selectors';
import { selectError } from '../ContentOverViewSection/selectors';
import { clearStates } from '../ContentOverViewSection/actions';
import { notificationModalCreate } from '../../App/actions';

function HomeSection({ accessToken, section }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [showFooter, setShowFooter] = React.useState(false);
  const { t } = useTranslation();

  //#region /// SELECTORS ///
  const widgetCollection = useSelector(state => selectors.selectWidgetCollection(state));
  const appSettings = useSelector(state => selectors.selectAppSettings(state));
  const networks = useSelector(state => selectors.selectNetworks(state));
  const channels = useSelector(state => selectors.selectChannels(state))
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const favorites = useSelector(state => selectors.selectFavoritesContents(state));
  const widgetCollectionPaginate = useSelector(state => selectors.selectWidgetCollectionPaginate(state));
  const selectIsPlayerEpgSection = useSelector(state => selectors.selectIsPlayerEpgSection(state));
  const page = useSelector(state => selectors.selectPage(state));
  const selectSectionError = useSelector(state => selectors.selectSectionError(state));
  const PATHS = useSelector(state => selectors.selectPathList(state));
  const pathCountry = useSelector(state => selectPathCountry(state));
  const selectErrorState = useSelector(state => selectError(state));
  //#endregion


  const handleOnSelectContent = async (content) => {
    dispatch(clearStates());

    if(content.live || content.bookmark || content.clip){
      if (!isDesktop) {
        alert('Visualiza este contenido descargando la app');
      } else {
        const { start, end } = content?.flights[0]?.flights[0];
        const now = new Date().getTime();
        const startTime = new Date(start).getTime();
        const endTime = new Date(end).getTime();

        // LIVE NOW!
        if ( (startTime < now && endTime > now) || content.contentType === CONTENT_TYPES.BROADCAST ) {
          // CHECK ACCESS
          if (checkifHaveContentAccess(authenticated, content)) {
            // GO TO PLAYER
            router.push(convertPath(PATHS.player, content.id));
            return false;
          }
  
          // REDIRECT TO LOGIN
          const { origin } = window.location;
          const returnURL = origin + convertPath(PATHS.player, content.id);
          router.push(PATHS.login, { returnURL });
          return false;
        }

        // NEXT SPORT EVENTS
        if ( content?.contentType === CONTENT_TYPES.SPORT ) {
          const subPath = getPathNameByContentType(content.contentType,PATHS);
          router.push(convertPath(subPath,content.id));
        }
      }
    } else {
      // NEXT EVENTS
        const subPath = getPathNameByContentType(content.contentType,PATHS);
        router.push(convertPath(subPath,content.id));
        setShowFooter(false);
    }
  }

  const onClickPlayerButton = (content, isBannerHero = false) => {
    if (!isDesktop) {
      alert('Visualiza este contenido descargando la app');
      return;
    }

    if(checkifHaveContentAccess(authenticated, content)) {
      dispatch(actions.getCurrentEpisodeBySerie(content.id, router, PATHS.player));
    } else {
      const { origin } = window.location;
      const returnPath = content?.contentType !== CONTENT_TYPES.SERIE ? PATHS.player : PATHS.content;
      const returnURL = origin + convertPath(returnPath, content.id);
      router.push(PATHS.login, { returnURL });
    }
  }

  const onClickMyListButton = (contentId) => {   
    favorites.some((idR)=>idR===contentId) ? dispatch(deleteFavoriteContentByProfile(contentId)) : dispatch(addFavoriteContentByProfile(contentId));
  }

  //#region ///// HOOK EFFECTS /////
  hooks.useDidMount(accessToken, section);
  //hooks.useSectionError(selectSectionError);
  //#endregion

  const redirectHandler = (content, redirectType, target = '_self') => {
    return (redirectType === 'externalUrl') ? window.open(content, target)
      : router.push(`${pathCountry}${content}`);
  }

  const showErrorModal = (error) => {
    if ( error.code === "CS-011" ) {
      dispatch(notificationModalCreate({
        title: t("¡Sorry!"),
        message: t("You do not have the necessary permissions to view the selected content."),
        error,
        logoImg: true,
        exitButton: true,
      }));
    }
  }

  React.useEffect(()=>{
    if (selectErrorState?.code){ 
      dispatch(actions.loadSectionError(selectErrorState));
    }
  },[selectErrorState]);

  React.useEffect(()=>{
    if (selectSectionError?.code){ 
      showErrorModal(selectSectionError);
      dispatch(actions.clearSectionError());
      dispatch(clearStates());
    }
  },[selectSectionError]);


  if(selectSectionError?.code === "SE-001") {
    return <HandleError error={{error: t('There is no home section available. Check with the system administrator.')}} navBar={false} />
  }

  return (
    <main onLoad={() => setShowFooter(false)} className="main-content homeSection">
      { section && <Seo title={`${BRAND_INFO.CLIENT_NAME} - ${section.name}`} contentTitle={`${BRAND_INFO.CLIENT_NAME}`} description={BRAND_INFO.CLIENT_DESCRIPTION} image={LOGO_IMAGE} /> }

      <div className="container-wide hstack-container">
        <InfiniteScroll
            dataLength={widgetCollectionPaginate?.length}
            next={() => dispatch(actions.loadMoreWidgets())}
            hasMore={widgetCollectionPaginate?.length < widgetCollection?.length}
            loader={
              <div className="homeSection__results-loader">
                <LoadingSpinner className="homeSection__results-loader-spinner" />
              </div>
            }
          >
          <HStack
            accessToken={accessToken}
            handleOnSelectContent={handleOnSelectContent}
            onClickPlayerButton={onClickPlayerButton}
            onClickMyListButton={onClickMyListButton}
            favorites={favorites}
            authenticated={authenticated}
            sectionID={section.id}
            widgetCollection={widgetCollectionPaginate}
            appSettings={appSettings}
            networks={networks}
            channels={channels}
            redirectHandler={redirectHandler}
          />
        </InfiniteScroll>
      </div>
      {!selectIsPlayerEpgSection && showFooter && <Footer />}
    </main>
  );
}

HomeSection.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    isHome: PropTypes.bool,
    isPublic: PropTypes.bool,
    name: PropTypes.string.isRequired,
    redirect: PropTypes.object,
    seo: PropTypes.object
  })
};

export default HomeSection;
