import React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import useWidgetTranslation from '../../../customHooks/useWidgetTranslation';
import {
  Player,
  WithTBXPlayerScripts,
  EPGGrid
} from '@tbx/experience-widgets-lib';
import { isDesktop } from 'react-device-detect';
import _ from 'lodash';

import * as hooks from './hooks';
import * as selectors from './selectors';
import classNames from '../../../utils/classnameUtils';
import useRouter from '../../../customHooks/useRouter';
import { checkifHaveContentAccess } from '../../../utils/validationUserUtils';
import './styles.scss';
import { CONTENT_TYPES } from '../../../constants/contentTypes';
import { LIVE_PLAYER, TITLES } from './constants';
import { ERROR_CODE_FORMAT } from '../../../constants/errorCode';
import NotAvailable from './NotAvailable';
import { usePlayerCallbackState } from '../../App/playerCallbackContextProvider.js';
import { SectionErrorBoundary } from '../../../HOCs/WithErrorBoundary';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { ENVIROMENTS } from '../../../utils/envUtils';
import { BRAND_INFO } from '../../../constants/utilTypes';
import Seo from '../../../components/Seo';
import { useGoogleAnalyticEvents } from '../../../customHooks/useGoogleAnalyticEvents';
import { setGoogleAnalyticEvents } from '../../../utils/googleAnalyticUtils';
import { GA_SECTION_NAMES } from '../../../constants/googleAnalyticEventTypes';
import { getEpgChannelName } from '../../../utils/ContentUtils';
import { PATH_ERROR } from '../../AppSectionRouter/constants';
import { clearErrors } from './actions';

function PlayerEpgSection({ content, isLoading, unityToken, playerScriptsLoaded, playerScriptsError }) {
  const router = useRouter();
  const dispatch = useDispatch();
  const playerCallbackState = usePlayerCallbackState();
  const { t } = useTranslation();

  const translationTexts = {
    ...useWidgetTranslation(TITLES),
    live_player: t(LIVE_PLAYER)
  };
  const playerID = _.uniqueId('playerEPG-');
  const PLAYEREPG_PLAYER_REF = React.useRef();
  const PLAYEREPG_EPG_REF = React.useRef();
  const daysBefore = 2;
  const daysAfter = 4;


  //#region /////// SELECTORS ///////
  const { appId } = useSelector(state => selectors.selectAppSettings(state));
  const selectedContent = useSelector(state => selectors.selectContent(state));
  const selectedEpgList = useSelector(state => selectors.selectEpgList(state));
  const playerIsFetching = useSelector(state => selectors.selectIsFetching(state));
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const selectError = useSelector(state => selectors.selectError(state));
  const useLoadWidgetError = hooks.useLoadWidgetError(playerScriptsError,selectError);
  const togglePorfilesModal = useSelector(state => selectors.selectToggleProfilesModal(state));
  const PATHS = useSelector(state => selectors.selectPathList(state))
  //#endregion

  //#region ///// HOOK EFFECTS /////
  hooks.useDidMount(content, unityToken, selectError, daysBefore, daysAfter);
  hooks.usePlayerCallbackConfig(playerCallbackState, PLAYEREPG_PLAYER_REF, PLAYEREPG_EPG_REF);
  const [setContent, contentState] = hooks.useSetContent();
  //#endregion


  React.useEffect(() => {
    const { CP006 } = ERROR_CODE_FORMAT;

    switch (selectError?.code) {
      case CP006:
        break;
      default:
        break;
    }

  }, [selectError]);

  const onBack = React.useCallback(async () => {
    if (!PLAYEREPG_PLAYER_REF?.current) return;
    PLAYEREPG_PLAYER_REF.current.style.visibility = 'hidden';
    PLAYEREPG_PLAYER_REF.current.style.opacity = '0';
    if (!!router?.location?.state?.oneBack?.url) {
      return router.push(router?.location?.state?.oneBack?.url);
    } else {
      return router.push(PATHS.home);
    }
  }, [router]);

  const catchError = (error) => {
    return router.push(PATH_ERROR, { error, navBar: true });
  };


  const onClickContentSelected = async ({ epgItemCollection, selectedProgramItemR }) => {
    selectError?.code && dispatch(clearErrors());
    
    if (playerCallbackState?.state?.contentType !== CONTENT_TYPES.BROADCAST) {
      playerCallbackState.clearPlayerCallback();
    }
    if (epgItemCollection.hasCatchUp && selectedProgramItemR?.startTime && selectedProgramItemR?.endTime) {
      setContent({
        ...epgItemCollection,
        startTime: selectedProgramItemR.startTime,
        endTime: selectedProgramItemR.endTime
      });
    } else {
      setContent(epgItemCollection);
    }
  }

  if (!selectedContent || isLoading) {
    return (<div className='playerEpgSectionContainer_loading'>
      <LoadingSpinner
        className="playerEpgSectionContainer_spineer"
      />
    </div>)
  }

  const handleChangeSelectItem = ({selectedEpgId, selectedProgramItem}) => {
    const title = selectedProgramItem && `${BRAND_INFO.CLIENT_NAME} - ${t(GA_SECTION_NAMES.EPG)} - ${getEpgChannelName(selectedEpgId, selectedEpgList)}`;
    title && setGoogleAnalyticEvents({title});
  }

  return (
    <div className="playerEpgSectionContainer" >
      {selectedContent && <Seo title={`${BRAND_INFO.CLIENT_NAME} - ${t(GA_SECTION_NAMES.EPG)} - ${selectedContent?.title}`}  /> }
      <div className={classNames('playerEpgSectionPlayer',
        !playerIsFetching && playerScriptsLoaded && selectedContent && !selectError?.code && !selectError?.message && isDesktop && checkifHaveContentAccess(authenticated, selectedContent) &&
        'playerEpgSectionPlayer_load'
      )} ref={PLAYEREPG_PLAYER_REF}>
        {
          selectedContent && !selectError?.code && !selectError?.message && isDesktop && checkifHaveContentAccess(authenticated, selectedContent)
            ?
            (playerScriptsLoaded) && !togglePorfilesModal && (playerCallbackState.state?.contentType === CONTENT_TYPES.BROADCAST || !playerCallbackState?.state) &&
            (<Player
              content={selectedContent}
              changeContent={contentState}
              onClickBackButton={() => onBack()}
              playerHandleError={useLoadWidgetError}
              catchError={catchError}
              translationTexts={translationTexts}
              fullWindow={false}
              eventListenerAccion={false}
              playerCallback={playerCallbackState}
              LoadingComponent={({className,...props})=><LoadingSpinner className={"playerEpgSectionContainer_spineer_player "+className} {...props}/>}
              isExitButton={false}
              options={{
                ...(appId ? { appId } : {}),
                playerID: playerID,
                jwtToken: `${unityToken['auth_type']} ${unityToken['access_token']}`,
                fill: true,
                responsive: true,
                autoplay: true,
                pictureInPictureToggle: true,
                environment: ENVIROMENTS
              }}
            />)
            :
            selectedContent && <NotAvailable
              translationTexts={translationTexts}
              checkifHaveContentAcces={checkifHaveContentAccess(authenticated, selectedContent)}
              isDesktop={isDesktop}
              selectError={selectError}
              pathLogin={PATHS.login}
            />
        }

      </div>
      <div
        className='playerEpgSectionEpg'
        ref={PLAYEREPG_EPG_REF}
      >
        {selectedEpgList?.length > 0 && <EPGGrid
          content={selectedEpgList}
          translationTexts={translationTexts}
          daysAfter={daysAfter}
          daysBefore={daysBefore}
          daysMaxRange={15}
          displayedItemsNum={3}
          displayedItemHeightInPx={150}
          imageMode={'THUMB'}
          isLoading={isLoading}
          locale={'es'}
          onChangeDayCallback={(dayItem) => console.log('>> Selected dayItem:', dayItem)}
          onSelectedProgramItemCallback={(programItem) => handleChangeSelectItem(programItem)}
          title={'Title'}
          unityToken={unityToken}
          onClickContentSelected={onClickContentSelected}
          showPlayIcon={false}
          enableEpgDropdown={false}
        />}
      </div>
    </div>
  );
}


export default SectionErrorBoundary(WithTBXPlayerScripts(PlayerEpgSection));
