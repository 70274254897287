import React from "react";
import { useTranslation } from "react-i18next";

import useWidgetTranslation from "../../customHooks/useWidgetTranslation";
import logoImg from "../../static/images/logo.png";
import googleStoreImg from "../../static/images/google_store.png";
import appStoreImg from "../../static/images/app_store.png";
import "./styles.scss";

//#region /////// TBX WIDGETS ///////
import { Imgcomponent } from "@tbx/experience-widgets-lib";
//#endregion

function DownloadApp() {
  const [hrefAppStore, hrefgooglePlay] = ["#", "#"];
  const { t } = useTranslation();

  const translation = useWidgetTranslation([
    "Premium channels and access to thousands of hours of On Demand content.",
    "Download",
    "on your devices",
    "Brand",
    "Take all our content with you and enjoy it through our application for Android and Apple devices.",
  ]);

  return (
    <React.Fragment>
      <div className={"tbxDownloadApp"}>
        <Imgcomponent
          className={"tbxDownloadApp_logoImg"}
          src={logoImg}
          loading={"eager"}
        />
        <div className={"tbxDownloadApp_title"}>
          { t('DownloadApp Message') }
        </div>

        <div className="tbxDownloadApp_card">
          <div className={"tbxDownloadApp_card_description"}>
          { t('DownloadApp Disclaimer') }
          </div>
          <div
            className="tbxDownloadApp_card_appStoreList"
            style={{
              display: "none",
            }}
          >
            <a target="_blank" href={hrefAppStore}>
              <Imgcomponent
                className="tbxDownloadApp_card_appStoreList_app"
                src={appStoreImg}
                loading={"eager"}
              />
            </a>
            <a target="_blank" href={hrefgooglePlay}>
              <Imgcomponent
                className="tbxDownloadApp_card_appStoreList_google"
                src={googleStoreImg}
                loading={"eager"}
              />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DownloadApp;
