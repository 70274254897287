import { types } from './constants';

const INITIAL_STATE = {
  navBarBool:true,
  sticky: false,
  userInput: '',
  accountMenuOpen: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.NAVBAR_SEARCHINPUT_CHANGE:
      return {
        ...state,
        userInput: action.text
      };
    case types.NAVBAR_STICKY_CHANGE:
      return {
        ...state,
        sticky: action.bool
      };
    case types.NAVBAR_NAVBARBOOL_CHANGE:
        return {
          ...state,
          navBarBool: action.bool
        }; 
    case types.ACCOUNT_MENU_STATUS_CHANGE:
          return {
            ...state,
            accountMenuOpen: action.status
          }; 
    default:
      return state;
  }
};

export default reducer;
