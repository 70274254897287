
import {
  isTokenExpired
} from '../../utils/jwtUtils.js';
import { refreshUnityToken } from '../../containers/App/actions';

export function createJWTRefreshMiddleware() {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      if(action.accessToken) {
        const jwtToken = action.accessToken['access_token'];

        if(jwtToken && isTokenExpired(jwtToken)) {
          return dispatch(refreshUnityToken(action));
        }
      }

      return next(action);
    }
  }
}

export default createJWTRefreshMiddleware;