import { createSelector } from 'reselect';

const getAppReducer = (state) => state.containers.app;
const getReducer = (state) => state.containers.searchSection;
const getSessionReducer = (state) => state.session;
const getProfileManager = (state) => state.containers.profileManager;
const getAppSectionsReducer = (state) => state.containers.appSections;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken
);

export const selectEmptyResult = createSelector(
  getReducer,
  (reducer) => reducer.emptyResult
);

export const selectRecommended = createSelector(
  getReducer,
  (reducer) => reducer.recommended
);

export const selectIsSearching = createSelector(
  getReducer,
  (reducer) => reducer.isSearching
);

export const selectPage = createSelector(
  getReducer,
  (reducer) => reducer.page
);

export const selectSearchResult = createSelector(
  getReducer,
  (reducer) => reducer.searchResult
);

export const selectUserInput = createSelector(
  getReducer,
  (reducer) => reducer.userInput
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings
);

export const selectNetworks = createSelector(
  getAppReducer,
  (reducer) => reducer.networks
);

export const selectChannels = createSelector(
  getAppReducer,
  (reducer) => reducer.channels
);

export const selectProfileCollection = createSelector(
  getProfileManager,
  (reducer) => reducer.profileCollection
);

export const selectProfileSelected = createSelector(
  getProfileManager,
  (reducer) => reducer.profileSelected
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList
);

export const selectAuthenticated = createSelector(
  getSessionReducer,
  (reducer) => reducer.authenticated
);