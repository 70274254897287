import { CONTENT_TYPES, SHOW_ITEMS_BY_DEFAULT } from "../constants/contentTypes";
import { BRAND_INFO } from "../constants/utilTypes";

/**
 * Validate if a content has url redirect
 * @param {*} content 
 * 
 * @return {Boolean} 
 */
 export const validateUrlRedirect = (content) => {
  return content?.currentContent?.entitlementTypes?.includes('redirect');
}


/**
 * Items to display in carousel depending on configuration
 * @param {*} content 
 * 
 * @return {Boolean} 
 */
export const contentItemsToShowInCarousel = (maxItems, contentListDisplayItems) =>  {
    let countItem = SHOW_ITEMS_BY_DEFAULT.CAROUSEL;
    if (!!contentListDisplayItems && contentListDisplayItems > 0) {
      countItem = contentListDisplayItems;
    } else if (maxItems > 0) {
      countItem = maxItems;
    }
    
    return countItem;
}
  

/**
 * Validate if GoBackOnCompletion action if enable depending the contentType
 * @param {*} content 
 * @param {*} lastEpisodeId 
 * 
 * @return {Boolean} 
 */
 export const validateGoBackOnCompletion = (content, lastEpisodeId) =>  {
   
    const { contentType } = content || null;

    if(contentType === CONTENT_TYPES.MOVIE || content.clip) {
      return true;
    }
      
    if(contentType === CONTENT_TYPES.EPISODE) {
      return content.id === lastEpisodeId;
    }
        
      return false;
}

/**
 * get the last episode of the serie
 * @param {*} content 
 * 
 * @return {Boolean} 
 */
 export const getLastEpisodeInSerie = (content) =>  {
 
    const activedEpsiodes = [...content?.result];
    const lastSeason = activedEpsiodes?.slice(-1);
    const lastEpisodeId = lastSeason[0].id;
    
    return lastEpisodeId; 
}

/**
 * Sort episodes of a serie - ASC or DESC. Besides return lastEpisode
 * @param {*} content 
 * @param {*} mode 
 * 
 * @return {Object} 
 */
 export const sortEpisodes = (content, mode='ASC') =>  {

    if(mode === 'DESC') {
      content?.result?.sort((a, b) =>  { return b?.season -a?.season }); 
    }
    else {
      content?.result?.sort((a, b) =>  { return a?.season - b?.season }); 
    }

    const lastEpisode = getLastEpisodeInSerie(content);
    
    return  { content, lastEpisode };
    
 }


 /**
 * Get Epg Channel name
 * @param {*} epgId 
 * @param {*} selectedEpgList 
 * 
 * @return {Object} 
 */
  export const getEpgChannelName = (epgId, selectedEpgList) =>  {

   return selectedEpgList && selectedEpgList.find(epg => epg.epgId === epgId)?.title;
    
 }
 
 /**
 * Get Seo Title In PlayerSection
 * @param {*} selectedContent 
 * @return {String} 
 */
  export const getSeoTitleInPlayerSection = (selectedContent, t) =>  {
    const titleWithFormat = (selectedContent?.contentType === CONTENT_TYPES.EPISODE) ? 
                            `${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title}:${t('Season')} ${selectedContent.season} - ${t('Episode')} ${selectedContent.episode}`
                            :
                            `${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title}`
    return titleWithFormat;
     
  }
