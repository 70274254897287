import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch
} from 'react-redux';
import useRouter from '../../../customHooks/useRouter';
import { changeNavBarBool, changeStickyBool } from '../../Navbar/actions';
import { fetchCurrentContentPositionByProfile } from '../../ProfileManager/actions';
import * as actions from './actions';
import { TOAST_TEXT } from './constants';
import { TOAST_TYPES } from '../../App/constants';
import { toastAdd } from '../../App/actions';
import { clearIsNextEpisodePlayer } from '../PlayerSection/actions';

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(accessToken, contentID, mainDiv, redirectUrl, authenticated) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    //automatic scroll up
    window.scrollTo(0, 0);
    dispatch(actions.clearStates());
    dispatch(actions.loadWidget(accessToken, contentID));
    dispatch(changeStickyBool(false))
    dispatch(changeNavBarBool(true))
    dispatch(clearIsNextEpisodePlayer());

    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    }

    if (authenticated) {
      dispatch(fetchCurrentContentPositionByProfile(accessToken, contentID));
    }

    return ()=>{
      dispatch(actions.clearStates());
    }

  }, [redirectUrl, authenticated]);
}

/**
 * useToastAdd like hook effect
 *
 * @export
 */
 export function useToastAdd() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (error) => {
    const message = t(TOAST_TEXT[error.code]) || t(TOAST_TEXT["DEFAULT"])
    const title = t(TOAST_TEXT['TITLE'])
      dispatch(toastAdd({
        toastType: TOAST_TYPES.ERROR,
        title: title,
        message: message,
        error: error,
      }))
  }
}

/**
 * useStoreTimeOnClickBack like hook effect
 *
 * @export
 */
export function useStoreTimeOnClickBack(storeTime, redirect, router, PATHS) {
  React.useEffect(() => {
    if (router.location.key === storeTime?.present?.key[0]) {
      const oneBackPath = router.location.state?.oneBack?.path;
      const storeTime3 = storeTime.past.slice(0, 2)
      redirect.boolGoBack = storeTime3.length > 0 && storeTime3.every(p => p.pathname.includes('/content/'))

      if (oneBackPath === "/:slug" || oneBackPath === "" || oneBackPath === PATHS.search) {
        redirect.countGoBack = 0;
        redirect.url = router.location.state.oneBack.url
      }
    }
  }, [storeTime])
}



