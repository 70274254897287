import { createSelector } from 'reselect';

const getAppReducer = (state) => state.containers.app;
const getSessionReducer = (state) => state.session;
const getReducer = (state) => state.containers.playerSection;
const getContentOverViewReducer = (state) => state.containers.contentOverViewSection;
const getAppSectionsReducer = (state) => state.containers.appSections;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings
);

export const selectIsFetching = createSelector(
  getReducer,
  (reducer) => reducer.isFetching
);

export const selectWidgetCollection = createSelector(
  getReducer,
  (reducer) => reducer.widgetCollection
);

export const selectSelectedContent = createSelector(
  getReducer,
  (reducer) => reducer.selectedContent
);

export const selectToggleModal = createSelector(
  getReducer,
  (reducer) => reducer.toggleModal
);

export const selectError = createSelector(
  getReducer,
  (reducer) => reducer.error
);

export const selectAuthenticated = createSelector(
  getSessionReducer,
  (reducer) => reducer.authenticated
);

export const selectLastEpisodeIdInSerie = createSelector(
  getContentOverViewReducer,
  (reducer) => reducer.selectLastEpisodeID
);

export const selectIsNextEpisode = createSelector(
  getReducer,
  (reducer) => reducer.isNextEpisode
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList
);
