import { createSelector } from 'reselect';

const getReducer = (state) => state.containers.activateCodeSection;
const getSessionReducer = (state) => state.session;
const getAppSectionsReducer = (state) => state.containers.appSections;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken
);

export const selectActivationCodeStatus = createSelector(
  getReducer,
  (reducer) => reducer.activationCodeStatus
);

export const selectIsFetching = createSelector(
  getReducer,
  (reducer) => reducer.isFetching
);


export const selectShowMessage = createSelector(
  getReducer,
  (reducer) => reducer.showMessage
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList
);