import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import logoImg from '../../static/images/logo.png';
import LoadingSpinner from '../LoadingSpinner';
import './styles.scss';

function LandingLoader() {
  
  return (
    <div className="landing-loader">
      <Container className="container" fluid>
        {<div className="container-item-image">
          {/*<Image src={logoImg} />*/}
        </div>}
        <div className="container-item-spinner">
          <LoadingSpinner
            className="landing-loader__loadingSpinner"
          />
        </div>
      </Container>
    </div>
  );
}

export default LandingLoader;
