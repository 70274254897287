import { createSelector } from 'reselect';

const getReducer = (state) => state.containers.profileManager;
const getSessionReducer = (state) => state.session;
const getAppReducer = (state) => state.containers.app;
const getAppSectionsReducer = (state) => state.containers.appSections;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken
);

export const selectAvatarCollection = createSelector(
  getReducer,
  (reducer) => reducer.avatarCollection
);

export const selectError = createSelector(
  getReducer,
  (reducer) => {
    return reducer.error;
  }
);

export const selectIsFetching = createSelector(
  getReducer,
  (reducer) => reducer.isFetching
);

export const selectProfileCollection = createSelector(
  getReducer,
  (reducer) => reducer.profileCollection
);

export const selectCurrentProfile = createSelector(
  getReducer,
  (reducer) => reducer.profileSelected
);


export const selectCurrentContentPosition = createSelector(
  getReducer,
  (reducer) => reducer.currentContentPosition
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings
);

export const selectProfileSelected = createSelector(
  getReducer,
  (reducer) => reducer.profileSelected
);

export const selectProfileFormData = createSelector(
  getReducer,
  (reducer) => reducer.profileDataFetch
);

export const selectProfileFormStatus = createSelector(
  getReducer,
  (reducer) => reducer.profileFormStatus
);

export const selectEnableProfileOperations = createSelector(
  getReducer,
  (reducer) => reducer.enableProfileOperations
);

export const selectFormModeProfile = createSelector(
  getReducer,
  (reducer) => reducer.isAddFormMode
);

export const selectEnablePinCodeValidationModal = createSelector(
  getReducer,
  (reducer) => reducer.enablePinCodeValidationModal
);

export const selectProfileIDSelectedForValidation = createSelector(
  getReducer,
  (reducer) => reducer.profileIDSelectedForValidation
);


export const selectValidatePinCodeStatus = createSelector(
  getReducer,
  (reducer) => reducer.validatePinCodeStatus
);

export const selectFormDisabledStatus = createSelector(
  getReducer,
  (reducer) => reducer.formDisabled
);

export const selectFireBaseToken = createSelector(
  getAppReducer,
  (reducer) => reducer.firebaseToken
);

export const selectFavorires = createSelector(
  getAppReducer,
  (reducer) => reducer.favorites
);


export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList
);
