import React from 'react';
import {
    useDispatch
} from 'react-redux';
import { changeNavBarBool } from '../../containers/Navbar/actions';


export function useChangeNavBarBool(bool){
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(changeNavBarBool(bool))
    }, [bool]);
}
  