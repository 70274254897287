import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BannerHero,
  ContentListCarousel,
  ContentGrid
} from '@tbx/experience-widgets-lib';

import UnityComponentProvider from '../../HOCs/UnityComponentProvider';
import useWidgetTranslation from '../../customHooks/useWidgetTranslation';
import { FILTER_ORDER_TYPES, TITLES } from './constants';
import useRouter from '../../customHooks/useRouter';

import PlayerEpgSection from '../../containers/sections/PlayerEpgSection';
import { useDispatch } from 'react-redux';
import { useCatchError } from './hooks';
import EpgComponent from '../EpgComponent';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from '../LoadingSpinner';

const TBX_WIDGETS = new Map([
  ['banner', BannerHero],
  ['carousel', ContentListCarousel],
  ['gallery_wall', ContentGrid],
  ['epg_grid', (props)=>props.player ? <PlayerEpgSection {...props}/> : <EpgComponent  {...props}/>]
]);


/**
 * Hstack Renders stack of widgets in a section.
 *
 * @param {*} {
 *   accessToken,
 *   handleOnSelectContent,
 *   sectionID,
 *   widgetCollection
 * }
 * @returns
 */
function HStack({
  accessToken,
  handleOnSelectContent,
  onClickPlayerButton,
  onClickMyListButton,
  favorites,
  authenticated,
  sectionID,
  widgetCollection,
  appSettings,
  networks,
  channels,
  redirectHandler
}) {
  const router = useRouter()
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translationTexts = useWidgetTranslation(TITLES);

  const catchErrorHanlder = useCatchError();

  const handleSeeMore = sectionRedirect => router.push(sectionRedirect);

  let orderTypeArray = [...FILTER_ORDER_TYPES];
  orderTypeArray.map(f => f.name=t(f.name));


  return (
    <React.Fragment>
      {widgetCollection
        .map(widget => {
          const WidgetComponent = TBX_WIDGETS.get(widget.componentType);
          if (!WidgetComponent) {
            return null;
          }

          const _renderWidget = (widgetProps) => {

            const { loadMoreDataInComponent, contentCollection, page } = widgetProps;
    
              if(widget.componentType === 'gallery_wall') {
                return (
                  <InfiniteScroll
                    dataLength={contentCollection.length}
                    next={()=>loadMoreDataInComponent()}
                    hasMore={!!page.nextPage}
                    loader={
                      <div className="sectionContainer__results-loader">
                        <LoadingSpinner className="sectionContainer__results-loader-spinner" />
                      </div>
                }>
                  <WidgetComponent
                  {...widgetProps}
                  disableAnimation
                  translationTexts={translationTexts}
                  selectedContentHandler={handleOnSelectContent}
                  onClickPlayerButton={onClickPlayerButton}
                  onClickMyListButton={onClickMyListButton}
                  favorites={favorites}
                  authenticated={authenticated}
                  accessToken={accessToken}
                  redirectHandler={redirectHandler}
                  isLoading={false}
                  handleSeeMore={handleSeeMore}
                  orderTypeArray={orderTypeArray}
               
                />
                </InfiniteScroll>)

              } else {
                return (<WidgetComponent
                  {...widgetProps}
                  disableAnimation
                  translationTexts={translationTexts}
                  selectedContentHandler={handleOnSelectContent}
                  onClickPlayerButton={onClickPlayerButton}
                  onClickMyListButton={onClickMyListButton}
                  favorites={favorites}
                  authenticated={authenticated}
                  accessToken={accessToken}
                  redirectHandler={redirectHandler}
                  isLoading={false}
                  handleSeeMore={handleSeeMore}
                />)
              }
                
            }

          return (
            <UnityComponentProvider
              key={`component-${widget.id}`}
              tokenData={accessToken}
              widgetData={widget}
              appSettings={appSettings}
              networks={networks}
              channels={channels}
              dispatchRedux={dispatch}
              favorites={favorites}
              authenticated={authenticated}
              renderWidget={_renderWidget}
              customEmptyComponent={null}
              customLoadingComponent={(props)=> props && <WidgetComponent {...props} />}
              catchError={catchErrorHanlder(widget)}
            />
          );
        })
      }
    </React.Fragment>
  );
}

HStack.propTypes = {
  accessToken: PropTypes.shape({
    access_token: PropTypes.string,
    auth_type: PropTypes.string,
    expires_in: PropTypes.string
  }).isRequired,
  handleOnSelectContent: PropTypes.func,
  redirectHandler: PropTypes.func,
  sectionID: PropTypes.string,
  widgetCollection: PropTypes.arrayOf(PropTypes.shape({
    componentParameters: PropTypes.object,
    componentType: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    life: PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    }),
    order: PropTypes.number
  })),
  appSettings: PropTypes.object,
  networks: PropTypes.array,
  channels: PropTypes.array
};

export default React.memo(HStack);