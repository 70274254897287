import { types } from './constants';

//TODO: State con datos mockeados para POC, se debe pensar en un endpoint que devuelva

const INITIAL_STATE = {
  error: {
    code: null,
    details: null,
    message: null
  },
  isFetching: true,
  isPlayerEpgSection: false,
  content: null,
  epgDataByIds: [],
  epgList: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CONTENT_SELECTED:
      return {
        ...state,
        content: action.content,
        epgList: action.epgList
      };
    case types.WIDGET_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error
        },
        isFetching: true
      };
    case types.EPG_DATA_BY_IDS_SUCCESS:
      return {
        ...state,
        epgDataByIds: action.result
      };
    case types.WIDGET_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...action.error
        },
        isFetching: false
      };
    case types.EPG_DATA_BY_IDS_ERROR:
      return {
        ...state,
        error: {
          ...action.error
        },
        isFetching: false
      };
    case types.WIDGET_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        content: action.content,
        epgList: action.epgList
      };
    case types.IS_PLAYEREPG_SECTION:
    return {
      ...state,
      isPlayerEpgSection: action.isPlayerEpgSection
    };
    case types.WIDGET_CHANGE:
      return {
        ...state,
        error: INITIAL_STATE.error,
        content: action.content
      };
    case types.CLEAR_ERRORS:
        return {
          ...state,
          error: INITIAL_STATE.error
        };
    case types.CLEAR_STATES:
      return {
        ...INITIAL_STATE
      };
    default:
      return state;
  }
};

export default reducer;
