import COUNTRIES from "../constants/countries";
const ENV = window.__TBX_ENV__;

function getEnvConfig(currentENV) {

  if (!currentENV.TBX_CLOUDPASS_API || !currentENV.TBX_UNITY_API) {
    if (!currentENV.TBX_CLIENT_KEY || !currentENV.TBX_CLIENTS_LIST) {
      console.error('>>>>>> Invalid __TBX_ENV__ definition! you must define TBX_CLIENT_KEY or TBX_CLIENTS_LIST');
    return null;
    }
    console.error('>>>>>> Invalid __TBX_ENV__ definition! you must define API vars TBX_CLOUDPASS_API and TBX_UNITY_API');
    return null;
  }

  const IDP_CLIENT = currentENV.TBX_IDP_CLIENT || null;
  const IDP_SHORTCODE = currentENV.TBX_IDP_SHORTCODE || null;
  const useCloudhubConfig = !IDP_CLIENT || !IDP_SHORTCODE;

  let tbxClientsJSON = null;
  let tbxClientKey = currentENV.TBX_CLIENT_KEY;



  const { pathname } = window.location

  const pathUrl = pathname.slice(1,pathname.length).split('/')[0]?.toUpperCase();

  const tbxPathCountry = COUNTRIES.find(({ code }) => code === pathUrl)?.code;

  if (typeof (currentENV.TBX_CLIENTS_LIST) === 'string') {
    try {
     
        tbxClientsJSON = JSON.parse(currentENV.TBX_CLIENTS_LIST );

        const pathClientDefault = tbxClientsJSON.find((client)=>client?.default);
        
        const pathClient = tbxClientsJSON.find((client)=>{
          return !!pathUrl && client?.country === pathUrl
        })
        let tbxClientKeyResult = pathClient || (!!tbxPathCountry && {...pathClientDefault, country:tbxPathCountry}) 

        if (tbxClientKeyResult) tbxClientsJSON = [(tbxClientKeyResult)];

    } catch (e) {
     console.error('[ENV Config] >>> Invalid TBX_CLIENTS_LIST value: ', e);
    }
  }
  return {
    client: {
      SUB: tbxClientKey,
      CONFIG_URI: currentENV.TBX_EXPERIENCE_CONFIG,
    },
    cloudpass: {
      API_URL: currentENV.TBX_CLOUDPASS_API,
      IDP_CLIENT,
      CLIENTS_LIST: tbxClientsJSON,
      IDP_SHORTCODE,
      USE_CLOUDHUB_CONFIG: currentENV.TBX_IDP_USE_CLOUDHUB || useCloudhubConfig
    },
    unity: {
      API_URL: currentENV.TBX_UNITY_API
    }
  };
}

const CONFIG = getEnvConfig(ENV);

export default CONFIG;