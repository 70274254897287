import React from 'react';

import './styles.scss';

const Backdrop = ({
  show,
  toggleBackdrop
}) =>
  show ? (
    <div onClick={toggleBackdrop} className="backdrop"></div>
  ) : null;

export default Backdrop;
