import React from 'react';
import './styles.scss'
import { Button } from 'react-bootstrap';
import useRouter from '../../../../customHooks/useRouter';
import FadeIn from 'react-fade-in/lib/FadeIn';
import {TITLES_MODAL} from '../constants'
import { useTranslation } from 'react-i18next';

function NotAvailable({
    translationTexts,
    checkifHaveContentAcces,
    isDesktop,
    selectError,
    pathLogin
}) {
    const { t } = useTranslation();
    const router = useRouter();
    const [ title, setTitle ] = React.useState(null);
    const [bodyBool, setBodyBool] = React.useState(false);
    const [bodyTitle, setBodyTitle] = React.useState(null);
    const [bodyButtonTitle, setBodyButtonTitle] = React.useState(null);
    const [bodyButtonHandler, setBodyButtonHandler] = React.useState(null);
    const [codeDescription, setCodeDescription] = React.useState(null);


    const clearStates = () => {
        setBodyBool(false);
        setBodyTitle(null);
        setBodyButtonTitle(null);
        setBodyButtonHandler(null);
    }

    React.useEffect(() => {
        clearStates()
        if (!isDesktop) {
            setBodyTitle('isDesktop');
            setBodyBool(true);
        }
        if(selectError?.code && TITLES_MODAL[selectError?.code]){
            setTitle(t(TITLES_MODAL[selectError?.code].title));
            setCodeDescription(selectError?.code);
        }else{
            setTitle(translationTexts.content_not_available)
        }
        if (typeof checkifHaveContentAcces === 'boolean' && !checkifHaveContentAcces) {
            setBodyTitle(translationTexts['you_do_not_have_access_to_this_content']);
            setBodyBool(true);
            setBodyButtonTitle(translationTexts.sign_in);
            const buttonHandler = () => (
                () => {
                    const { href } = window.location;
                    const returnURL = href;
                    router.push(pathLogin, { returnURL });
                }
            )
            setBodyButtonHandler(buttonHandler);
        } else if (selectError?.code || selectError?.message) {
            if(selectError?.code && TITLES_MODAL[selectError?.code]){
                setBodyTitle(t(TITLES_MODAL[selectError?.code].message));
                setCodeDescription(selectError?.code)
            }else{
                setBodyTitle(selectError?.code || selectError?.message);
                setCodeDescription(selectError?.code)
            }
            
            setBodyBool(true);
        }

    }, [checkifHaveContentAcces, isDesktop, selectError])

    
    /*if(selectError?.code && TITLES_MODAL[selectError?.code]){
        return (<>hola</>)
    }*/


    return (<FadeIn className='playerEpgSectionPlayer_contentNotAvailable'>
        <strong className='playerEpgSectionPlayer_contentNotAvailable_title'>{title}</strong>
        <div className='playerEpgSectionPlayer_contentNotAvailable_body'>
            {bodyBool &&
                <React.Fragment>
                    <div className='playerEpgSectionPlayer_contentNotAvailable_body_title'>
                        {bodyTitle}
                    </div>
                    { codeDescription && 
                        <div className='playerEpgSectionPlayer_contentNotAvailable_code_title'>
                            {codeDescription}
                        </div> 
                    }
                    {(bodyButtonTitle && bodyButtonHandler) &&
                        <Button className='playerEpgSectionPlayer_contentNotAvailable_body_button' onClick={bodyButtonHandler}>
                            {bodyButtonTitle}
                        </Button>}
                </React.Fragment>
            }
        </div>
    </FadeIn>)
}

export default (NotAvailable);
