import React from 'react';
import {
  useDispatch
} from 'react-redux';
import useRouter from '../../../customHooks/useRouter';
import { MAX_CHARS_CODE } from './constants';
import * as actions from './actions';



/**
 * DidMount like hook effect
 *
 * @export
 */
 export function useDidMount() {
  const dispatch = useDispatch();

  React.useEffect(() => {
   dispatch(actions.showMessageInform(false));
  }, []);
}


/**
 * useValidationCode like hook effect
 *
 * @export
 */
export function useValidationCode(isFetching,PATHS) {
  const router  = useRouter();
  const dispatch = useDispatch();
  const [pinCodeValue, setPinCodeValue] = React.useState('');
  const [disabledState, setDisabledState] = React.useState(false);
  const [cleanUp, setCleanUp] = React.useState(()=>{});
  
  const cleanUpHanlderClosure = (f) => setCleanUp(()=>f);

  const handlePinCodeChange = pinCode => setPinCodeValue(pinCode);

  const handleBackToSite = () => router.push(PATHS.home);

  const handleConfirmCode = () => dispatch(actions.fetchValidateCode(pinCodeValue));


  React.useEffect(()=>{
    if(pinCodeValue.length === MAX_CHARS_CODE.CUSTOM){
      setDisabledState(true);
      dispatch(actions.fetchValidateCode(pinCodeValue));
      cleanUp()
    }
  },[pinCodeValue]);


  React.useEffect(()=>{
    disabledState && !isFetching && setDisabledState(false);
  },[isFetching])

  return {
    pinCodeValue,
    cleanUpHanlderClosure,
    disabledState,
    handleConfirmCode,
    handleBackToSite,
    handlePinCodeChange,
  }
}
