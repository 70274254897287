import { CONTENT_ID_CONST } from "./constants";

export const findPath = (pathname, PATHS, countryPath) => {
    const countryAndPathname = countryPath + pathname

    const converterToArray = (pathname) => {
        return pathname.slice(1, pathname.length).split('/');
    }

    const pathnameArray = converterToArray(pathname)
    const pathnameCountryArray = converterToArray(countryAndPathname)


    const pathRouteMerging = (pathnameArray, pathArray) => {
        return pathnameArray.reduce((previusValue, currentValue, index) => {
            previusValue += '/'
            if (pathArray[index] === CONTENT_ID_CONST) return previusValue + currentValue
            return previusValue + pathArray[index];
        }, '');
    }


    const findPathResult1 = Object.entries(PATHS)?.find(([key, path]) => {
        return (path === pathname) || (path === countryAndPathname)
    })
    
    const findPathResult2 = Object.entries(PATHS)?.reduce((previusValue, [key, path]) => {
        if (!path.includes(CONTENT_ID_CONST)) return previusValue;
        const pathArray = converterToArray(path)
        const pathResult1 = pathRouteMerging(pathnameArray, pathArray);
        const pathResult2 = pathRouteMerging(pathnameCountryArray, pathArray);
        if (pathResult1 === countryAndPathname) previusValue = [key, pathResult1]
        if (pathResult2 === countryAndPathname) previusValue = [key, pathResult2]
        return previusValue;
    }, [[]])

    return findPathResult1 || findPathResult2

}


export const isPathRoute = (pathname, PATHS) => Object.values(PATHS).some((path) => path === pathname);
