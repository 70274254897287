
export const types = {
    VALIDATE_CODE_FETCH: 'ActivateCodeSection/VALIDATE_CODE_FETCH',
    VALIDATE_CODE_FETCH_FAILURE: 'ActivateCodeSection/VALIDATE_CODE_FETCH_FAILURE',
    VALIDATE_CODE_FETCH_SUCCESS: 'ActivateCodeSection/VALIDATE_CODE_FETCH_SUCCESS',
    SHOW_MESSAGE: 'ActivateCodeSection/SHOW_MESSAGE',
  };
   
export const MAX_CHARS_CODE = {
    CUSTOM: 6
  }