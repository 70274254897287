import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useRouter from '../../../customHooks/useRouter';
import useWidgetTranslation from '../../../customHooks/useWidgetTranslation';
import * as hooks from './hooks';
import * as selectors from './selectors';
import * as actions from './actions';
import { isDesktop } from 'react-device-detect';

import { BRAND_INFO } from '../../../constants/utilTypes';
import { ERROR_CODE } from '../../../constants/errorCode';
import { TITLES } from './constants';
import { PATH_ERROR } from '../../AppSectionRouter/constants';
import { NO_IMAGE_DEFAULT } from '../../../constants/noImage';
import { IMAGE_MODE, TRUCATE_STRING, CONTENT_TYPES as TYPES } from '../../../constants/contentTypes';
import { selectCurrentContentPosition } from '../../ProfileManager/selectors';

import { convertPath } from '../../../utils/pathRoutesUtils';
import { validateUrlRedirect } from '../../../utils/ContentUtils';
import { getContentImageByMode } from '../../../utils/imageUtils';
import { truncateString } from '../../../utils/objectUtils';
import { checkifHaveContentAccess } from '../../../utils/validationUserUtils';

//#region /////// COMPONENTS ///////
import SEO from '../../../components/Seo';
import Footer from '../../../components/Footer';
import {
  ContentOverviewFuture,
  ContentOverviewLive,
  ContentRelated
} from '@tbx/experience-widgets-lib';
import { SectionErrorBoundary } from '../../../HOCs/WithErrorBoundary';
import BgImgSport from "../../../static/images/bg_ficha_sport.jpg";
import { useNotificationModalCreate } from '../PlayerSection/hooks';
//#endregion

//#region /////// VARIABLES goBackFunction ///////
const redirect = {
  url: '/',
  countGoBack: 0,
  boolGoBack:false
}
//#endregion

function ContentOverViewSection({ accessToken, storeTime }) {
  const router = useRouter();
  const contentID = router.query.contentID;
  const mainDiv = React.useRef();
  const translationTexts = useWidgetTranslation([
    ...TITLES,
    'at',
  ]);
  const [showFooter, setShowFooter] = React.useState(false);
  const dispatch = useDispatch();

  //#region /////// SELECTORS ///////
  const PATHS = useSelector(state => selectors.selectPathList(state));
  const selectedContent = useSelector(state => selectors.selectContentWidget(state));
  const selectedContentRelated = useSelector(state => selectors.selectContentRelatedWidget(state));
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const networks = useSelector(state => selectors.selectNetworks(state));
  const redirectUrl = useSelector(state => selectors.selectContentsUrlByID(state));
  const currentContentPosition = useSelector(state => selectCurrentContentPosition(state));
  const selectError = useSelector(state => selectors.selectError(state));
  const appSettings = useSelector(state => selectors.selectAppSettings(state));
  const notificationModalCreate = useNotificationModalCreate(selectedContent, appSettings.packageUpgradeMessages, PATHS);
  //#endregion

  //#region ///// HOOK EFFECTS /////
  hooks.useDidMount(accessToken, contentID, mainDiv, redirectUrl, authenticated);
  const toastAdd = hooks.useToastAdd();
  hooks.useStoreTimeOnClickBack(storeTime, redirect, router, PATHS)
  //#endregion

  const truncatedDescription = truncateString(
    selectedContent?.description, TRUCATE_STRING.METATAGS_DESCRIPTION
  );
  const imageUrl = getContentImageByMode(selectedContent?.images, 'THUMB')?.url;

  const onClickPlayerButton = (contentId) => {
    const hasRedirect = validateUrlRedirect({
      currentContent: selectedContent
    });
    hasRedirect && authenticated && dispatch(actions.getContentsUrlById(accessToken, contentId));

    if (!isDesktop) {
      alert('Visualiza este contenido desde un PC o laptop.');
    } else if (checkifHaveContentAccess(authenticated, selectedContent)) {

       if(!hasRedirect) {
          redirect.countGoBack = 0;

          const subPath = currentContentPosition ? currentContentPosition.currentContent.id : contentId;

          router.push(convertPath(PATHS.player, subPath))
        }

    } else {
      redirect.countGoBack = 0;
      const { origin } = window.location;
      const returnURL = origin + convertPath(PATHS.content,contentID);
      router.push(PATHS.login, { returnURL });
    }
  }

  const onClickContentSelected = React.useCallback(async (ev, contentid) => {
    ev.preventDefault();

    if(!contentid){
      return false;
    }

    redirect.countGoBack = 0;
    setShowFooter(false);
    
    router.push(convertPath(PATHS.content,contentid));
  }, [router]);


  const goBackFunction = React.useCallback(async () => {
    if ((redirect.countGoBack >= 2 && redirect.boolGoBack) ||
      router.location.state?.oneBack === PATHS.player ||
      !router.location.state?.oneBack) {
        redirect.countGoBack = 0;
      return router.push(redirect.url);
    }
    redirect.countGoBack = redirect.countGoBack + 1;
    return router.goBack();
  }, [router]);

  const catchError = (error) => {
    return router.push(PATH_ERROR, { error });
  };

  React.useEffect(()=>{
    if (ERROR_CODE[selectError.code] || (!!selectError.code && !!selectError.details && !!selectError.message)){
      if ( selectError?.code === 'CS-011' ) {
        notificationModalCreate(selectError);
        dispatch(actions.clearErrorStates());
        return;
      }
      toastAdd(selectError);
      return goBackFunction();
    }
  },[selectError]);

  return (
    <React.Fragment>
      {selectedContent && 
        <SEO
          title={`${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title}`}
          contentTitle={`${BRAND_INFO.CLIENT_NAME} - ${selectedContent?.title}`}
          description={truncatedDescription} image={imageUrl}
        />
      }

      <div ref={mainDiv} className="main-content">
        <div onLoad={()=> setShowFooter(false)} className="ContentOverviewLiveSection">
          {selectedContent?.live && selectedContent?.contentType === TYPES.SPORT ?
            <ContentOverviewFuture
              content={selectedContent}
              isLoading={!selectedContent}
              onClickPlayerButton={onClickPlayerButton}
              translationTexts={translationTexts}
              showPlayIcon={false}
              backgroundImage={BgImgSport}
              redirectContent={validateUrlRedirect({
                currentContent: selectedContent
              })}
              networks={networks}
            />
            :
            <ContentOverviewLive 
              translationTexts={translationTexts} 
              content={selectedContent} 
              onClickPlayerButton={onClickPlayerButton} 
              onClickBackButton={goBackFunction} 
              networks={networks} 
              isLoading={!selectedContent}
              currentContentPosition={currentContentPosition} 
              contentNoImageProps={{
                backgroundColorOne: NO_IMAGE_DEFAULT().backgroundColorOne,
                backgroundColorTwo: NO_IMAGE_DEFAULT().backgroundColorTwo
              }} 
              catchError={catchError}
              redirectContent={validateUrlRedirect({
                currentContent: selectedContent
              })}
              showPlayIcon={false}
            />
          }
        </div>
        <div>
          {selectedContentRelated &&
            <ContentRelated 
              content={selectedContentRelated} 
              translationTexts={translationTexts}
              imageMode={IMAGE_MODE.THUMB}
              onClickContentSelected={onClickContentSelected} 
              isLoading={!selectedContentRelated} 
              catchError={() => {}}
              textMode={selectedContent?.contentType}
            />
          }
        </div>
        { showFooter && <Footer /> }
      </div>
    </React.Fragment>
  );
}



export default SectionErrorBoundary(ContentOverViewSection);
