import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const DEBUG_MODE = (window.__TBX_ENV__.NODE_ENV !== 'production');

const options = {
  backend: {
    loadPath: '/locales/{{lng}}.json'
  },
  debug: DEBUG_MODE,
  // do not load a fallback
  fallbackLng: false,
  lng: 'es',
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },
  // allow keys to be phrases having `:`, `.`
  nsSeparator: false,
  keySeparator: false,
  react: {
    useSuspense: false
  }
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language`
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options);

export default i18n;