import { types } from './constants';

export const loadAppSections = (accessToken, unityAuthData,filters = {}) => ({
  type: types.ROUTER_SECTIONS_FETCH,
  unityAuthData,
  accessToken,
  filters
});

export const loadAppSectionsError = (error) => ({
  type: types.ROUTER_SECTIONS_FETCH_FAILURE,
  error
});

export const loadAppSectionsSuccess = (sectionsData) => ({
  type: types.ROUTER_SECTIONS_FETCH_SUCCESS,
  sectionsData
});

export const pathRoutesAdd = (pathList) => ({
  type: types.ROUTER_SECTIONS_ADD_PATH_ROUTES,
  pathList
});

export const pathCountryAdd = (pathCountry) => ({
  type: types.ROUTER_SECTIONS_ADD_PATH_COUNTRY,
  pathCountry
});

export const pathRoutesAddSectionCollection = (pathList) => ({
  type: types.ROUTER_SECTIONS_ADD_PATH_ROUTES_SECTION_COLLECTION,
  pathList
});
