import { unityContentsService } from "@tbx/experience-widgets-lib";

export const types = {
  WIDGET_FETCH: 'ContentOverViewLiveSection/WIDGET_FETCH',
  WIDGET_FETCH_FAILURE: 'ContentOverViewLiveSection/WIDGET_FETCH_FAILURE',
  WIDGET_FETCH_SUCCESS: 'ContentOverViewLiveSection/WIDGET_FETCH_SUCCESS',
  CLEAR_STATES: 'ContentOverViewLiveSection/CLEAR_STATES',
  CLEAR_ERROR_STATES: 'ContentOverViewSection/CLEAR_ERROR_STATES',
  CONTENTS_URL_ID_FETCH: 'ContentOverViewLiveSection/CONTENTS_URL_ID_FETCH',
  CONTENTS_URL_ID_FETCH_FAILURE: 'ContentOverViewLiveSection/CONTENTS_URL_ID_FETCH_FAILURE',
  CONTENTS_URL_ID_FETCH_SUCCESS: 'ContentOverViewLiveSection/CONTENTS_URL_ID_FETCH_SUCCESS',
  RELATED_WIDGET_FETCH_SUCCESS: 'ContentOverViewLiveSection/RELATED_WIDGET_FETCH_SUCCESS',
};

export const CONTENT_TYPES = {
  BROADCAST: {
    SERVICE: unityContentsService.getContents,
    LOAD_ACTION: 'loadWidgetBroadcastSuccess'
  },

  SPORT: {
    SERVICE: unityContentsService.getContentsRelated,
    LOAD_ACTION: 'loadWidgetRelatedSuccess'
  }
};

export const TOAST_TEXT = {
  "TITLE": 'Content',
  "SE-003": "Content not available.",
  "DEFAULT": "We are very sorry. There was a problem trying to open content."
}

export const TITLES = [
  'Available until',
  'Play in',
  'Play now', 
  'My list',
  'Now live',
  'Start in',
  'Lang',
  'Continue watching',
  'Content not available',
  'Live on',
  'Available live on',
  'Live',
  'Related'
];
