import { types } from './constants';

export const fetchValidateCode = (code) => ({
  type: types.VALIDATE_CODE_FETCH,
  code
});

export const fetchValidateCodeError = (error) => ({
  type: types.VALIDATE_CODE_FETCH_FAILURE,
  error
});

export const fetchValidateCodeSuccess = (status) => ({
  type: types.VALIDATE_CODE_FETCH_SUCCESS,
  status
});

export const showMessageInform = (status) => ({
  type: types.SHOW_MESSAGE,
  status
});