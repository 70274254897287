import { useTranslation } from 'react-i18next';

// Hook
export default function useWidgetTranslation(values) {

    const {t} = useTranslation();
    const result = {};

    values?.forEach(val => {
        const key = val.replaceAll(' ', '_').toLowerCase();
        result[key.replace(/['"]+/g, '')] = t(val);
    });

    return result;
 
}