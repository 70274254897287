import React from 'react';
import { useSelector } from 'react-redux';
import ActivateCode  from '../../../components/ActivateCode';
import Footer from '../../../components/Footer';
import * as hooks from './hooks';
import * as selectors from './selectors';
import { isDesktop } from 'react-device-detect';
import './styles.scss';

function ActivateCodeSection() {
    const refFooter = React.useRef(null);

    //SELECTORS
    const validatePinCodeStatus = useSelector((state) => selectors.selectActivationCodeStatus(state));
    const showMessage = useSelector((state) => selectors.selectShowMessage(state));
    const isFetching = useSelector((state) => selectors.selectIsFetching(state));
    const PATHS = useSelector(state => selectors.selectPathList(state))
    // CUSTOM HOOKS
    const { 
      pinCodeValue,
      cleanUpHanlderClosure,
      disabledState,
      handleConfirmCode,
      handleBackToSite,
      handlePinCodeChange } = hooks.useValidationCode(isFetching,PATHS);

    return (
        <div ref={refFooter}>
                
            <ActivateCode
                validatePinCodeStatus={validatePinCodeStatus}
                handlePinCodeChange={handlePinCodeChange}
                handleConfirmCode={handleConfirmCode}
                handleBackToSite={handleBackToSite}
                pinCodeValue={pinCodeValue}
                showMessage={showMessage}
                isFetching={isFetching}
                cleanUpHanlderClosure={cleanUpHanlderClosure}
                isDisabled={disabledState}
            />
            { /*refFooter.current && isDesktop && <Footer />*/ }
            
        </div> 
    )

}

export default ActivateCodeSection;