import { createSelector } from 'reselect';

const getAppReducer = (state) => state.containers.app;
const getSessionReducer = (state) => state.session;
const getReducer = (state) => state.containers.homeSection;
const getProfileReducer = (state) => state.containers.profileManager;
const getPlayerEpgSection = (state) => state.containers.playerEpgSection;
const getAppSectionsReducer = (state) => state.containers.appSections;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken
);

export const selectAuthenticated = createSelector(
  getSessionReducer,
  (reducer) => reducer.authenticated
);

export const selectWidgetCollection = createSelector(
  getReducer,
  (reducer) => reducer.widgetCollection
);

export const selectSelectedContent = createSelector(
  getReducer,
  (reducer) => reducer.selectedContent
);

export const selectToggleModal = createSelector(
  getReducer,
  (reducer) => reducer.toggleModal
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings
);

export const selectNetworks = createSelector(
  getAppReducer,
  (reducer) => reducer.networks
);

export const selectChannels = createSelector(
  getAppReducer,
  (reducer) => reducer.channels
);

export const selectFavoritesContents = createSelector(
  getProfileReducer,
  (reducer) => reducer.favorites
);

export const selectFavoritesIsFetching = createSelector(
getProfileReducer,
  (reducer) => reducer.isFetching
);

export const selectWidgetCollectionPaginate = createSelector(
  getReducer,
  (reducer) => reducer.widgetCollectionPaginate ?? []
)

export const selectPage = createSelector(
  getReducer,
  (reducer) => reducer.page
);

export const selectIsPlayerEpgSection = createSelector(
  getPlayerEpgSection,
  (reducer) => reducer.isPlayerEpgSection
);

export const selectSectionError = createSelector(
  getReducer,
  (reducer) => reducer.error
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList
);