import React from 'react';
import PropTypes from 'prop-types';

//#region ///// COMPONENTS /////
import Avatar from '../Avatar';
//#endregion
import './styles.scss';

function AvatarList({
  avatarCollection,
  handleAvatarEdit
}) {

  return (
    <div className={`avatarList ${avatarCollection.length < 7 ? `avatarList__items__${avatarCollection.length}` : ''}`}>
      {
        avatarCollection.map((item) => {
          return (
            <Avatar
              avatarData={item}
              handleAvatarEdit={handleAvatarEdit}
              className={'avatarList_avatar'}
            />
          )
        })
      }
    </div>
  )
}

AvatarList.propTypes = {
  avatarCollection: PropTypes.array,
  handleAvatarEdit: PropTypes.func
};

export default React.memo(AvatarList); 