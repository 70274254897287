import React from 'react';
import { useTranslation } from 'react-i18next';
import { BRAND_INFO } from '../../constants/utilTypes';
import './styles.scss';

const currentYear = new Date().getFullYear();

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer dtv-footer-container-home ">
      <div className="footer-container">
        <div className="footer-linksLegales">
          <div className="footer-linksLegales-links">
            <a className="footer-linksLegales-links-Terminos" href="#" target="_blank">{t('Frequent questions')}</a> 
            <span className="footer-linksLegales-links-span">|</span>
            <a className="footer-linksLegales-links-Help" href="#" target="_blank">{t('Customer support')}</a>
            <span className="footer-linksLegales-links-span">|</span>
            <a className="footer-linksLegales-links-Help" href="#" target="_blank">{t('User Guide')}</a>
            <span className="footer-linksLegales-links-span">|</span>
            <a className="footer-linksLegales-links-Politica" href="#" target="_blank">{t('Privacy policy')}</a>
          </div>
        </div>
        <div className="footer-socialMedia">
          <a href="https://www.facebook.com/" target="_blank"><i className='bi bi-facebook' /></a>
          <a href="https://twitter.com/" target="_blank"><i className='bi bi-twitter' /></a>
          <a href="https://www.instagram.com/" target="_blank"><i className='bi bi-instagram' /></a>
          <a href="https://www.youtube.com/" target="_blank"><i className='bi bi-youtube' /></a>
        </div>
        <div className='footer-rightsReserved'>{`© ${currentYear} ${BRAND_INFO.CLIENT_NAME} ${t('All rights reserved.')}`}</div>
      </div>
    </footer>
  );
}

export default Footer;
