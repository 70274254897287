
export const decodeToken = (token) => {
  const result = JSON.parse(atob(token.split('.')[1]));
  return result;
};

export const isTokenExpired = (token) => {
  const { exp } = decodeToken(token);

  return (exp < Date.now() / 1000);
}
