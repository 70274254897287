import React from 'react';
import { useDispatch } from 'react-redux'

import './styles.scss';
import ClientLogo from '../../static/images/logo.png';
import { notificationModalDelete } from '../../containers/App/actions';
import classNames from '../../utils/classnameUtils';
import { useTranslation } from 'react-i18next';

//#region /////// TBX WIDGETS ///////
import {
    Imgcomponent
  } from '@tbx/experience-widgets-lib';
  //#endregion

function NotificationModal({ notificationModal }) {
    const { title, message, htmlText, error, errorCode, logoImg, firstButton, secondButton, className, exitButton } = notificationModal;
    const dispatch = useDispatch();
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <div className={classNames('tbxNotificationModal-container', !!error?.code && 'tbxNotificationModal-errorCode-' + error?.code, !!className && 'tbxNotificationModal-' + className)}>
                {exitButton && <button className='tbxNotificationModal-exitButton' onClick={() => dispatch(notificationModalDelete())}><i className="bi bi-x-lg tbxNotificationModal-exitButton-icon"></i></button>}
                {(logoImg) && <Imgcomponent className="tbxNotificationModal-logo" src={ClientLogo} loading={'eager'}/>}
                {htmlText && <div className={'tbxNotificationModal-htmlText'} dangerouslySetInnerHTML={{ __html: htmlText }}>
                </div>}
                {title && !htmlText && <div className={'tbxNotificationModal-header'}>
                    <h2 className={'tbxNotificationModal-title'}>
                        {title}
                    </h2>
                </div>}
                {message && !htmlText && <div className={'tbxNotificationModal-body'}>
                    <div className={'tbxNotificationModal-message'}>
                        {message}
                    </div>
                </div>}
                {errorCode && <div className='tbxNotificationModal-details' style={{ whiteSpace: 'pre-wrap' }}>
                    <div className='tbxNotificationModal-summary'>{t('ERROR CODE')}</div>
                    <div className='tbxNotificationModal-errorText'>
                        {error?.code}
                    </div>
                </div>}
                {(!!firstButton || !!secondButton) && <div className={'tbxNotificationModal-footer'}>
                    {firstButton && <button className={'tbxNotificationModal-firstButton'} onClick={() => {
                        firstButton?.handlerFunction ? firstButton?.handlerFunction() : dispatch(notificationModalDelete());
                    }}>{firstButton.text}</button>
                    }
                    {secondButton && <button className={'tbxNotificationModal-secondButton'} onClick={() => {
                        secondButton?.handlerFunction ? secondButton?.handlerFunction() : dispatch(notificationModalDelete());
                    }}>{secondButton?.text}</button>}
                </div>}
            </div>
        </React.Fragment>
    );
}

export default NotificationModal;