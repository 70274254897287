import { types } from './constants';

export const loadWidget = (accessToken, contentID) => ({
  type: types.WIDGET_FETCH,
  accessToken,
  contentID
});

export const loadWidgetError = (error) => ({
  type: types.WIDGET_FETCH_FAILURE,
  error
});

export const loadWidgetSuccess = (content) => ({
  type: types.WIDGET_FETCH_SUCCESS,
  content
});

export const loadWidgetRelatedSuccess = (content) => ({
  type: types.RELATED_WIDGET_FETCH_SUCCESS,
  content
});

export const clearStates = () => ({
  type: types.CLEAR_STATES
});

export const getContentsUrlById = (accessToken, contentID) => ({
  type: types.CONTENTS_URL_ID_FETCH,
  accessToken, 
  contentID
});

export const getContentsUrlByIdError = (error) => ({
  type: types.CONTENTS_URL_ID_FETCH_FAILURE,
  error
});

export const getContentsUrlByIdSuccess = (content) => ({
  type: types.CONTENTS_URL_ID_FETCH_SUCCESS,
  content
});

export const clearErrorStates = () => ({
  type: types.CLEAR_ERROR_STATES
});
