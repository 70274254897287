import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';

import app from '../containers/App/reducer';
import appSections from '../containers/AppSectionRouter/reducer';
import homeSection from '../containers/sections/HomeSection/reducer';
import navbar from '../containers/Navbar/reducer';
import profileManager from '../containers/ProfileManager/reducer';
import searchSection from '../containers/sections/SearchSection/reducer';
import session from '../containers/App/sessionReducer';
import playerSection from '../containers/sections/PlayerSection/reducer';
import playerEpgSection from '../containers/sections/PlayerEpgSection/reducer';
import contentOverViewSection from '../containers/sections/ContentOverViewSection/reducer';
import contentOverViewLiveSection from '../containers/sections/ContentOverViewLiveSection/reducer';
import activateCodeSection from '../containers/sections/ActivateCodeSection/reducer';


import { types } from '../containers/App/constants';

const reducers = {
  containers: combineReducers({
    app,
    appSections,
    homeSection,
    navbar,
    profileManager,
    searchSection,
    playerSection,
    playerEpgSection,
    contentOverViewSection,
    contentOverViewLiveSection,
    activateCodeSection
  }),
  session
};

const reducerWithHistory = (history, asyncReducers) => (
  combineReducers({
    ...reducers,
    dynamicSections: asyncReducers,
    router: connectRouter(history)
  })
);

const createGlobalReducer = (history, asyncReducers = new Map()) => {
  const appReducer = reducerWithHistory(history, asyncReducers);

  return (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === types.APP_DEVICE_LOGGED_OUT) {
      storage.removeItem('persist:tbx').then(res => {
        console.log('PersistedStore CLEARED!');
      }).catch(e => {
        console.error(`Error ocurrs when trying to clear persistedStore. details: ${e}`)
      });

      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };
};

export default createGlobalReducer;
