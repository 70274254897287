import React from 'react';
import {
  useDispatch,
  useSelector
 } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import { MAX_RATING_DEFAULT, NUMBER_FIELDS_PIN } from './constants';
import { logout, notificationModalCreate, notificationModalDelete, toggleProfilesModal } from '../App/actions';
import { changeAccountMenuStatus } from '../Navbar/actions';
import { useTranslation } from 'react-i18next';
import { getAvatarIdRandom, isAdminProfile, validateDefaultProfileInCollection } from '../../utils/ProfileUtils';


const initialDataForm =  {
  name: '',
  maxRating: MAX_RATING_DEFAULT.ADULT,
  enableAdultContent: false,
  props: {
    email: null,
    isAdmin: true
  },
  hasPIN: false
}

export function useUnityProfiles() {
  const dispatch = useDispatch();
  const accessToken = useSelector(state => selectors.selectAccessToken(state));
  const getDeviceProfiles = React.useCallback(() => {
        dispatch(actions.clearStates());
        dispatch(actions.fetchProfiles(accessToken));
    }, [accessToken, dispatch]);

  React.useEffect(() => {
    getDeviceProfiles();
  }, [getDeviceProfiles]);
}

export function useContentProfile(profileDataFetch, profileformStatus, isAddFormMode, currentDataProfileSelected, history) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const PATHS = useSelector(state => selectors.selectPathList(state));
  const [profileDataForm, setProfileDataForm] = React.useState(initialDataForm);
  //const [isAddMode, setIsAddMode] = React.useState(true);
  const [enableAvatarOption, setEnableAvatarOption] = React.useState(false);
  const [pinCodeValue, setPinCodeValue] = React.useState('');
  const profileCollection = useSelector(state => selectors.selectProfileCollection(state));
  const avatarCollection = useSelector(state => selectors.selectAvatarCollection(state));

  const handleOnSelectProfile = (profileID) =>{
    dispatch(actions.setCurrentProfile(profileID));
    dispatch(toggleProfilesModal(false));
    dispatch(changeAccountMenuStatus(false));
    dispatch(actions.changeEnableProfileOpeartions(false));
  } 

  const handleOnChangeProfileNameForm = (event) => {
    const profileName = event.target.value;
    setProfileDataForm(prevState => ({
      ...prevState, 
      name: profileName
      }))
    }

  const handleCancel = (event) => {
    event.preventDefault();
    onChangeProfileFormStatus();
  }

  const handleAvatarBackButton = (event) => {
    event.preventDefault();
    setEnableAvatarOption(false);
    dispatch(actions.changeProfileFormStatus(true));

  }

  const handleOnAddProfile = () => {
    handleClearForm();
    setProfileDataForm(prevState => ({
      ...prevState, 
      props: {
        ...initialDataForm.props,
        avatarId: getAvatarIdRandom(avatarCollection)
      }
     })
    )
    
    dispatch(actions.changeProfileFormStatus(true));
    dispatch(actions.changeFormProfileMode(true));

  } 

  const handleOnEditProfile = (profileID) => {
    handleClearForm();
    dispatch(actions.fetchProfileById(profileID));
    dispatch(actions.changeProfileFormStatus(true)); 
    dispatch(actions.changeFormProfileMode(false));

  } 

  const handleOnDeleteProfile = (profileID) => {
  
    dispatch(actions.fetchDeleteProfile(profileID));
    //onChangeProfileFormStatus();
    dispatch(notificationModalDelete());

  }

  const handleDeletePinCode = (profileID) => {
    const notificationModal = {
      title: t("Confirm delete pin"),
      message: "",
      logoImg: false,
      errorCode: false,
      firstButton: {
        text: t('Accept'),
        handlerFunction: () => handleOnDeletePinCode(profileID)
      },
      secondButton: {
        text: t('Cancel')
      },
      className: 'confirmDeleteProfile'
    }
    dispatch(notificationModalCreate(notificationModal))
  }

  const handleOnDeletePinCode = () => {
  
    dispatch(actions.deletePinCodeProfile(profileDataFetch?.id));
    dispatch(notificationModalDelete());
    //dispatch(actions.changeProfileFormStatus(false));

  }

  const handleConfirmDeleteProfile = (profileID) => {

    const notificationModal = {
      title: t("Confirm delete profile"),
      message: "",
      logoImg: false,
      errorCode: false,
      firstButton: {
        text: t('Accept'),
        handlerFunction: () => handleOnDeleteProfile(profileID)
      },
      secondButton: {
        text: t('Cancel')
      },
      className: 'confirmDeleteProfile'
    }
    dispatch(notificationModalCreate(notificationModal))
  }

  const handleAvatarOption = (avatarID) => {
      setEnableAvatarOption(true);
  } 

  const handleAvatarEdit = (avatarID) => {

    setProfileDataForm(prevState => ({
      ...prevState,
      props: { 
          email: prevState.email,
          isValidEmail: prevState.isValidEmail,
          validationMailSent: prevState.validationMailSent,
          isAdmin: prevState.isAdmin,
          modified: prevState.modified,
          avatarId: avatarID
      }
    }));

    setEnableAvatarOption(false);

  } 

  const handleMaxRatingToggle = (val) => {

    setProfileDataForm(prevState => ({
      ...prevState,
      maxRating: val
    }))
  }

  const handleEnableAdultContentToggle = (val) => {
    setProfileDataForm(prevState => ({
      ...prevState,
      enableAdultContent: val
    }))
  }

  const handleClearForm = () => 
  {
    setProfileDataForm(initialDataForm);
    setPinCodeValue('');
  }

  const onChangeProfileFormStatus = () =>
  {
    const { max_rating } = currentDataProfileSelected || {};
    const isAdult = isAdminProfile(max_rating);

    dispatch(actions.changeProfileFormStatus(false));

    if(!isAdult) {
      dispatch(toggleProfilesModal(false));
      dispatch(changeAccountMenuStatus(false));
    }
  }
 
  const handleProfileTypeSelected = (val) => {
    
    if(isAddFormMode) {
      setProfileDataForm(prevState => ({
        ...prevState,
        maxRating: val
      }));
    }
   
  }

  const handleExitPinCodeModal = () => {
    dispatch(toggleProfilesModal(false));
    dispatch(changeAccountMenuStatus(false));
    dispatch(actions.enablePinCodeValidateModal(false, ''));
  }

  const handleValidatePinCodeProfile = (value) => {
    const { id } = currentDataProfileSelected;
    dispatch(actions.validatePinCodeProfile(id, value));
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const {name:profileNameForm, maxRating:maxRatingEnabled, enableAdultContent} = profileDataForm;
    const isDefaultProfile = profileCollection.length == 1 && !!validateDefaultProfileInCollection(profileCollection);
    
    //Create Profile
    isAddFormMode && !isDefaultProfile && profileNameForm && profileformStatus && dispatch(actions.fetchCreateProfile(profileDataForm, pinCodeValue));
    
    // Create first Profile where the default profile is updated with the new data
    isAddFormMode && isDefaultProfile && profileNameForm && profileCollection[0]?.id && profileformStatus && dispatch(actions.fetchEditProfile(profileCollection[0]?.id,profileDataForm, pinCodeValue));
    
    //Edit Profile
    !isAddFormMode && profileNameForm && profileDataFetch?.id && profileformStatus && dispatch(actions.fetchEditProfile(profileDataFetch.id,profileDataForm, pinCodeValue));

    //onChangeProfileFormStatus();
    //handleClearForm();
  }

  const handlePinCodeChange = pinCode => setPinCodeValue(pinCode);
  
  const handlePinCodeSubmit = (event) => {
    event.preventDefault();
 
    if(pinCodeValue.length == NUMBER_FIELDS_PIN.CUSTOM)  {
      setPinCodeValue('');
      handleValidatePinCodeProfile(pinCodeValue);
    }
  };

  const handlePinCodeCancel = (event) => {
    event.preventDefault();
    dispatch(toggleProfilesModal(false));
    dispatch(changeAccountMenuStatus(false));
    dispatch(actions.enablePinCodeValidateModal(false, ''));
  }

  const handleProfilePinCodeLogout = (e) => {
    e.preventDefault();
    const { origin } = window.location;
    history.push(PATHS.logout, { returnURL: origin });
    history.go();
  }
  
  React.useEffect(()=> {
    if(!isAddFormMode && profileDataFetch) {
      const {name, max_rating, props, hasPIN} =  profileDataFetch;
      const { avatar } = props;
   
      setProfileDataForm(prevState => ({
        ...prevState,
        name: name,
        maxRating: max_rating,
        props: {...props,
                   avatarId: avatar?.id
        },
        hasPIN: hasPIN
    }))

    }

  }, [isAddFormMode, profileDataFetch])

  return {
    enableAvatarOption,
    profileDataForm,
    pinCodeValue,
    handleOnSelectProfile,
    handleAvatarEdit,
    handleOnChangeProfileNameForm,
    handleCancel,
    handleSubmit,
    handleMaxRatingToggle,
    handleProfileTypeSelected,
    handleEnableAdultContentToggle,
    handleOnAddProfile,
    handleOnEditProfile,
    handleConfirmDeleteProfile,
    handleAvatarOption,
    handleAvatarBackButton,
    handlePinCodeChange,
    handleExitPinCodeModal,
    handleValidatePinCodeProfile,
    handlePinCodeSubmit,
    handlePinCodeCancel,
    handleDeletePinCode,
    handleProfilePinCodeLogout
  }

}