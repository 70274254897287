import {
  all,
  call,
  put,
  select,
  take,
  takeLatest
} from 'redux-saga/effects';
import {
  unityProfileService
} from '@tbx/experience-widgets-lib';

import { refreshedUnityToken, currentProfileSelected, toggleProfilesModal } from '../App/actions';
import * as actions from './actions';
import { types } from './constants';
import * as selectors from './selectors';
import { decodeToken } from '../../utils/jwtUtils';
import { getCurrentProfileData, getDefaultAdminProfile, getProfilesWithOutDefault, isAdminProfile } from '../../utils/ProfileUtils';
import { MAX_RATING_DEFAULT } from '../../containers/ProfileManager/constants';
import { changeAccountMenuStatus } from '../Navbar/actions';
import { DEFAULT_TOPIC } from '../../constants/contentTypes';
/**
 * Fetch unity available avatars for client
 *
 * @param {*} action
 */
function* fetchAvatars(action) {
  const { tokenData } = action;
  try {
    const { result } = yield call(unityProfileService.getClientAvatars, tokenData);

    if (!result || result.error) {
      throw new Error(result);
    }

     yield put(actions.fetchAvatarsSuccess(result));
  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('FETCH_UNITY_PROFILE_AVATARS_ERROR: ', errObj);
    yield put(actions.fetchAvatarsError(errObj));
  }
}

/**
 * Get Device Profiles
 * @param {*} action
 */
function* getProfiles(action) {
  let { tokenData } = action;
  const { parentalControl } = yield select(state => selectors.selectAppSettings(state));
  const accessToken = yield select(state => selectors.selectAccessToken(state));
 
  if(!tokenData) {
    tokenData = accessToken;
  }

  try {

      if(tokenData.access_token) {
        const [{ result }] = yield all([
          yield call(unityProfileService.getUserProfiles, tokenData),
          yield put(actions.fetchAvatars(tokenData))
        ]);

        if (!result || result.error) {
          throw new Error(result);
        }

        const { avatars } = yield take(types.AVATARS_FETCH_SUCCESS);

        const currentToken = tokenData.access_token ? decodeToken(tokenData.access_token) : {};
        const { profile } = currentToken;
        const { result: profilesResult } = result;

        // TODO: Con el dato de la configuracion obtenemos o no el profile por defecto ordenados por fecha de creacion
        if((parentalControl || parentalControl===undefined) || profile) {
          //const profilesWithoutDefault = getProfilesWithOutDefault(profilesResult);
          yield put(actions.fetchProfilesLoadSuccess(profilesResult, avatars, profile));
        }
        else
        {
            const profileByDefault = getDefaultAdminProfile(profilesResult);

            yield all([
              put(actions.fetchProfilesLoadSuccess(profilesResult, avatars, profileByDefault)),
              put(actions.setCurrentProfile(profileByDefault?.id))
            ]);
        }
    }
    else {
      console.warn("NO TOKEN DATA");
    }

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('GET_DEVICE_UNITY_PROFILES_ERROR: ', errObj);
    yield put(actions.fetchProfilesLoadError(errObj));
  }
}

function* setProfileToken(action) {
  const { profileID } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const token = yield select(state => selectors.selectFireBaseToken(state));

  try {
    const { result } = yield call(unityProfileService.setCurrentProfile, accessToken, profileID, { token });

    if (!result || !result.token || result.error) {
      throw new Error(result);
    }

    yield put(refreshedUnityToken(result.token));

    const { profile } = decodeToken(result.token.access_token);

    if(profile)
      yield put(actions.setCurrentProfileSuccess(profile));

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('SET_SELECTED_PROFILE_TOKEN_ERROR: ', errObj);
    yield put(actions.setCurrentProfileLoadError(errObj));
  }
}


/**
 * Get Cureent Content Position by Profile
 * @param {*} action
 */
 function* getCurrentContentPositionByProfile(action) {
  const { tokenData, contentID } = action;
  const currentToken = tokenData.access_token ? decodeToken(tokenData.access_token) : {};
  const { profile } = currentToken;

  try {
    const { result } = yield call(unityProfileService.getCurrentContentPositionByProfile, tokenData, contentID, profile);

    if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.fetchCurrentContentPositionByProfileSuccess(result));

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.fetchCurrentContentPositionByProfileError(errObj));
  }
}

function* createProfile(action) {
  const { payload, pinCodeValue } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));

  try {
    const [{ result }] = yield all([
      call(unityProfileService.createUserProfile, accessToken, payload),
      put(actions.fetchCreateProfileSuccess()),
      put(actions.setFormDisabledProfile(true))
    ]);

    if (!result || result.error) {
      throw new Error(result);
    }

    const { id: profileIdCreated } = result;

    if(pinCodeValue) {
      yield put(actions.addPinCodeProfile(profileIdCreated, pinCodeValue))
    }
    else {
      yield put(actions.loadSelectDataProfile());
    }

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('CREATE_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.fetchCreateProfileError(errObj));
  }
}

function* editProfile(action) {
  const { profileID, payload, pinCodeValue } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const profileDataFetch = yield select(state => selectors.selectProfileFormData(state));
  const { id, hasPIN } = profileDataFetch || {};
  const {maxRating: MaxRatingForm} = payload;
  const currentProfileIdSelected = yield select(state => selectors.selectCurrentProfile(state));


  try {
    const [{ result }] = yield all([
      call(unityProfileService.updateUserProfile, accessToken, profileID, payload),
      put(actions.fetchEditProfileSuccess()),
      put(actions.setFormDisabledProfile(true))
    ]);

    if (!result || result.error) {
      throw new Error(result);
    }

    if(currentProfileIdSelected !== id) {
      if(MaxRatingForm <= MAX_RATING_DEFAULT.KID && pinCodeValue) {
        yield put(actions.editPinCodeProfile(profileID, pinCodeValue));
      } else if(MaxRatingForm >= MAX_RATING_DEFAULT.ADULT) {
          yield put(actions.deletePinCodeProfile(profileID));
      }
    }
    yield put(actions.loadSelectDataProfile());

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('EDIT_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.fetchEditProfileError(errObj));
  }
}

function* deleteProfile(action) {
  const { profileID } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));

  try {
    const [{ result }] = yield all([
      call(unityProfileService.deleteUserProfile, accessToken, profileID),
      put(actions.fetchDeleteProfileSuccess()),
      put(actions.setFormDisabledProfile(true))
    ]);

     if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.loadSelectDataProfile());

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('DELETE_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.fetchDeleteProfileError(errObj));
  }
}

function* getProfileById(action) {
  const { profileID } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));

  try {
    const {result} = yield call(unityProfileService.getUserProfiles, accessToken, profileID);

     if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.fetchProfileByIdSuccess(result))

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('PROFILE_BY_ID_FETCH_ERROR: ', errObj);
    yield put(actions.fetchProfileByIdError(errObj));
  }
}

/**
 * Fetch unity available favorites for contents
 *
 * @param {*} action
 */
 function* getFavoritesByContent(action) {
  const { tokenData } = action;
  const currentToken = tokenData.access_token ? decodeToken(tokenData.access_token) : {};
  const { profile } = currentToken;

  try {

    const { result }  = yield call(unityProfileService.getFavoriteContentsByProfile, tokenData, profile);

    if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.fetchFavoriteContentByProfileSuccess(result));

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('FETCH_UNITY_FAVORITES_CONTENTS_ERROR: ', errObj);
    yield put(actions.fetchFavoriteContentByProfileError(errObj));
  }
}

function* addFavoriteContent(action) {
  const { contentID } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const profileID = yield select(state => selectors.selectCurrentProfile(state));

  try {
    const [{ result }] = yield all([
      call(unityProfileService.addFavoriteContentByProfile, accessToken, profileID, contentID),
      put(actions.addFavoriteContentByProfileSuccess())
    ]);

     if (!result || result.error) {
      throw new Error(result);
    }

    if (Object.keys(result).length === 0) {
      yield put(toggleProfilesModal(true));
    }
    else {
      yield put(actions.fetchFavoriteContentByProfile(accessToken, profileID))
    }


  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('PROFILE_BY_ID_FETCH_ERROR: ', errObj);
    yield put(actions.addFavoriteContentByProfileError(errObj));
  }
}

function* deleteFavoriteContent(action) {
  const { contentID } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const profileID = yield select(state => selectors.selectCurrentProfile(state));
  const selectFavoriresPrev = yield select(state => selectors.selectFavorires(state));

  try {

    const [{ result }] = yield all([
      call(unityProfileService.deleteFavoriteContentByProfile, accessToken, profileID, contentID),
      put(actions.deleteFavoriteContentByProfileSuccess())
    ]);

     if (!result || result.error) {
      throw new Error(result);
    }

    if (Object.keys(result).length === 0 && selectFavoriresPrev?.length > 1 ) {
      yield put(toggleProfilesModal(true));
    }
    else {
      yield put(actions.fetchFavoriteContentByProfile(accessToken, profileID))
    }

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('DELETE_FAVORITE_CONTENT_ERROR: ', errObj);
    yield put(actions.deleteFavoriteContentByProfileError(errObj));
    if(e?.code === 'PA-004') {
      yield put(toggleProfilesModal(true));
    }
  }
}

function* setPinCodeProfile(action) {
  const { targetProfile, pinCode } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const profileID = yield select(state => selectors.selectCurrentProfile(state));

  try {

    const [{ result }] = yield all([
      call(unityProfileService.addPinCodeProfile, accessToken, profileID, targetProfile, pinCode),
      put(actions.addPinCodeProfileSuccess()),
      put(actions.setFormDisabledProfile(true))
    ]);

     if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.loadSelectDataProfile());

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('ADD_PIN_CODE_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.addPinCodeProfileError(errObj));
  }
}

function* editPinCodeProfile(action) {
  const { targetProfile, pinCode } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const profileID = yield select(state => selectors.selectCurrentProfile(state));

  try {

    const [{ result }] = yield all([
      call(unityProfileService.addPinCodeProfile, accessToken, profileID, targetProfile, pinCode),
      put(actions.editPinCodeProfileSuccess()),
      put(actions.setFormDisabledProfile(true))
    ]);

     if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.loadSelectDataProfile());

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('EDIT_PIN_CODE_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.editPinCodeProfileError(errObj));
  }
}

function* deletePinCodeProfile(action) {
  const { targetProfile } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));

  try {

    const [{ result }] = yield all([
      call(unityProfileService.deletePinCodeProfile, accessToken, targetProfile),
      put(actions.deletePinCodeProfileSuccess()),
      put(actions.setFormDisabledProfile(true))
    ]);

     if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.loadSelectDataProfile());

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('DELETE_PIN_CODE_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.deletePinCodeProfileError(errObj));
  }
}

function* validatePinCodeProfile(action) {
  const { targetProfile, pinCode } = action;
  const accessToken = yield select(state => selectors.selectAccessToken(state));

  try {

    const { result } = yield  call(unityProfileService.validateCurrentPinCodeProfile, accessToken, targetProfile, pinCode);

     if (!result || result.error) {
      throw new Error(result);
    }

    yield put(actions.validatePinCodeProfileSuccess(result?.status))

    let validatePinCodeStatus = yield select((state) => selectors.selectValidatePinCodeStatus(state));

    if(validatePinCodeStatus) {
      let profileIDSelectedForValidation = yield select((state) => selectors.selectProfileIDSelectedForValidation(state));
      yield all([
          put(actions.setCurrentProfile(profileIDSelectedForValidation)),
          put(toggleProfilesModal(false)),
          put(changeAccountMenuStatus(false)),
          put(actions.enablePinCodeValidateModal(false, ''))
      ])
    }

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('VALIDATE_PIN_CODE_PROFILE_FETCH_ERROR: ', errObj);
    yield put(actions.validatePinCodeProfileError(errObj));
  }
}

function* loadSelectProfiles () {
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const profileCollection = yield select(state => selectors.selectProfileCollection(state));
  const profileID = yield select(state => selectors.selectCurrentProfile(state));
  const currentDataProfileSelected = getCurrentProfileData(profileCollection, profileID);

  yield put(actions.fetchProfiles(accessToken));
  yield take(types.PROFILES_FETCH_SUCCESS);
  yield put(actions.setFormDisabledProfile(false))

  const { max_rating } = currentDataProfileSelected || {};
  const isAdult = isAdminProfile(max_rating);

  if(!isAdult && max_rating) {
    yield all ([
      put(toggleProfilesModal(false)),
      put(changeAccountMenuStatus(false)),
      put(actions.changeProfileFormStatus(false))
    ])
  } else {
    yield put(actions.changeProfileFormStatus(false));
  }
}

function* updateProfileNotificationTopicToken () {
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const currentProfileIdSelected = yield select(state => selectors.selectCurrentProfile(state));
  const token = yield select(state => selectors.selectFireBaseToken(state));

  try {

    const { result } = yield call(unityProfileService.updateProfileNotificationTopicsToken, accessToken, currentProfileIdSelected, { firebaseToken: token });

    if (!result || result.error) {
      throw new Error(result);
    }

    put(actions.updateNotificationTopicsFirebaseTokenSuccess())

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_ERROR: ', errObj);
    yield put(actions.updateNotificationTopicsFirebaseTokenError(errObj));
  }
}

function* subscribeDefaultTopic () {
  const accessToken = yield select(state => selectors.selectAccessToken(state));
  const currentProfileIdSelected = yield select(state => selectors.selectCurrentProfile(state));
  const token = yield select(state => selectors.selectFireBaseToken(state));

  try {

    if(currentProfileIdSelected) {

      const { result } = yield call(unityProfileService.subscribeToTopic, accessToken, currentProfileIdSelected, DEFAULT_TOPIC.ID, DEFAULT_TOPIC.NAME,
          {
            expires:true,
            startDate: new Date(),
            firebaseToken: token
          }
        );

      if (!result || result.error) {
        throw new Error(result);
      }

      put(actions.subscribeToDefaultTopicSuccess());
    }

  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('SUBCRIBE_DEFAULT_TOPIC_ERROR: ', errObj);
    yield put(actions.subscribeToDefaultTopicError(errObj));
  }
}


function* saga() {
  yield takeLatest(types.AVATARS_FETCH, fetchAvatars);
  yield takeLatest(types.PROFILES_FETCH, getProfiles);
  yield takeLatest(types.SET_CURRENT_PROFILE_FETCH, setProfileToken);
  yield takeLatest(types.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH, getCurrentContentPositionByProfile);
  yield takeLatest(types.CREATE_PROFILE_FETCH, createProfile);
  yield takeLatest(types.EDIT_PROFILE_FETCH, editProfile);
  yield takeLatest(types.DELETE_PROFILE_FETCH, deleteProfile);
  yield takeLatest(types.PROFILE_BY_ID_FETCH, getProfileById);
  yield takeLatest(types.FAVORITES_CONTENTS_FETCH, getFavoritesByContent);
  yield takeLatest(types.ADD_FAVORITE_CONTENT_FETCH, addFavoriteContent);
  yield takeLatest(types.DELETE_FAVORITE_CONTENT_FETCH, deleteFavoriteContent);
  yield takeLatest(types.ADD_PIN_CODE_PROFILE_FETCH, setPinCodeProfile);
  yield takeLatest(types.EDIT_PIN_CODE_PROFILE_FETCH, editPinCodeProfile);
  yield takeLatest(types.DELETE_PIN_CODE_PROFILE_FETCH, deletePinCodeProfile);
  yield takeLatest(types.VALIDATE_PIN_CODE_PROFILE_FETCH, validatePinCodeProfile);
  yield takeLatest(types.LOAD_SELECT_PROFILES, loadSelectProfiles);
  yield takeLatest(types.UPDATE_PROFILE_NOTIFICATION_TOPICS_TOKEN_FETCH, updateProfileNotificationTopicToken);
  yield takeLatest(types.SUBSCRIBE_DEAFULT_TOPIC_FETCH, subscribeDefaultTopic);



}

export default saga;