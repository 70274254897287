import React from 'react';
import PropTypes from 'prop-types';
import { AccountMenu } from '@tbx/experience-widgets-lib';
import { ReactComponent as AccountLogo } from '../../static/images/person-circle.svg';

import './styles.scss';

//#region /////// TBX WIDGETS ///////
import {
  Imgcomponent
} from '@tbx/experience-widgets-lib';
import { MAX_RATING_DEFAULT } from '../../containers/ProfileManager/constants';
//#endregion

function DropdownUserAccount({
  avatars = [],
  profiles = [],
  currentAvatar,
  setOpenProfileMenu,
  handleOnSelectProfile,
  openProfileMenu, 
  profileOptionsProps,
  profileOperationProps,
  handleOpenMenuProfile,
  currentProfileSelected,
  isAdminProfileSelected,
  handleEnablePinCodeValidateModal
}) {
  return (
    <div className="dropdown-content__container" >
      <div className={`dropdown-content__container${currentAvatar ? '--avatar' : '--userLogoDefault'}`}>

        <button className='dropdown-content__container__openMenu' onClick={() => handleOpenMenuProfile()}>
          {currentAvatar ?
            <Imgcomponent src={currentAvatar.src} loading={'eager'} /> :
            <AccountLogo />}
        </button>


        <AccountMenu
          isRight={true}
          openProfileMenu={openProfileMenu}
          setOpenProfileMenu={setOpenProfileMenu}
          profiles={profiles}
          avatars={avatars}
          handleOnSelectProfile={handleOnSelectProfile}
          profileOperationProps={profileOperationProps}
          profileOptionsProps={profileOptionsProps}
          currentProfileSelected={currentProfileSelected}
          isAdminProfileSelected={isAdminProfileSelected}
          adultMaxRatingDefault={MAX_RATING_DEFAULT.ADULT}
        />
      </div>
    </div>
  )
};

DropdownUserAccount.propTypes = {
  avatars: PropTypes.array,
  profiles: PropTypes.array,
  currentAvatar: PropTypes.object,
  setOpenProfileMenu: PropTypes.func,
  handleOnSelectProfile: PropTypes.func,
  openProfileMenu: PropTypes.bool, 
  profileOptionsProps: PropTypes.array,
  handleOpenMenuProfile: PropTypes.func,
  currentProfileSelected: PropTypes.object,
  isAdminProfileSelected: PropTypes.bool
};

export default DropdownUserAccount;
