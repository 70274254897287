import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { setGoogleAnalyticEvents } from '../../utils/googleAnalyticUtils';
function Seo({title, contentTitle, description, image}) {

    React.useEffect (()=> {
        if({title}) {
            setGoogleAnalyticEvents({title});
        }
    }, [])
    
    return (
            <Helmet>
                {/* Primary Meta Tags  */}
                <title>{title}</title>
                <meta name="title" content={title} />
                <meta name="description" content={description} />

                {/* OpenGraph tags */}
                <meta name="og:url" content={window.location.href} />
                <meta name="og:title" content={contentTitle} />
                <meta name="og:description" content={description} />
                <meta name="og:image" content={image} />
                <meta name="og:type" content="article" />
                {/* Twitter Card tags */}
                <meta name="twitter:title" content={contentTitle} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:card" content="summary_large_image" />
                {/* General Tags */}
                <meta itemProp="image" content={image} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={contentTitle} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                {/* WHATSAPP */}
                <meta property="og:image" content={image} />
                <meta property="og:image:secure_url" content={image} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="300" />
                <meta property="og:image:height" content="300" />
            </Helmet>
    );
}

Seo.propTypes = {
    title: PropTypes.string, 
    contentTitle: PropTypes.string,
    description:PropTypes.string,
    image: PropTypes.string,
}

export default React.memo(Seo);