import { checkifPropertiesExist } from "./objectUtils";

/**
 * Verifies if an user is authenticated or if the content is free
 *
 * @param  {*}          authenticated  Prop to verify
 * @param  {*}          obj Object to verify
 * @return {Boolean}        
 */
 export const checkifHaveContentAccess = (authenticated, obj) => {
    return ((authenticated) || ( checkifPropertiesExist('free', obj).status && obj.free ));
};


