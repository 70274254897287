import {
  all,
  call,
  put,
  takeLatest,
  select,
  take
} from 'redux-saga/effects';
import {
  unityContentsService
} from '@tbx/experience-widgets-lib';

import * as actions from './actions';
import * as selectors from './selectors';
import { types } from './constants';
import { convertPath } from '../../../utils/pathRoutesUtils';

function* fetchContentWidget(action) {
  const {
    accessToken,
    contentID
  } = action;

  try {
    const { result } =  yield call(unityContentsService.getContentsById, accessToken, contentID);

      if (!result || result.error) {
        throw new Error(result.error);
      }
     
    return yield all([
      put(actions.loadWidgetSuccess(result))
    ]);
  } catch (e) {
    console.error('SECTION_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.loadWidgetError(e));
  }
}


function* startNextEpisode(action) {
  const {
    status,
    router,
    contentid,
    pathPlayer
  } = action;

  try {
    yield put (actions.startNextEpisodeSuccess(status));

    router.push(convertPath(pathPlayer,contentid));
  
  } catch (e) {
    console.error('SECTION_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.startNextEpisodeError(e));
  }
}

function* saga() {
  yield takeLatest(types.WIDGET_FETCH, fetchContentWidget);
  yield takeLatest(types.START_NEXT_EPISODE, startNextEpisode);
}

export default saga;