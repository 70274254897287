import React, {useRef, useEffect} from 'react';
import {
  unitySectionService
} from '@tbx/experience-widgets-lib';
import * as actions from './actions';
import { TIMEOUT_PARAMETER } from '../../constants/delayTime';
import { LIST_SOURCES_FOR_AUTOREFRESH, LIST_SOURCES_NAMES, MAX_ITEMS_TO_REQUEST_BY_LIST_SOURCE } from './constants';
import { contentItemsToShowInCarousel } from '../../utils/ContentUtils';
import { fetchFavoriteContentByProfile } from '../../containers/ProfileManager/actions';

export const fetchComponentData = async ({tokenData, widgetData, state, dispatch, dispatchRedux, authenticated, isLoadMoreDataInComponent= false}) => {

  const { componentItems, orderTypeSelected } = state;
  const { page } = componentItems || {};
  const { section:sectionID, id:widgetID, componentParameters } = widgetData || {};
  const { contentListDisplayItems, maxItems, listSource} = componentParameters || {};
  const maxItemsToRequest = (listSource === LIST_SOURCES_NAMES.BOOKMARKS) ? MAX_ITEMS_TO_REQUEST_BY_LIST_SOURCE.BOOKMARKS : contentItemsToShowInCarousel(maxItems, contentListDisplayItems);
  
  let filters = {pageSize: maxItemsToRequest};

  if(widgetData.componentType === 'gallery_wall') {
    filters ={...filters, orderType: orderTypeSelected, page: page?.nextPage}
  }

  try {
    const { result } = await unitySectionService.getSectionComponentItems(tokenData, sectionID, widgetID, filters);

    if (!areEquals(componentItems, result?.result)){

        if(isLoadMoreDataInComponent) {
          dispatch(actions.loadMoreDataInComponentSuccess(result));
        }
        else {
          dispatch(actions.loadComponentFetchSuccess(result));
        }

        if(listSource === "favorites" && authenticated) {
          dispatchRedux(fetchFavoriteContentByProfile(tokenData))
      }
    }

  } catch (e) {
    dispatch(actions.loadComponentFetchFailure(e));

  };
};

/**
 * useHook like hook effect
 *
 * @export
 */

export function useDidMount({tokenData, widgetData, state, dispatch, dispatchRedux, authenticated}) {

  React.useEffect(() => {
    if (widgetData.id && widgetData.section) {
      fetchComponentData({tokenData, widgetData, state, dispatch, dispatchRedux, authenticated}); 
    }
  }, [state.orderTypeSelected]);
}

/**
 * useAutoRefreshProcess like hook effect
 *
 * @export
 */

 export function useAutoRefreshProcess({tokenData, widgetData, state, dispatch, dispatchRedux, authenticated}) {
  const delay = TIMEOUT_PARAMETER.AUTO_REFRESH;
  const { componentParameters } = widgetData || {};
  const { listSource } = componentParameters || {};
 
  useInterval(() => {

    if(widgetData && authenticated && LIST_SOURCES_FOR_AUTOREFRESH.includes(listSource)){
      fetchComponentData({tokenData, widgetData, state, dispatch, dispatchRedux, authenticated}); 
    }

  }, delay);

}

/**
 * useInterval like hook effect
 *
 * @export
 */
export function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest function.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}
  

export function areEquals(contentCollection, result) {
    if(contentCollection.length !== result.length) {
      return false
    }
    const resultToContentCollection=result.map((item) => {
        return item.content ? item.content : item;
    })
    if(JSON.stringify(resultToContentCollection) === JSON.stringify(contentCollection)) {
      return true
    }
    return false
  
 }

 export function areEqualsx(contentCollection, result) {
  if(contentCollection.length !== result.length) {
    return false
  }
  const resultToContentCollection=result.map((item) => {
      return item.content ? item.content : item;
  })
  if(JSON.stringify(resultToContentCollection) === JSON.stringify(contentCollection)) {
    return true
  }
  return false

}