import React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';

import * as actions from './actions';
import { toastAdd, notificationModalCreate } from '../../App/actions';
import * as selectors from './selectors';

import { TITLES_MODAL } from './constants';
import { TOAST_TYPES } from '../../App/constants';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../customHooks/useRouter';
import { ERROR_CODE_FORMAT } from '../../../constants/errorCode';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import { isWindows } from 'react-device-detect';
import { CONTENT_TYPES } from '../../../constants/contentTypes';

const { CL001, CL002, CP006, CS011, CP015, CP037, CP137, SE003 } = ERROR_CODE_FORMAT;

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(content, unityToken, selectError, daysBefore, daysAfter) {
  const dispatch = useDispatch();
  const [contentCopy, setContentCopy] = React.useState();
  const selectedContent = useSelector(state => selectors.selectContent(state));
  const selectedEpgDataByIds = useSelector(state => selectors.selectEpgDataByIds(state));
  const [epgIdsState, setEpgIdsState] = React.useState([])

  React.useEffect(() => {
    if (JSON.stringify(contentCopy) !== JSON.stringify(content)) {
      setContentCopy(content);
      if (content?.length > 0 && !selectedContent) {
        const dateFrom = startOfDay(subDays(Date.now(), daysBefore))
        const dateTo = endOfDay(addDays(Date.now(), daysAfter));
        const epgIds = content.map((e) => e.epgId);
        if (JSON.stringify(epgIds) !== JSON.stringify(epgIdsState)) {
          setEpgIdsState(epgIds);
          dispatch(actions.fetchEPGDataByIds(
            unityToken,
            epgIds,
            dateFrom,
            dateTo
          ));
        }
      }
      if (selectError.code || selectError.message) {
        dispatch(actions.changeWidget(content));
      }
    }
  }, [content]);

  React.useEffect(() => {
    dispatch(actions.isPlayerEpgSection(true));
    return () => {
      dispatch(actions.isPlayerEpgSection(false));
    }
  }, [])


  React.useEffect(() => {
    if (!!selectedEpgDataByIds) {
      const copyContent = content.map((c, index) => {
        const resultFind = selectedEpgDataByIds.find((r) => {
          return c.epgId === r.epgId
        })
        if (!!resultFind?.epg) {
          return {
            ...content[index],
            epg: resultFind.epg
          }
        }
        return false;
      }).filter((bool) => !!bool);
      dispatch(actions.selectContent(copyContent[0], copyContent));
    }
  }, [selectedEpgDataByIds])

}

/**
 * usePlayerCallbackConfig like hook effect
 *
 * @export
 */
export function usePlayerCallbackConfig(playerCallbackState, PLAYEREPG_PLAYER_REF, PLAYEREPG_EPG_REF) {
  const router = useRouter();

  React.useEffect(() => {
    const playerCallbackContentType = playerCallbackState?.state?.contentType;
    const playerCallbackPathname = playerCallbackState?.state?.pathname;

    if (playerCallbackContentType && playerCallbackContentType !== CONTENT_TYPES.BROADCAST && !playerCallbackState.pictureInPictureState || !router.pathname?.includes(playerCallbackPathname)) {
      playerCallbackState.clearPlayerCallback();
    };
  }, []);

  React.useEffect(() => {
    if ("pictureInPictureEnabled" in document) {
      if (PLAYEREPG_PLAYER_REF?.current) {
        PLAYEREPG_PLAYER_REF.current.style.display = !playerCallbackState.pictureInPictureState ? 'block' : 'none';
      }
      if (PLAYEREPG_EPG_REF?.current) {
        if (playerCallbackState.pictureInPictureState) {
          PLAYEREPG_EPG_REF.current.classList.add('playerEpgSectionEpg_fullHeight');
        } else {
          PLAYEREPG_EPG_REF.current.classList.remove('playerEpgSectionEpg_fullHeight');
        }
      }
    }
  }, [playerCallbackState.pictureInPictureState, PLAYEREPG_PLAYER_REF, PLAYEREPG_EPG_REF?.current]);

  React.useEffect(() => {
    const changePictureInPicture = (bool) => {
      if (playerCallbackState.state.pathname !== window.location.pathname && !window.location.pathname.includes('/player/')) {
        router.push(playerCallbackState.state.pathname);
      }
      if (window.location.pathname.includes('/player/') && playerCallbackState?.state) {
        playerCallbackState.state.playerVideoJs().pause();
      }
    }
    playerCallbackState.setPictureInPicturePlayerCallbackHandler(changePictureInPicture);
  }, [playerCallbackState.state]);
}

/**
 * useChangeWidget like hook effect
 *
 * @export
 */
export function useSetContent() {
  const dispatch = useDispatch();
  const [content, setContent] = React.useState(false);
  const [contentState, setContentState] = React.useState(null);
  const selectedContent = useSelector(state => selectors.selectContent(state));

  React.useEffect(() => {
    if (content && (selectedContent?.epgId !== content.epgId || (selectedContent.startTime !== content.startTime && selectedContent.endTime !== content.endTime))) {
      dispatch(actions.changeWidget(content));
      setContentState(content)
    }
  }, [content])

  return [setContent, contentState]
}


/**
 * useLoadWidgetError like hook effect
 *
 * @export
 */
export function useLoadWidgetError(playerScriptsError, selectError) {
  const dispatch = useDispatch();
  const [errorHelp, setErrorHelp] = React.useState(null)

  React.useEffect(() => {
    if (!!playerScriptsError) setErrorHelp(playerScriptsError);
  }, [playerScriptsError])

  React.useEffect(() => {
    if (errorHelp && JSON.stringify(selectError) !== JSON.stringify(errorHelp)) {
      dispatch(actions.loadWidgetError(errorHelp));
    }
  }, [errorHelp, selectError])


  return (error) => setErrorHelp(error);
}

/**
 * useToastAdd like hook effect
 *
 * @export
 */
export function useToastAdd() {
  const dispatch = useDispatch();
  const { t } = useTranslation()

  return (error) => {
    const toast = {
      ["4"]: {
        toastType: TOAST_TYPES.ERROR,
        title: t(TITLES_MODAL["4"].title),
        message: t(TITLES_MODAL["4"].message),
        error: error?.error ? error?.error : error,
        temporary: true,
      },
      [SE003]: {
        toastType: TOAST_TYPES.ERROR,
        title: t(TITLES_MODAL[SE003].title),
        message: t(TITLES_MODAL[SE003].message),
        error: error?.error ? error?.error : error,
        temporary: true,
      },
      ["DEFAULT"]: {
        toastType: TOAST_TYPES.ERROR,
        title: t(TITLES_MODAL["DEFAULT"].title),
        message: t(TITLES_MODAL["DEFAULT"].message),
        error: error?.error ? error?.error : error,
      }
    }

    const toastResult = toast[error?.code] || toast['DEFAULT']
    dispatch(toastAdd(toastResult))
  }
}


/**
 * useNotificationModalCreate like hook effect
 *
 * @export
 */
export function useNotificationModalCreate(selectedContent, packageUpgradeMessages, PATHS) {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const router = useRouter();

  return (error) => {
    const { origin } = window.location;


    function CS011ObjectFunction() {
      const htmlCS011 = packageUpgradeMessages?.find((r) => {
        return (r.urn === selectedContent?.urn && !!r.url)
      })

      if (!!htmlCS011) {
        return {
          htmlText: htmlCS011.text,
          firstButton: {
            text: t(TITLES_MODAL[CS011].firstButton),
            handlerFunction: () => window.open(htmlCS011.url, htmlCS011.target),
          },
          secondButton: {
            text: t(TITLES_MODAL[CS011].secondButton)
          },
          exitButton: false,
        }
      }
      return {};
    }

    const modal = {
      [CL001]: {
        title: t(TITLES_MODAL[CL001].title),
        message: t(TITLES_MODAL[CL001].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CL001].firstButton),
          handlerFunction: () => alert('Check device')
        },
        secondButton: {
          text: t(TITLES_MODAL[CL001].secondButton),
        },
        className: 'playerModal-' + error?.code,
      },
      [CL002]: {
        title: t(TITLES_MODAL[CL002].title),
        message: t(TITLES_MODAL[CL002].message),
        error: error,
        logoImg: false,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CL002].firstButton),
          handlerFunction: () => {
            if (window.navigator.onLine) window.location.reload();
          }

        },
        className: 'playerModal-' + error?.code,
      },
      [CP006]: {
        title: t(TITLES_MODAL[CP006].title),
        message: t(TITLES_MODAL[CP006].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP006].firstButton)
        },
        className: 'playerModal-' + error?.code,
      },
      [CP015]: {
        title: t(TITLES_MODAL[CP015].title),
        message: t(TITLES_MODAL[CP015].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP015].firstButton),
          handlerFunction: () => router.push(PATHS.logout, { returnURL: origin + '/home' }),
        },
        className: 'playerModal-' + error?.code,
      },
      [CP037]: {
        title: t(TITLES_MODAL[CP037].title),
        message: t(TITLES_MODAL[CP037].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP037].firstButton),
          handlerFunction: () => router.push(PATHS.logout, { returnURL: origin + '/home' }),
        },
        className: 'playerModal-' + error?.code,
      },
      [CP137]: {
        title: t(TITLES_MODAL[CP137].title),
        message: t(TITLES_MODAL[CP137].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP137].firstButton),
          handlerFunction: () => router.push(PATHS.logout, { returnURL: origin + '/home' }),
        },
        className: 'playerModal-' + error?.code,
      },
      [CS011]: {
        title: t(TITLES_MODAL[CS011].title),
        message: t(TITLES_MODAL[CS011].message),
        error: error?.error || error,
        logoImg: true,
        exitButton: true,
        ...CS011ObjectFunction()
      },
    }

    dispatch(notificationModalCreate(modal[error?.code]))
  }
}
