import React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';

import config from '../../config';

//#region /////// COMPONENTS ///////
import AppSectionRouter from '../AppSectionRouter';
import LandingLoader from '../../components/LandingLoader';
import Modal from '../../components/Modal';
import ProfileManager from '../ProfileManager';
import Toast from '../../components/Toast';
//#endregion

import * as hooks from './hooks';
import * as selectors from './selectors';
import HandleModal from '../../components/NotificationModal';
import NotificationModal from '../../components/NotificationModal';
import classNames from '../../utils/classnameUtils';
import { HandleError } from '../../components/HandleError';
import { onMessageListener } from '../../firebase';
import { toastAdd } from './actions';
import { isDesktop } from 'react-device-detect';
import DownloadApp from '../../components/DownloadApp';
import useAnalytics from '../../googleAnalytics';
import { FatalErrorBoundary } from '../../HOCs/WithErrorBoundary';

function App({ history }) {
  const { ready } = useTranslation();
  const dispatch = useDispatch();
  //#region /////// SELECTORS ///////
  const accessToken = useSelector(state => selectors.selectAccessToken(state));
  const error = useSelector(state => selectors.selectError(state));
  const isFetching = useSelector(state => selectors.selectIsFetching(state));
  const togglePorfilesModal = useSelector(state => selectors.selectToggleProfilesModal(state));
  const notificationModal = useSelector(state => selectors.selectNotificationModal(state));
  const PATHS = useSelector(state => selectors.selectPathList(state));
  //#endregion
  const showApp = ready && !isFetching && !!accessToken.access_token;
  //#region /////// HOOK EFFECTS ///////
  useAnalytics();
  hooks.useDidMount(config, accessToken);
  hooks.useProfiles(accessToken);
  hooks.useCurrentProfileValidation(accessToken);
  hooks.useUserLogout();
  hooks.useNewRelicAttributes(accessToken);
  hooks.useRatingImages(accessToken);
  hooks.useIdpsContentProvider();
  hooks.useCountries();
  ////#endregion

  // INFO: En caso de no tener la configuracion requerida de entorno
  // o unity devuelve error en el endpoint inicial para obtener token.
  if (!config || (error.code && error.message)) {
    return (
      <HandleError
        error={error?.code ? error : { error: 'FATAL: Startup configuration error.' }}
        navBar={false}
      />
    );
  }

  if (!showApp) return (<LandingLoader />);
  if (!isDesktop && window.location.pathname !== PATHS.active) return (<DownloadApp/>);



  onMessageListener().then(payload => {
    dispatch(toastAdd({ title: payload.notification.title, message: payload.notification.body }))
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

  return (
    <React.Fragment>
      <AppSectionRouter history={history} />
      <Toast />
      <Modal
        show={togglePorfilesModal || notificationModal}
        classNameCustom={classNames(togglePorfilesModal ? 'tbxContentModalProfile' : '', !notificationModal ? 'tbxNotificationModal' : '')}
      >
        {togglePorfilesModal && <ProfileManager history={history} />
          || notificationModal && <NotificationModal notificationModal={notificationModal} />
        }
      </Modal>
    </React.Fragment>
  );
}

export default FatalErrorBoundary(App);
