import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function Avatar({
    avatarData,
    handleAvatarEdit,
    handleAvatarOption,
    className
}) {

  let avatarStyle = {};
  let avatarURI = avatarData?.src;

  if (avatarURI) {
      avatarStyle = {
      backgroundSize: `${avatarURI ? 'cover' : 'contain'}`,
      backgroundImage: `url(${avatarURI})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#FFFFFF1A'
    };
  }

  return (
    <div className={className}>
      <div
        className={`${className}-item_icon ${!avatarURI && `${className}-item_iconfallback`} ${handleAvatarEdit ? `${className}-selectable`:''}`}
        style={avatarStyle}
        onClick={handleAvatarEdit ? ()=> handleAvatarEdit(avatarData.id): undefined}
      >
      {
        handleAvatarOption &&
        <a className={`${className}__edit_icon`} onClick={()=> handleAvatarOption()}>
          <i className="bi bi-pencil-fill"></i>
        </a>
      }
      </div>

    </div>   
  );
}

Avatar.propTypes = {
  avatarData: PropTypes.object,
  handleAvatarEdit: PropTypes.func,
  handleAvatarOption: PropTypes.func
};

export default React.memo(Avatar);