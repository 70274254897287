import React from 'react';
import {
  useDispatch
} from 'react-redux';

import * as actions from './actions';
import { changeNavBarBool } from '../../Navbar/actions';
import { toastAdd, notificationModalCreate } from '../../App/actions';

import { TITLES_MODAL } from './constants';
import { TOAST_TYPES } from '../../App/constants';
import { useTranslation } from 'react-i18next';
import useRouter from '../../../customHooks/useRouter';
import { ERROR_CODE_FORMAT } from '../../../constants/errorCode';
import { CONTENT_TYPES } from '../../../constants/contentTypes';
import { convertPath, isPath } from '../../../utils/pathRoutesUtils';

const { CL001, CL002, CP006, CS011, CP015, CP037, CP137, SE003, GE001 } = ERROR_CODE_FORMAT;


/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(accessToken, contentID) {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.loadWidget(accessToken, contentID));
    dispatch(changeNavBarBool(false))
    return () => {
      dispatch(actions.clearStates());
    }

  }, [contentID]);
}

/**
 * usePlayerCallbackConfig like hook effect
 *
 * @export
 */
 export function usePlayerCallbackConfig(playerCallbackState, contentID, selectedContent, PATHS) {
  const router = useRouter();

  React.useEffect(() => {
    if(playerCallbackState?.state?.playerID){
      const playerCallback = playerCallbackState.state.playerVideoJs();
      const playerCallbackID1 = playerCallback?.tbx?.playerConfig?.contentId
      const playerCallbackID2 = playerCallback?.tbx?.content?.id;
      if(playerCallbackState.pictureInPictureState) playerCallback.exitPictureInPicture();
      if(playerCallbackID1 !== contentID && playerCallbackID2 !== contentID){
        playerCallbackState.clearPlayerCallback();
      }
    }
  }, [contentID]);
  
  React.useEffect(() => {
    const changePictureInPicture = (bool) => {
      const playerCallback = playerCallbackState.state;
      if (!bool && playerCallback?.pathname && window.location.pathname !== playerCallback.pathname) {
        if (isPath(PATHS.player, window.location.pathname)) {
          playerCallbackState.clearPlayerCallback();
        } else {
          return router.push(playerCallback.pathname);
        }
      }
      if(bool){
        let contentId = (selectedContent?.contentType === CONTENT_TYPES.EPISODE) ? selectedContent?.serie.id : selectedContent?.id;
        
        router.push(convertPath(PATHS.content ,contentId));
      }
    }

    playerCallbackState.setPictureInPicturePlayerCallbackHandler(changePictureInPicture);
  }, [playerCallbackState.state, selectedContent]);
}

/**
 * useLoadWidgetError like hook effect
 *
 * @export
 */
 export function useLoadWidgetError(playerScriptsError,contentID) {
  const dispatch = useDispatch();

  React.useEffect(()=>{
    if(!!playerScriptsError){
      dispatch(actions.loadWidgetError(playerScriptsError));
    }
  },[playerScriptsError])

  if ((!contentID || typeof contentID === 'undefined')) {
    dispatch(actions.loadWidgetError({
      code: SE003,
    }));
  }

  return (error)=>{
    dispatch(actions.loadWidgetError(error));
  }
}

/**
 * useToastAdd like hook effect
 *
 * @export
 */
export function useToastAdd() {
  const dispatch = useDispatch();
  const { t } = useTranslation()

  return (error) => {
    const toast = {
      ["4"]: {
        toastType: TOAST_TYPES.ERROR,
        title: t(TITLES_MODAL["4"].title),
        message: t(TITLES_MODAL["4"].message),
        error: error?.error ? error?.error  : error,
        temporary: true,
      },
      [SE003]: {
        toastType: TOAST_TYPES.ERROR,
        title: t(TITLES_MODAL[SE003].title),
        message: t(TITLES_MODAL[SE003].message),
        error: error?.error ? error?.error  : error,
        temporary: true,
      },
      ["DEFAULT"]: {
        toastType: TOAST_TYPES.ERROR,
        title: t(TITLES_MODAL["DEFAULT"].title),
        message: t(TITLES_MODAL["DEFAULT"].message),
        error: error?.error ? error?.error  : error,
      }
    }

    const toastResult = toast[error?.code] || toast['DEFAULT']
    dispatch(toastAdd(toastResult))
  }
}


/**
 * useNotificationModalCreate like hook effect
 *
 * @export
 */
export function useNotificationModalCreate(selectedContent, packageUpgradeMessages , PATHS) {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const router = useRouter();

  return (error) => {
    const { origin } = window.location;


    function CS011ObjectFunction(){
      const htmlCS011 = packageUpgradeMessages?.find((r) => {
        return (r.urn === selectedContent?.urn && !!r.url)
      })
  
      if (!!htmlCS011) {
        return {
          htmlText: htmlCS011.text,
          firstButton: {
            text: t(TITLES_MODAL[CS011].firstButton),
            handlerFunction:  ()=>window.open(htmlCS011.url,htmlCS011.target),
          },
          secondButton: {
            text: t(TITLES_MODAL[CS011].secondButton)
          },
          exitButton: false,
        }
      }
        return {};
    }

    const modal = {
      [CL001]: {
        title: t(TITLES_MODAL[CL001].title),
        message: t(TITLES_MODAL[CL001].message),
        error: error,
        logoImg: false,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CL001].firstButton)
        },
        className: 'playerModal-' + error?.code,
      },
      [CL002]: {
        title: t(TITLES_MODAL[CL002].title),
        message: t(TITLES_MODAL[CL002].message),
        error: error,
        logoImg: false,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CL002].firstButton),
          handlerFunction: () => {
            if (window.navigator.onLine) window.location.reload();
          }

        },
        className: 'playerModal-' + error?.code,
      },
      [CP006]: {
        title: t(TITLES_MODAL[CP006].title),
        message: t(TITLES_MODAL[CP006].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP006].firstButton),
          handlerFunction: () => router.push(PATHS.logout, { returnURL: origin + '/home' }),
        },
        className: 'playerModal-' + error?.code,
      },
      [CP015]: {
        title: t(TITLES_MODAL[CP015].title),
        message: t(TITLES_MODAL[CP015].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP015].firstButton),
          handlerFunction: () => router.push(PATHS.logout, { returnURL: origin + '/home' }),
        },
        className: 'playerModal-' + error?.code,
      },
      [CP037]: {
        title: t(TITLES_MODAL[CP037].title),
        message: t(TITLES_MODAL[CP037].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP037].firstButton),
          handlerFunction: () => router.push(PATHS.logout, { returnURL: origin + '/home' }),
        },
        className: 'playerModal-' + error?.code,
      },
      [CP137]: {
        title: t(TITLES_MODAL[CP137].title),
        message: t(TITLES_MODAL[CP137].message),
        error: error,
        logoImg: true,
        errorCode: true,
        firstButton: {
          text: t(TITLES_MODAL[CP137].firstButton),
          handlerFunction: () => router.push(PATHS.logout, { returnURL: origin + '/home' }),
        },
        className: 'playerModal-' + error?.code,
      },
      [GE001]: {
        title: t(TITLES_MODAL[GE001].title),
        message: t(TITLES_MODAL[GE001].message),
        error: error,
        logoImg: true,
        exitButton: true,
        className: 'playerModal-' + error?.code,
      },
      [CS011]: {
        title: t(TITLES_MODAL[CS011].title),
        message: t(TITLES_MODAL[CS011].message),
        error: error?.error || error,
        logoImg: true,
        exitButton: true,
        ...CS011ObjectFunction()
      },
    }

    dispatch(notificationModalCreate(modal[error?.code]))
  }
}
