
export const types = {
  SEARCH_CONTENTS: 'SearchSection/SEARCH_CONTENTS',
  SEARCH_CONTENTS_FAILURE:  'SearchSection/SEARCH_CONTENTS_FAILURE',
  SEARCH_CONTENTS_SUCCESS: 'SearchSection/SEARCH_CONTENTS_SUCCESS',
  SEARCH_INPUT_CHANGE: 'SearchSection/SEARCH_INPUT_CHANGE',
  SEARCH_INPUT_CLEAR: 'SearchSection/SEARCH_INPUT_CLEAR',
  SEARCH_LOAD_MORE_CONTENTS: 'SearchSection/LOAD_MORE_CONTENTS'
};

export const DEFAULT_SEARCH_FILTERS = {
  contentType: 'MOVIE,SERIE'
};

export const TITLES = ['Live','Our suggestions', 'Search results', 'Available in'];
