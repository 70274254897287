
export const types = {
  CONTENT_SELECTED: 'PlayerEpgSection/CONTENT_SELECTED',
  WIDGET_FETCH: 'PlayerEpgSection/WIDGET_FETCH',
  WIDGET_FETCH_FAILURE: 'PlayerEpgSection/WIDGET_FETCH_FAILURE',
  FETCH_EPG_DATA_BY_IDS: 'PlayerEpgSection/FETCH_EPG_DATA_BY_IDS',
  EPG_DATA_BY_IDS_ERROR: 'PlayerEpgSection/EPG_DATA_BY_IDS_FETCH_ERROR',
  EPG_DATA_BY_IDS_SUCCESS: 'PlayerEpgSection/EPG_DATA_BY_IDS_FETCH_SUCCESS',
  WIDGET_FETCH_SUCCESS: 'PlayerEpgSection/WIDGET_FETCH_SUCCESS',
  IS_PLAYEREPG_SECTION: 'PlayerEpgSection/IS_PLAYEREPG_SECTION',
  WIDGET_CHANGE: 'PlayerEpgSection/WIDGET_CHANGE',
  CLEAR_STATES: 'PlayerEpgSection/CLEAR_STATES',
  CLEAR_ERRORS: 'PlayerEpgSection/CLEAR_ERRORS',
  LOAD_MORE_DATA_EPG_GRID: 'PlayerEpgSection/LOAD_MORE_DATA_EPG_GRID'
};


export const TITLES_MODAL = {
  "CL-001": {
    title: '¡Sorry!',
    message:"Concurrence denied by server.",
  },
  "CL-002": {
    title: 'VIDEO PLAYER',
    message:"No connection to internet.",
  },
  "CP-006": {
    title: 'VIDEO PLAYER',
    message: "User disconnected.",
  },
  "CS-011": {
    title: "¡Sorry!",
    message: "You do not have the necessary permissions to view the selected content.",
  },
  "SE-003": {
    title: 'VIDEO PLAYER',
    message: "Warning: Sorry, we are experiencing technical issues. Please try again later."
  },
  "CP-015": {
    title: "VIDEO PLAYER",
    message: "An error has occurred on the site: CP-015. Please, try again.",
  },
  "CP-037": {
    title: "You are not logged in",
    message: "Try to login again",
  },
  "CP-137": {
    title: "You are not logged in",
    message: "Try to login again",
  },
  "GE-001": {
    title: "Ahgggg! Huston we have a problem...",
    message: "This content is  no longer available on the actual region at this moment",
  },
  "4":{
    title: "VIDEO PLAYER",
    message: "Warning: Sorry, we are experiencing technical issues. Please try again later.",
  },
  "DEFAULT": {
    title: 'VIDEO PLAYER',
    message: "Warning: Sorry, we are experiencing technical issues. Please try again later."
  }

}


export const TITLES = [
'Next episode in',
'Season',
'Episode',
'Seconds',
'Audio',
'Subtitles',
'Not available',
'Content not available',
'TODAY',
'GO BACK TO LIVE',
'SEE CHANNEL LIVE', 
'TO SEE AGAIN',
'LIVE', 
"Sign In",
"You do not have access to this content",
"Guide"
];

export const LIVE_PLAYER = 'Live';