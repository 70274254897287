import React from 'react';
import {
  useSelector
} from 'react-redux';

//#region ///// COMPONENTS /////
import ProfileOption from '../../components/Profile/ProfileOption';
import AvatarOption from '../../components/AvatarOption';
//#endregion

import {
  Imgcomponent
} from '@tbx/experience-widgets-lib';

import * as hooks from './hooks';
import * as selectors from './selectors';
import ProfileForm from '../../components/Profile/ProfileForm';
import Avatar from '../../components/Avatar';
import ClientLogo from '../../static/images/logo.svg';
import { countProfileTypes, getAvatarInCollectionById, getCurrentProfileData, getProfilesWithOutDefault } from '../../utils/ProfileUtils';
import './styles.scss';
import { selectNotificationModal } from '../App/selectors';
import NotificationModal from '../../components/NotificationModal';
import { NUMBER_FIELDS_PIN } from './constants';
import { Button } from 'react-bootstrap';
import { PinCodeInput } from '@tbx/experience-widgets-lib';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../customHooks/usePrevious';
import LoadingSpinner from '../../components/LoadingSpinner';
import ProfilePinCode from '../../components/Profile/ProfilePinCode';

function ProfileManager(props) {

  const { history } = props;
  const { t } = useTranslation();
  //#region /////// SELECTORS ///////
  const avatarCollection = useSelector(state => selectors.selectAvatarCollection(state));
  const isFetching = useSelector(state => selectors.selectIsFetching(state));
  const profileCollection = useSelector(state => selectors.selectProfileCollection(state));
  const { maxProfiles, suggestedProfileRatingLevel } = useSelector(state => selectors.selectAppSettings(state));
  const profileDataFetch = useSelector(state => selectors.selectProfileFormData(state));
  const profileformStatus = useSelector(state => selectors.selectProfileFormStatus(state));
  const accessToken = useSelector(state => selectors.selectAccessToken(state));
  const enableProfileOperations = useSelector(state => selectors.selectEnableProfileOperations(state));
  const profileSelected = useSelector(state => selectors.selectProfileSelected(state));
  const isAddFormMode = useSelector(state => selectors.selectFormModeProfile(state));
  const notificationModal = useSelector(state => selectNotificationModal(state));
  const enablePinCodeValidationModal = useSelector(state => selectors.selectEnablePinCodeValidationModal(state));
  const validatePinCodeStatus = useSelector(state => selectors.selectValidatePinCodeStatus(state));
  const disabledForm = useSelector(state => selectors.selectFormDisabledStatus(state));;
  //#endregionse

  const currentDataProfileSelected = getCurrentProfileData(profileCollection, profileSelected);

  //#region ///// Hook Effects /////
  hooks.useUnityProfiles(profileformStatus);
  const {
    enableAvatarOption,
    profileDataForm,
    pinCodeValue,
    handleOnSelectProfile,
    handleAvatarEdit,
    handleOnChangeProfileNameForm,
    handleCancel,
    handleSubmit,
    handleMaxRatingToggle,
    handleProfileTypeSelected,
    handleEnableAdultContentToggle,
    handleOnAddProfile,
    handleOnEditProfile,
    handleConfirmDeleteProfile,
    handleAvatarOption,
    handleAvatarBackButton,
    handlePinCodeChange,
    handleExitPinCodeModal,
    handleValidatePinCodeProfile,
    handlePinCodeSubmit,
    handlePinCodeCancel,
    handleDeletePinCode,
    handleProfilePinCodeLogout
  } = hooks.useContentProfile(profileDataFetch, profileformStatus, isAddFormMode, currentDataProfileSelected, history);
  //#endregion

  const { props: profileProps } = profileDataForm;
  const { avatarId } = profileProps;
  const profileCollectionSize = profileCollection?.length;
  const avatarSelected = getAvatarInCollectionById(avatarCollection, avatarId);
  const profilesWithoutDefault = getProfilesWithOutDefault(profileCollection);

  if (notificationModal) {
    return <NotificationModal notificationModal={notificationModal} />
  }

  return (
    <section className="profile-manager">
      <header className="profile-manager_header">
        {enableAvatarOption && (
          <div className="profile-manager_header__button">
            <Button size="sm" className="avatarOption_backButtom" onClick={(e) => handleAvatarBackButton(e)}>
              <i className="bi bi-arrow-left-short"></i>
            </Button>
          </div>
        )}
      </header>

      <div className={`profile-manager_animation ${disabledForm ? 'disabledForm' : ''}`}>
        {
          isFetching ?
            <div className={'profile-manager_container_loadingSpinner'}>
            <LoadingSpinner className={'profile-manager_loadingSpinner'}/>
            </div>
            :
            <React.Fragment>
              {

                enablePinCodeValidationModal ?
                <div className="profile-manager_form">
                  <ProfilePinCode
                    handlePinCodeChange={handlePinCodeChange}
                    handlePinCodeSubmit={handlePinCodeSubmit}
                    handlePinCodeCancel={handlePinCodeCancel}
                    isFetching={isFetching}
                    validatePinCodeStatus={validatePinCodeStatus}
                    pinCodeValue={pinCodeValue}
                    handleProfilePinCodeLogout={handleProfilePinCodeLogout}
                    />
                  </div>
                :
                enableAvatarOption ?
                  <AvatarOption
                    avatarCollection={avatarCollection}
                    handleAvatarEdit={handleAvatarEdit}
                    handleAvatarBackButton={handleAvatarBackButton}
                  />
                  :
                  !profileformStatus ?
                    <ProfileOption
                      handleOnAddProfile={handleOnAddProfile}
                      handleOnEditProfile={handleOnEditProfile}
                      handleConfirmDeleteProfile={handleConfirmDeleteProfile}
                      handleOnSelectProfile={handleOnSelectProfile}
                      avatarCollection={avatarCollection}
                      profileCollection={profileCollection}
                      maxProfiles={maxProfiles}
                      enableProfileOperations={enableProfileOperations}
                      currentDataProfileSelected={currentDataProfileSelected}
                      profileCollectionSize={profileCollectionSize}
                      profilesWithoutDefault={profilesWithoutDefault}
                    />
                    :
                    <div className="profile-manager_form">
                      <div>
                        <h1 className="profile-manager__title">{isAddFormMode ? t('Add profile') : t('Edit profile')}</h1>
                      </div>
                      <Avatar
                        avatarData={avatarId ? avatarSelected : avatarCollection[0]}
                        handleAvatarOption={handleAvatarOption}
                        className={'profileManagerForm_avatar'}
                      />
                      <ProfileForm
                        isAddMode={isAddFormMode}
                        handleOnChangeProfileNameForm={(e) => handleOnChangeProfileNameForm(e)}
                        handleCancel={(e) => handleCancel(e)}
                        handleSubmit={(e) => handleSubmit(e)}
                        handleProfileTypeSelected={handleProfileTypeSelected}
                        profileDataForm={profileDataForm}
                        currentDataProfileSelected={currentDataProfileSelected}
                        profileCollectionSize={profileCollectionSize}
                        handlePinCodeChange={handlePinCodeChange}
                        handleDeletePinCode={handleDeletePinCode}
                        pinCodeValue={pinCodeValue}
                        profileCounts={countProfileTypes(profileCollection)}
                      />
                    </div>
              }

            </React.Fragment>
        }
        {/* <button className="profile-manager_editbutton">
          {t('Manage Profiles')}
        </button> */}
      </div>
    </section>
  );
}

export default ProfileManager;
