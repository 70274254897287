import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './config/i18n';
import App from './containers/App';
//import config from './config';
import LandingLoader from './components/LandingLoader';
import reportWebVitals from './reportWebVitals';
import store, { history, persistor } from './state/store';

import './static/sass/style.scss';

// INFO: Para load de datos con la carga de lo persistido en el store:
//const handleOnBeforeLift = async (store) => {
//};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={<LandingLoader />}
        //onBeforeLift={() => handleOnBeforeLift(store)}
        persistor={persistor}
      >
        <App history={history} />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
