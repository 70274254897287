import React from "react";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import queryString from "query-string";

// Hook
export default function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return React.useMemo(() => {
    return {
      // For convenience add push(), replace(), pathname at top level
      push: (string,obj={})=>{
        history.push(string,{...obj,'oneBack':{...match,'key':location.key}})
      },
      goBack: history.goBack,
      goForward: history.goForward,
      replace: (string,obj={})=>{
        history.replace(string,{...obj,'oneBack':{...match,'key':location.key}})
      },
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
    };
  }, [params, match, location, history]);
}