import React from 'react';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import './styles.scss';
import { selectToastData } from '../../containers/App/selectors'
import { toastDelete } from '../../containers/App/actions';

import ToastReact from 'react-bootstrap/Toast';
import { TOAST_TYPES } from '../../containers/App/constants';
import { useTranslation } from 'react-i18next';


function Toast() {
    //#region ////// SELECTORS ///////
        const toastData = useSelector(state => selectToastData(state));
    //#endregion

    return (
        <React.Fragment>
            {toastData.length > 0 &&
                <div className='tbxToastContainer'
                style={{
                    position: 'fixed',
                    top: '80px',
                    right: '48px',
                    left: 'auto',
                }}>
                    {toastData.map((data) => <ToastComponent data={data} key={'ToastComponent_'+data.key}/>)}
            </div>}
        </React.Fragment>
    );
}

const ToastComponent = ({data})=>{
    const {key,message,temporary,time,title,toastType,error} = data;
    const dispatch = useDispatch()
    const [show, setShow] = React.useState(true);
    const {t} = useTranslation();


    const toggleShow = () => {
        setShow(false)
        dispatch(toastDelete(key))
    }
    
    const icono = {
        [TOAST_TYPES.WARNING]: <i className="bi bi-exclamation-triangle-fill" fill={'red'}></i>,
        [TOAST_TYPES.ERROR]: <i className="bi bi-exclamation-triangle-fill" fill={'red'}></i>,
        [TOAST_TYPES.INFO]: <i className="bi bi-info-circle-fill" fill={'yellow'}></i>,
    }

    return (
        <ToastReact className={'tbxToastComponent'} show={show} onClose={toggleShow} delay={time} autohide={temporary} animation={false}>
            <ToastReact.Header className={'tbxToastHeader'}>
                <div className={'tbxToastIcon'}>
                    {icono[toastType]}
                </div>
                <strong className={'tbxToastTitle mr-auto'}>{t(title)}</strong>
            </ToastReact.Header>
            <ToastReact.Body className={'tbxToastBody'}>
            <text className={'tbxToastMessage'}>{t(message)}</text>
            {!!error?.code && <div className={'tbxToastCode'}>{'Code error: ' + error.code}</div>}
            </ToastReact.Body>
        </ToastReact>)
}



export default Toast;