import React from 'react';
import PropTypes from 'prop-types';

//#region ///// COMPONENTS /////
import ProfileItem from '../ProfileItem';
//#endregion
import './styles.scss';

function ProfilesList({
  avatarCollection = [],
  profileCollection = [],
  onClickItemHandler,
  handleOnEditProfile,
  handleConfirmDeleteProfile,
  enableProfileOperations,
  currentDataProfileSelected
}) {


  return (
    <React.Fragment>
      {
        profileCollection.map((item) => {
          const avatar = avatarCollection?.find((a) => a.id === item.props.avatarId);

          return (
            <ProfileItem
              avatarURI={avatar && avatar.src}
              key={`profileitem-${item.id}`}
              name={item.name}
              onClickHandler={() => onClickItemHandler(item.id)}
              handleOnEditProfile={handleOnEditProfile}
              handleConfirmDeleteProfile={handleConfirmDeleteProfile}
              profileData={item}
              profileCollection={profileCollection}
              enableProfileOperations={enableProfileOperations}
              currentDataProfileSelected={currentDataProfileSelected}
            />
          )
        })
      }
    </React.Fragment>
  );
}

ProfilesList.propTypes = {
  avatarCollection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired
    })
  ),
  profileCollection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      props: PropTypes.object.isRequired
    })
  ),
  onClickItemHandler: PropTypes.func,
  handleOnEditProfile: PropTypes.func,
  handleOnDeleteProfile: PropTypes.func,
  enableProfileOperations: PropTypes.bool,
  currentDataProfileSelected: PropTypes.object
};

export default React.memo(ProfilesList);
