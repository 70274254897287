
export const types = {
  CONTENT_SELECTED: 'EpgComponent/CONTENT_SELECTED',
  FETCH_EPG_DATA_BY_IDS: 'EpgComponent/FETCH_EPG_DATA_BY_IDS',
  EPG_DATA_BY_IDS_ERROR: 'EpgComponent/EPG_DATA_BY_IDS_FETCH_ERROR',
  EPG_DATA_BY_IDS_SUCCESS: 'EpgComponent/EPG_DATA_BY_IDS_FETCH_SUCCESS',
}


export const TITLES = [
'Next episode in',
'Season',
'Episode',
'Seconds',
'Audio',
'Subtitles',
'Not available',
'Content not available',
'TODAY',
'GO BACK TO LIVE',
'SEE CHANNEL LIVE', 
'TO SEE AGAIN',
'LIVE', 
"Sign In",
"You do not have access to this content",
"Guide"
];

export const LIVE_PLAYER = 'Live';