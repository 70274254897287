import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { PinCodeInput } from '@tbx/experience-widgets-lib';
import { NUMBER_FIELDS_PIN } from '../../../containers/ProfileManager/constants';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';

function ProfilePinCode({handlePinCodeChange, isFetching, validatePinCodeStatus, handlePinCodeSubmit, handlePinCodeCancel, pinCodeValue, handleProfilePinCodeLogout}) {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    
  return (
     <Form className="profilePinCode__container" onSubmit={(e) => handlePinCodeSubmit(e)}>
      <Row className='profilePinCode__title'>
        <span> 
          <label >{t('Enter PIN')}</label>
        </span>
      </Row>
      <Row className={"profilePinCode__subTitle"}>
        <span> 
          <label>{t('To be able to change profile:')}</label>
        </span>
      </Row>
      <Row className={"profilePinCode__inputs"}>
            <Form.Group as={Col} controlId="formProfileName">
              <PinCodeInput
                numberOfFields={NUMBER_FIELDS_PIN.CUSTOM}
                handlePinCodeChange={handlePinCodeChange}
                isFetching={isFetching}
                validateFormat={'0123456789'}
                classNameCustom={'pinCodeModal pinCodeScreen'} 
              />
            </Form.Group>
      </Row>
      <Row className='profilePinCode__errorMessage'>
        {!validatePinCodeStatus && (pinCodeValue.length === 0) && <label>{t('Pin code error message')}</label>}
      </Row>
      <Row className={"profilePinCode__forgotPin"}>
        <span onClick={handleShow}> 
          <label>{t('Forgot PIN?')}</label>
        </span>
      </Row>
      <Row className="mb-3 mt-5" controlId="formGridSubmit">
        <Col>
          <Button
            className="profilePinCode__cancel_button"
            onClick={handlePinCodeCancel}
          >
            {t('Cancel')}
          </Button>
        </Col>
        <Col>
          <Button
            className={"profilePinCode__enter_button"}
            type="submit"
            disabled={!(pinCodeValue.length === NUMBER_FIELDS_PIN.CUSTOM)}
            >
            {t('Get In')}
          </Button>
        </Col>
      </Row>
      <Modal contentClassName="forgotModal__content" className="forgotModal__container" show={show} onHide={handleClose}>
        <Modal.Header className="forgotModal__header">
          <Modal.Title>{t('Sign back in to change your PIN')}</Modal.Title>
        </Modal.Header>
        <Modal.Footer  className="forgotModal__buttons">
          <Button className="forgotModal__buttons__secondary" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button className="forgotModal__buttons__primary" onClick={(e) => handleProfilePinCodeLogout(e)}>
            {t('Confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

ProfilePinCode.propTypes = {
  handlePinCodeChange: PropTypes.func, 
  isFetching: PropTypes.bool,
  validatePinCodeStatus: PropTypes.bool, 
  handlePinCodeSubmit: PropTypes.func, 
  handlePinCodeCancel: PropTypes.func,
  pinCodeValue: PropTypes.number,
  handleProfilePinCodeLogout: PropTypes.func,

};

export default React.memo(ProfilePinCode);
