import { types } from './constants';

export const INITIAL_STATE = {
  authenticated: false,
  accessToken: {
    access_token: null,
    auth_type: null,
    expires_in: null
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.APP_TOKEN_FETCH_SUCCESS:
      const { unityAuthData } = action;

      return {
        ...state,
        accessToken: {
          access_token: unityAuthData.token['access_token'],
          auth_type: unityAuthData.token['auth_type'],
          expires_in: unityAuthData.token['expires_in']
        },
        authenticated: !!unityAuthData.device
      };
    case types.APP_TOKEN_REFRESH_SUCCESS:
      const { tokenData } = action;

      return {
        ...state,
        accessToken: {
          access_token: tokenData['access_token'],
          auth_type: tokenData['auth_type'],
          expires_in: tokenData['expires_in']
        }
      };
    default:
      return state;
  }
};

export default reducer;
