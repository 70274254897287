import {
  all,
  call,
  put,
  takeLatest,
  select
} from 'redux-saga/effects';
import {
  unityContentsService
} from '@tbx/experience-widgets-lib';

import {
  loadMoreContents,
  searchContentsFailure,
  searchContentsSuccess
} from './actions';
import { DEFAULT_SEARCH_FILTERS, types } from './constants';
import * as selectors from './selectors';
import { getContentListSuggestions } from '../../../utils/cloudpassUtilis';
import { decodeToken } from '../../../utils/jwtUtils';


function* getContents (tokenData, filters){
  const userInput = yield select(state => selectors.selectUserInput(state));
  const tokenInfo = decodeToken(tokenData.access_token);
  const { isSuggestion } = filters;

  const contentListSuggestions = getContentListSuggestions(tokenInfo.client)

  if((!userInput || isSuggestion) && contentListSuggestions) {
    filters = { 'contentListID' : contentListSuggestions, page: filters.page }
  }

  const { result } = yield call(unityContentsService.getContents, tokenData, { ...filters });

    if (!result || result.error) {
      throw new Error(result.error);
    }

    return result;
}

function* contentsSuccessOrLoadMore (result, page, recommended, emptyResult){
  if (page && page > 1) {
    yield put(loadMoreContents(result));
  } else{
    yield put(searchContentsSuccess(result, recommended, emptyResult));
  }
}

function* searchForContent(action) {
  const {
    tokenData,
    filters
  } = action;


  try {

    const result = yield getContents(tokenData, filters);
    
    if (!result.count) {
      const resultFinal = yield getContents(tokenData, {
        ...DEFAULT_SEARCH_FILTERS,
        text: '',
        page: filters.page,
        isSuggestion: true
      })
      yield contentsSuccessOrLoadMore(resultFinal, filters.page, true, true)
    } else {
      yield contentsSuccessOrLoadMore(result, filters.page, filters.text === '', false)
    }
  } catch (e) {
    console.error('[searchForContentSaga] Error: ', e);
    put(searchContentsFailure(e));
  }
}

function* saga() {
  yield takeLatest(types.SEARCH_CONTENTS, searchForContent);
}

export default saga;