import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import reducer, { INITIAL_STATE } from "./reducer";
import { normalizeImageMode } from "../../utils/imageUtils";
import { findImageSettingByType } from "../../utils/appSettingUtils";
import { NO_IMAGE_DEFAULT } from "../../constants/noImage";
import { CONTENT_TYPES, IMAGE_MODE } from "../../constants/contentTypes";
import { contentItemsToShowInCarousel } from "../../utils/ContentUtils";
import { DISABLED_SKELETON_LIST_SOURCES } from "./constants";
import * as hooks from "./hooks";
import { WidgetErrorBoundary } from "@tbx/experience-widgets-lib";
import { selectOrderType } from "./actions";
import { useSelector } from "react-redux";
import * as selectors from "../../containers/AppSectionRouter/selectors";

/**
 * HOC component provides unity component data for tbx-widgets rendering.
 *
 * @param {*} {
 *   renderWidget,
 *   customEmptyComponent = null,
 *   customLoadingComponent = null,
 *   sectionID,
 *   tokenData,
 *   widgetID
 * }
 * @returns Component
 */
function UnityComponentProvider({
  translationTexts,
  renderWidget,
  customEmptyComponent = null,
  customLoadingComponent = null,
  tokenData,
  appSettings,
  networks,
  channels,
  dispatchRedux,
  authenticated,
  widgetData = {
    componentParameters: {},
  },
}) {
  // Getting sections from State
  const sections = useSelector((state) =>
    selectors.selectSectionCollection(state)
  );

  const { t } = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, {
    ...INITIAL_STATE,
  });
  const { componentItems, orderTypeSelected } = state;
  const { page } = componentItems || {};
  const { componentType, componentParameters } = widgetData || {};
  const {
    title,
    textMode,
    contentListDisplayItems,
    maxItems,
    sectionLinked,
    listSource,
    rowType,
    itemLabel,
  } = componentParameters || {};

  hooks.useDidMount({
    tokenData,
    widgetData,
    state,
    dispatch,
    dispatchRedux,
    authenticated,
  });
  hooks.useAutoRefreshProcess({
    tokenData,
    widgetData,
    state,
    dispatch,
    dispatchRedux,
    authenticated,
  });

  const imageMode =
    componentType === "gallery_wall" ? "POSTER" : normalizeImageMode(rowType);
  const { w, h } = findImageSettingByType(appSettings.imageSizes, imageMode);

  const contentNoImageProps = {
    width: w || NO_IMAGE_DEFAULT(imageMode).width,
    height: h || NO_IMAGE_DEFAULT(imageMode).height,
    backgroundColorOne: NO_IMAGE_DEFAULT().backgroundColorOne,
    backgroundColorTwo: NO_IMAGE_DEFAULT().backgroundColorTwo,
  };

  const selectFilterHandler = (orderType) =>
    dispatch(selectOrderType(orderType));

  const loadMoreDataInComponent = () => {
    hooks.fetchComponentData({
      tokenData,
      widgetData,
      state,
      dispatch,
      dispatchRedux,
      authenticated,
      isLoadMoreDataInComponent: true,
    });
  };

  if (state.isFetchingData && customLoadingComponent) {
    if (DISABLED_SKELETON_LIST_SOURCES.includes(listSource)) {
      return customLoadingComponent(null);
    } else {
      let loadingObjectResult;
      let countItem = contentItemsToShowInCarousel(
        maxItems,
        contentListDisplayItems
      );

      if ("carousel" === componentType) {
        loadingObjectResult = {
          isLoading: true,
          countItem: countItem,
          title: title,
          textMode: textMode,
          imageMode: imageMode,
          itemLabel: itemLabel,
          contentNoImageProps: contentNoImageProps,
          sectionLinked: sectionLinked,
        };
        return customLoadingComponent(loadingObjectResult);
      }

      if ("gallery_wall" === componentType) {
        loadingObjectResult = {
          isLoading: true,
          countItem: countItem,
          title: title,
          textMode: textMode,
          imageMode: imageMode,
          itemLabel: itemLabel,
          contentNoImageProps: contentNoImageProps,
          sectionLinked: sectionLinked,
          enableFilters: true,
          customClassName: "tbxContentGrid__gallery_wall_filters",
        };

        return customLoadingComponent(loadingObjectResult);
      }

      if ("banner" === componentType) {
        loadingObjectResult = {
          isLoading: true,
        };
        return customLoadingComponent(loadingObjectResult);
      }
    }
  }

  if (
    !state.isFetchingData &&
    componentItems.itemCollection.length === 0 &&
    componentType !== "gallery_wall"
  ) {
    console.warn(
      `>>> Component "${componentType}" ID:"${widgetData.id}" is empty`
    );
    return customEmptyComponent;
  }

  const widgetProps = (({
    textMode = "none",
    title,
    rowType,
    sectionLinked,
    maxItems,
    contentListDisplayItems,
    itemLabel,
  }) => {
    if (
      componentType === "banner" ||
      componentType === "carousel" ||
      componentType === "gallery_wall"
    ) {
      return {
        translationTexts,
        contentCardProps: {
          prefixNetworkText: t("Disponible en: "),
        },
        contentCollection:
          componentType === "gallery_wall"
            ? componentItems?.itemCollection.map((item) => item.content)
            : componentItems?.itemCollection
                .slice(
                  0,
                  contentItemsToShowInCarousel(
                    maxItems,
                    contentListDisplayItems
                  )
                )
                .map((item) => {
                  if (widgetData.componentType === "banner") {
                    const section = sections?.filter(
                      (section) => section.url === item.sectionUrl
                    )[0];
                    const bannerItem = {
                      sectionName: section?.name,
                      ...item,
                    };
                    return bannerItem;
                  } else {
                    return item?.content
                      ? {
                          ...item.content,
                          bookmark: item.bookmark,
                          serieProps: item.serieProps,
                        }
                      : item;
                  }
                }),
        contentNoImageProps: contentNoImageProps,
        networks,
        channels,
        listSource,
        playAndFavorite: normalizeImageMode(rowType) === IMAGE_MODE.POSTER,
        sectionLinked: sectionLinked,
        imageMode: imageMode,
        trailerOverview:
          (normalizeImageMode(rowType) === IMAGE_MODE.SIMPLE_LARGE ||
            normalizeImageMode(rowType) === IMAGE_MODE.THUMB) &&
          componentItems?.itemCollection.every(
            (prop) => prop?.content?.contentType !== CONTENT_TYPES.BROADCAST
          ),
        ...{ title },
        ...{ textMode },
        itemLabel,
        enableFilters: componentType === "gallery_wall" ? true : false,
        orderTypeSelected: orderTypeSelected,
        selectFilterHandler: selectFilterHandler,
        loadMoreDataInComponent: loadMoreDataInComponent,
        customClassName:
          componentType === "gallery_wall"
            ? "tbxContentGrid__gallery_wall_filters"
            : "",
        page: page,
        noItemsDescription:
          componentItems?.itemCollection.length === 0
            ? t("At this time there are no content available.")
            : "",
      };
    }

    if (componentType === "epg_grid") {
      const isEpgPlayer = true;
      return {
        content: componentItems.itemCollection.map((e) => e.content),
        unityToken: tokenData,
        contentNoImageProps: contentNoImageProps,
        player: isEpgPlayer,
      };
    }
  })(componentParameters);

  return renderWidget(widgetProps);
}

UnityComponentProvider.propTypes = {
  renderWidget: PropTypes.func.isRequired,
  EmptyComponent: PropTypes.element,
  LoadingComponent: PropTypes.element,
  tokenData: PropTypes.shape({
    auth_type: PropTypes.string,
    access_token: PropTypes.string,
  }).isRequired,
  appSettings: PropTypes.object,
  networks: PropTypes.array,
  channels: PropTypes.array,
  widgetData: PropTypes.shape({
    componentParameters: PropTypes.object,
    id: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
  }).isRequired,
};

export default WidgetErrorBoundary(UnityComponentProvider);
