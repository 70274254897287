import { SUBPATHS } from '../constants/contentTypes';

/**
 * Find image setting from cloudhub data
 * @param {Object} obj
 * @param {*} imageType
 * 
 * @return {Object}
 */
 export const findImageSettingByType = (obj, imageType) => {
    const setting = obj?.find(element => element.name === imageType);
    if(typeof setting !== 'object') return {w:false,h:false}
    return setting;
};


/**
 * Validate subpath to redirect. Default value /content
 * @param {String} contentType
 * 
 * @return {String}
 */
/*
 export const getSubPathByContentType = (contentType) => {

    const subPathsDefault = 'content';
    const subPathsObj = Object.values(SUBPATHS);

    for(const val in subPathsObj) {
        if(subPathsObj[val].contentTypeOptions.includes(contentType))
            return subPathsObj[val].subPath;
    }

    return subPathsDefault;
 
};
*/