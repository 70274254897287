import { createSelector } from 'reselect';

const getAppReducer = (state) => state.containers.app;
const getSessionReducer = (state) => state.session;
const getReducer = (state) => state.containers.contentOverViewSection;
const getProfileReducer = (state) => state.containers.profileManager;
const getAppSectionsReducer = (state) => state.containers.appSections;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken
);

export const selectContentWidget = createSelector(
  getReducer,
  (reducer) => reducer.selectedContent
);

export const selectContentRelatedWidget = createSelector(
  getReducer,
  (reducer) => reducer.selectedContentRelated
);

export const selectContentEpisodesWidget = createSelector(
  getReducer,
  (reducer) => reducer.selectContentEpisodes
);

export const selectError = createSelector(
  getReducer,
  (reducer) => {
    return reducer.error;
  }
);

export const selectAuthenticated = createSelector(
  getSessionReducer,
  (reducer) => reducer.authenticated
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings
);


export const selectNetworks = createSelector(
  getAppReducer,
  (reducer) => reducer.networks
);

export const selectCurrentContentID = createSelector(
  getReducer,
  (reducer) => reducer.currentContentID
);

export const selectContentsUrlByID = createSelector(
  getReducer,
  (reducer) => reducer.redirectUrl
);

export const selectFavoritesContents = createSelector(
  getProfileReducer,
  (reducer) => reducer.favorites
);

export const selectFavoritesIsFetching = createSelector(
getProfileReducer,
  (reducer) => reducer.isFetching
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList
);

export const selectRatingImages = createSelector(
  getAppReducer,
  (reducer) => reducer.ratingImages
);




